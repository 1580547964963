import React, { useEffect } from "react";
import BlurModal from "../CommonComponents/BlurModal/BlurModal";
import BlurModalHeading from "../CommonComponents/BlurModal/BlurModalHeading";
import { isMobile } from "../helperFunc";
import HeadLines from "../CommonComponents/HeadLines";
import BlurModalContent from "../CommonComponents/BlurModal/BlurModalContent";
import { greenCircleCheck } from "../icon_assets";
import style from "./style.module.css";
import HeadLinesSpan from "../CommonComponents/HeadlinesSpan";
const EmailLinkSentPopUp = ({
  showEmailSentPopUp,
  setShowEmailSentPopUp,
  popUpShow,
  email = "",
  setBlurBackGround = () => { },
}) => {
  useEffect(() => {
    setBlurBackGround(true);
    return () => {
      setBlurBackGround(false);
    };
  }, []);
  return (
    <BlurModal
      className={"blur-modal"}
      show={showEmailSentPopUp}
      showDesktopHeader={!isMobile}
      desktopHeading={"Verification Email sent"}
      headingClassName={`font-size20 line-height23 letterSpacing001 font-weight500 colorDark5`}
      onHide={() => {
        setShowEmailSentPopUp(false);
        popUpShow && popUpShow(false);
      }}
    >
      <>
        {/* {isMobile && (
          <BlurModalHeading
            className={`padding-left16
                        padding-bottom16
                       `}
            heading={
              <HeadLines
                className={`font-weight600 
                            font-size18 
                            line-height21`}
                text={"Verification Email sent"}
              />
            }
          />
        )} */}
        <BlurModalContent
          className={
            isMobile
              ? "padding16 "
              : "padding-top20 padding-left20 padding-right20 padding-bottom20"
          }
        >
          <div className={`  `}>
            <div className="d-flex justify-content-center">
              <img src={greenCircleCheck} />
            </div>
            {isMobile && <HeadLines className={`${isMobile ? "font-weight500 font-size16 line-height19 " : ""} textColorBlack text-center margin-top16`} text="Verification Email sent" />}
            <HeadLines
              text={`A verification link has been sent to`}
              className={`font-weight400 text-center  ${isMobile
                ? "font-size14 line-height16 padding-left16 padding-right16"
                : "font-size16 line-height19"
                } letterSpacing025 colorDark9 margin-top12`}
            />
            <HeadLines text={email} className={`${isMobile ? "font-size14 line-height16" : "font-size16 line-height19"} text-center font-weight500 colorAccent4 margin-top2`} />
            <HeadLines
              text={"Please click on the link to complete the verification process"}
              className={`font-weight400 text-center margin-top2 ${isMobile
                ? "font-size14 line-height16  padding-left16 padding-right16 padding-bottom16"
                : "font-size16 line-height19"
                } letterSpacing025 colorDark9`}
            />
          </div>
        </BlurModalContent>
      </>
    </BlurModal>
  );
};
export default EmailLinkSentPopUp;
