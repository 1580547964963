import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'

const callApi = async (api, Method, data) => {
    try {
        const response = await RestMethod[Method](api, data);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

export const stpDashboardCounts = async () => {
    var reuestlink = "/total-stp-ltp";
    return callApi(reuestlink, 'GET')
}

export const supplierTotalPurchange = async () => {
    var reuestlink = "/bd-supplier-pie-chart-contract";
    return callApi(reuestlink, 'GET')
}

export const supplierContractQuantity = async () => {
    var reuestlink = "/bd-supplier-pie-chart-invoice";
    return callApi(reuestlink, 'GET')
}


export const supplierTotalAverageValue = async () => {
    var reuestlink = "/bd-supplier-line-chart";
    return callApi(reuestlink, 'GET')
}

export const supplierSpplyTrend = async () => {
    var reuestlink = "/bd-supplier-bar-chart";
    return callApi(reuestlink, 'GET')
}



export const buyerTotalSales = async () => {
    var reuestlink = "/bd-buyer-pie-chart-contract";
    return callApi(reuestlink, 'GET')
}

export const buyerTotalContractQuantity = async () => {
    var reuestlink = "/bd-buyer-pie-chart-invoice";
    return callApi(reuestlink, 'GET')
}


export const buyerAveragePurchageValue = async () => {
    var reuestlink = "/bd-buyer-line-chart";
    return callApi(reuestlink, 'GET')
}

export const buyerBuyTrend = async () => {
    var reuestlink = "/bd-buyer-bar-chart";
    return callApi(reuestlink, 'GET')
}

export const supplierSales = async () => {
    var reuestlink = "/sales-supplier";
    return callApi(reuestlink, 'GET')
}
export const supplierContractQty = async () => {
    var reuestlink = "/contract-qty-supplier";
    return callApi(reuestlink, 'GET')
}
export const supplierAvgInvoice = async () => {
    var reuestlink = "/avg-invoice-supplier";
    return callApi(reuestlink, 'GET')
}
export const supplierSupplyTrend = async () => {
    var reuestlink = "/supply-trend-supplier";
    return callApi(reuestlink, 'GET')
}

//past quotations
export const getPastTransactions = async () => {
    var reuestlink = "/my-rent-history?type=3";
    return callApi(reuestlink, 'GET')
}


