import React from "react";
import SocialLinks from "../../Footer/SocialLinks";
import TopPart from "./TopPart";
import style from "./style.module.css";
import HeadLines from "../../CommonComponents/HeadLines";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
const currentYear = new Date().getFullYear();

const DesktopFooter = (props) => {
  const { t: translation } = useTranslation();
  const history = useHistory();
  return (
    <div className="stdFontStyle backgroundDarkGrey padding-left-right100 padding-top16 padding-bottom16">
      <TopPart />
      <div
        className={`${style["border-top-bottom"]} padding-top16 padding-bottom8 text-center`}
      >
        <SocialLinks />
      </div>
      <div
        className={`d-flex ${style["paddingtb18"]} justify-content-space-between`}
      >
        <div
          className={`d-flex font-size14 line-height16 font-weight400 letterSpacing05 ${style["colorLightBlue"]}`}
        >
          <HeadLines
            className={`cursor-pointer`}
            text="Terms and Conditions"
            onClick={() => history.push("/terms-condition")}
          />
          <HeadLines className={`padding-left12 padding-right12`} text="|" />
          <HeadLines
            className={`cursor-pointer`}
            text="Privacy Policy"
            onClick={() => history.push("/privacy-policy")}
          />
        </div>
        <div>
          <HeadLines
            text={translation(
              `Copyright © Aryatech Platforms Private Limited | All Rights Reserved.`,
              {
                current_year: currentYear,
              }
            )}
            className="font-size14 textColorWhite line-height16 font-weight400 letterSpacing05 "
          />
        </div>
      </div>
    </div>
  );
};
export default DesktopFooter;
