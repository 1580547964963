/**
 * @param {string} command Command to GA, usually 'event'
 * @param {string} action type of event
 * @param {object} event Object for extra properties
 */

export const EventTracker = (command = "event", action, params = {}) => {
  const userDashBoardData = JSON.parse(
    localStorage.getItem("userDashBoardData")
  );

  return window.gtag
    ? window.gtag(command, action, {
        ...params,
        userid: userDashBoardData?.member?.id || "NA",
        is_old_arya: true,
      })
    : "";
};
