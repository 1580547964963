import FilterResponse from "../../../_helpers/FilterResponse";

import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import { customToastr } from "../../../_Pages/_webapp/arya2.0/CustomToastr";
const callApi = async (api, Method, data) => {
  try {
    const response = await RestMethod[Method](api, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getUserImages = async (urlParamString) => {
  return callApi(
    `/v2/user-images${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getRmsToken = async () => {
  return callApi(`/v2/rms/token`, "GET");
};

export const updateUserImages = async (data) => {
  return callApi(`/v2/update-user-image`, "POST", data);
};

export const getPreferenceList = async () => {
  return callApi(`/v2/get-preference-list`, "GET");
};

export const updateLangPreference = async (params = "") => {
  return callApi(
    `/v2/update-lang-preference` + (params ? "?lang=" + params : ""),
    "GET"
  );
};

export const updateCommodityLocationPreference = async (data) => {
  return callApi(`/v2/update-commodity-location-preference`, "POST", data);
};
