import React, { useEffect, useState } from "react";
import Card from "../CommonComponents/Card";
import HeadLines from "../CommonComponents/HeadLines";
import IconsSvg from "./IconsSvg";
import style from "./style.module.css";
import { BackToTopFunc } from "../CommonFunctions/Function";

const BackToTop = ({ show, setShow }) => {
  if (show)
    return (
      <Card
        className={`backgroundWhite ${style["backToTopCSS"]} d-flex align-items-center`}
        onClick={BackToTopFunc}
      >
        <IconsSvg iconName={"BackToTop"} isActive={true} />
        <HeadLines
          className={
            "padding-left4 font-size12 line-height16 letterSpacing025 colorAccent4 font-weight400"
          }
          text="Go To Top"
        />
      </Card>
    );
  else return "";
};

export default BackToTop;
