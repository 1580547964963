import React from "react";
import HeadLines from "../CommonComponents/HeadLines";
import BlurModal from "../CommonComponents/BlurModal/BlurModal";
import BlurModalHeading from "../CommonComponents/BlurModal/BlurModalHeading";
import s from "./styles.module.css";
import BlurModalContent from "../CommonComponents/BlurModal/BlurModalContent";
import { isMobile } from "../helperFunc";
import MultiLangTranslate from "../../../../_components/Translate/MultiLangTranslate";
import Button from "../CommonComponents/Button";

const DeleteUserModal = ({ showModal, selectedUser, onHide }) => {
  const handleUser = () => {
    //API HIT
    onHide();
  };

  return (
    <div className="stdFontStyle">
      <BlurModal
        show={showModal}
        onHide={() => {
          onHide();
        }}
        showDesktopHeader={!isMobile}
        desktopHeading={"Delete User"}
      >
        {isMobile && (
          <BlurModalHeading
            className={`d-flex 
                      justify-content-left
                      padding-bottom16
                      padding-top24 
                      font-weight600 font-size18 line-height21 letterSpacing0015
                      padding-bottom24`}
            heading={<MultiLangTranslate title={"Delete User"} />}
          />
        )}

        <BlurModalContent>
          <div className={`stdFontStyle padding20`}>
            <div className=" justify-content-center colorDark9 margin-top4 mx-auto">
              <HeadLines
                text={"By proceeding, this user will be permanently removed."}
                className={`text-center width-70 margin-bottom8 ${
                  !isMobile ? "font-size16 " : "font-size14 "
                }`}
              />
              <HeadLines
                text=" Are you sure you want to proceed?"
                className={`text-center width-70 ${
                  !isMobile
                    ? "font-size16 margin-bottom40"
                    : "font-size14 margin-bottom24"
                }`}
              />
            </div>
            <div className="d-flex justify-content-center gap16">
              <Button
                onClick={onHide}
                title="Cancel"
                className={`colorRed4 input-style-error fontWeight600 padding105 boxRadius6 ${s["wid360"]} ${s["backTextLight9"]}`}
              />
              <Button
                onClick={handleUser}
                title="Delete User"
                className={`backAccent3 textColorWhite fontWeight600 padding105 boxRadius6 ${s["wid360"]}`}
              />
            </div>
          </div>
        </BlurModalContent>
      </BlurModal>
    </div>
  );
};

export default DeleteUserModal;
