import React, { useState, useEffect } from "react";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import BlurModal from "../CommonComponents/BlurModal/BlurModal";
import BlurModalHeading from "../CommonComponents/BlurModal/BlurModalHeading";
import s from "./styles.module.css";
import BlurModalContent from "../CommonComponents/BlurModal/BlurModalContent";
import { isMobile } from "../helperFunc";
import MultiLangTranslate from "../../../../_components/Translate/MultiLangTranslate";
import Button from "../CommonComponents/Button";
import CustomReactSelect from "../CommonComponents/CustomReactSelect/CustomReactSelect";
import { roleOptions } from "./constant";
import Title from "../CommonComponents/InputFieldWithTitle/Title";
import InputMobileNumber from "../CommonComponents/InputMobileNumber";
import { isNumberOnly, validationFunction } from "../CommonFunctions/Function";
import { addUserDetails } from "./constant";
import ValidationError from "../CommonComponents/ValidationError/ValidationError";
import { customToastr } from "../CustomToastr";

const initial = {
  fName: "",
  phoneNum: "",
  role: "",
  permissions: "",
};

const AddUserModal = ({ showModal, editUser, editUserData, onHide }) => {
  const [data, setData] = useState(() => {
    if (editUserData) {
      return {
        fName: editUserData.name,
        phoneNum: editUserData.phoneNum,
        role: { label: editUserData.role, value: editUserData.role },
        permissions: editUserData.permissions,
      };
    } else return initial;
  });
  const [error, setError] = useState("");
  const [editButtonEnable, setEditButtonEnable] = useState(false);

  const buttonEnable = () => {
    if (editUser) return !editButtonEnable;
    return !data.fName || !data.role || !data.phoneNum;
  };

  const submit = () => {
    let flag = validationFunction(data, addUserDetails, setError);
    if (flag === 0) {
      customToastr({
        text: "User Added Successfully",
        type: "success",
      });
      onHide(data);
    }
  };

  const onChange = (e) => {
    setEditButtonEnable(true);
    const { name, value } = e.target;
    const isNumberValid = value ? isNumberOnly(value) : true;

    if (
      name !== "phoneNum" ||
      (value.toString().length <= 10 && isNumberValid)
    ) {
      setData((prev) => ({ ...prev, [name]: value }));
    }
    if (name === "role") {
      setData((prev) => ({ ...prev, permissions: value.permissions }));
    }
  };

  return (
    <div className="stdFontStyle">
      <BlurModal
        show={showModal}
        onHide={() => {
          onHide();
        }}
        showDesktopHeader={!isMobile}
        desktopHeading={!editUser ? "Add User" : "Edit User"}
      >
        {isMobile && (
          <BlurModalHeading
            className={`d-flex 
                        justify-content-left
                        padding-bottom16
                        padding-top24 
                        font-weight600 font-size18 line-height21 letterSpacing0015
                        padding-bottom24`}
            heading={
              <MultiLangTranslate
                title={!editUser ? "Add User" : "Edit User"}
              />
            }
          />
        )}
        {
          <BlurModalContent>
            <div className={`stdFontStyle padding16`}>
              <InputFieldWithTitle
                title="Full Name"
                type="text"
                className="width-100"
                onChange={onChange}
                placeholder="Enter Full Name"
                value={data.fName}
                isCompulsory
                name="fName"
                disabled={editUser}
                compulsoryStarClassName="line-height14"
                titleClassName={`${s["input-title-1"]} ${
                  isMobile
                    ? "font-size14 line-height16"
                    : "font-size16 line-height19"
                }`}
                inputClassName={`${s["input-style"]} placeholderColorb8bdbd`}
                disableInputClassName={`${s["input-style-disable"]}`}
                errorInputClassName={`${s["input-style-error"]}`}
                showError={error?.fName ? error.fName : ""}
                errorClassName={`${s["input-error-style"]}`}
              />
              <div className="d-flex width-100 margin-top16 margin-bottom16 gap16">
                <div className="width-50">
                  <InputMobileNumber
                    className1={`${s["input-style"]} placeholderColorb8bdbd`}
                    value={data.phoneNum}
                    onChange={onChange}
                    name="phoneNum"
                    errorClassName={isMobile && `margin-top4`}
                    title="Phone Number"
                    compulsoryStarClassName="line-height14"
                    titleClassName={`${s["input-title-1"]} ${
                      isMobile
                        ? "font-size14 line-height16"
                        : "font-size16 line-height19"
                    }`}
                    isCompulsory
                    placeholder="E.g. 9897868689"
                    showCountryCode={false}
                    showError={error?.phoneNum ? error.phoneNum : ""}
                  />
                </div>

                <div className="width-50">
                  <Title
                    title={"Role"}
                    isCompulsory
                    titleClassName={`${s["input-title-1"]} font-weight500  ${
                      isMobile
                        ? "font-size14 line-height16"
                        : "font-size16 line-height19"
                    }`}
                    compulsoryStarClassName={`${s["asterisk"]}`}
                  />
                  <CustomReactSelect
                    options={roleOptions}
                    name="role"
                    onChange={(e) =>
                      onChange({ target: { name: "role", value: e } })
                    }
                    className={`placeholderColorb8bdbd ${
                      !isMobile
                        ? `${s["desk-cu-react-select"]}`
                        : `${s["height43Imp"]}`
                    }`}
                    value={data.role}
                  />
                  {error.role && (
                    <ValidationError
                      text={error.role}
                      imageClassName={`${s["error-icon-sryle wid18"]}`}
                      textClassName={`font-size14 ${s["errorCSS"]} display-inline-block`}
                    />
                  )}
                </div>
              </div>

              <InputFieldWithTitle
                title="Permissions"
                type="text"
                className=""
                value={data.permissions}
                disabled
                titleClassName={`${s["input-title-1"]}  ${
                  isMobile
                    ? "font-size14 line-height16"
                    : "font-size16 line-height19"
                }`}
                inputClassName={`${s["input-style"]} placeholderColorb8bdbd`}
                disableInputClassName={`${s["input-style-disable"]}`}
                errorInputClassName={`${s["input-style-error"]}`}
                errorClassName={`${s["input-error-style"]}`}
              />

              <div className="d-flex justify-content-center">
                <Button
                  disabled={buttonEnable()}
                  onClick={submit}
                  title={!editUser ? "Add User" : "Save Changes"}
                  className={`${
                    buttonEnable() ? "btn-disable-grey" : "backAccent3"
                  } textColorWhite cursor-pointer fontWeight600 padding105 boxRadius6 ${
                    s["wid360"]
                  }`}
                />
              </div>
            </div>
          </BlurModalContent>
        }
      </BlurModal>
    </div>
  );
};
export default AddUserModal;
