import React from "react";
import { useState } from "react";
import {
  postUserDetails,
  verifyEmailOTP,
} from "../../../../_services/Arya2.0/AccountSettings";
import BlurModal from "../CommonComponents/BlurModal/BlurModal";
import BlurModalContent from "../CommonComponents/BlurModal/BlurModalContent";
import BlurModalHeading from "../CommonComponents/BlurModal/BlurModalHeading";
import Button from "../CommonComponents/Button";
import HeadLines from "../CommonComponents/HeadLines";
import InputText from "../CommonComponents/InputText";
import Loader from "../CommonComponents/Loader/Loader";
import { customToastr } from "../CustomToastr";
import { errorInfoIcon } from "../icon_assets";
import ResentOTP from "./ResentOTP";
import s from "./styles.module.css";
import tcs from "../CustomToastr/customToastrStyles.module.css";
import LoaderCircle from "../CommonComponents/Loader/LoaderCircle/LoaderCircle";
import { isMobile } from "../helperFunc";

const VerifyEmailModal = (props) => {
  const {
    showVerifyEmailModal,
    setShowVerifyEmailModal,
    otherModalProps,
    sendOtpToEmail,
    accountSettingFormStates,
    getUserDetailsAPI,
  } = props;
  const [selfProps, setSelfProps] = useState({});
  const [error, setError] = useState({ otp: "" });
  const [data, setData] = useState({ otp: "" });
  const [buttonDisable, setButtonDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [timer, setTimer] = useState(10);
  const [enable, setEnable] = useState(false);
  const [firstTimeOTP, setFirstTimeOTP] = useState(true);

  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const reSendOtp = async () => {
    setIsLoading(true);
    try {
      const res = await sendOtpToEmail();
      if (res.status_code === 200) {
        customToastr({
          text: "OTP sent to the e-mail address",
          type: "success",
          positionClass: tcs["toast-top-center2"],
          toastClass: tcs["email-otp-toastr"],
        });
        setFirstTimeOTP(false);
        setTimer(10);
        setEnable(false);
        setData({ otp: "" });
        setError({ otp: "" });
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserDetails = async () => {
    let payload = {
      email: accountSettingFormStates.email,
      is_catalog_hide_details: accountSettingFormStates.hideDetails ? 1 : 0,
      address: {
        ...accountSettingFormStates.addressFieldFromApi,
        address_line1: accountSettingFormStates?.permanantAddressLine1,
        address_line2: accountSettingFormStates?.permanantAddressLine2,
        district: accountSettingFormStates?.city?.id,
        pincode: accountSettingFormStates?.pincode,
        state: accountSettingFormStates?.state?.id,
        district_name: accountSettingFormStates?.city?.name,
        state_name: accountSettingFormStates?.state?.name,
      },
    };
    // console.log(payload);
    return await postUserDetails(payload);
  };
  const verifyOtpViaApi = async (otp, wantRaw) => {
    let payload = {
      email: accountSettingFormStates.email,
      type: "verify",
      otp,
    };
    return await verifyEmailOTP(payload, wantRaw);
  };
  const verifyOtp = async (otp) => {
    setVerifyingOtp(true);
    setSelfProps({ backdrop: "static", keyboard: false });

    try {
      const res = await verifyOtpViaApi(otp, true);
      if (res.status_code === 200) {
        const res1 = await updateUserDetails();

        if (res1.status_code === 200) {
          setShowVerifyEmailModal(false);
          getUserDetailsAPI();
        }
      } else {
        setError({ otp: res.message });
      }
    } catch (e) {
    } finally {
      setVerifyingOtp(false);
      setSelfProps({});
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setError({ otp: "" });
    if (value.toString().length > 6) return;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (value.toString().length === 6) {
      verifyOtp(value.toString());
      setButtonDisable(false);
    } else setButtonDisable(true);
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <BlurModal
        className={"blur-modal"}
        show={showVerifyEmailModal}
        onHide={() => setShowVerifyEmailModal(false)}
        desktopHeading="Verify your Email"
        showDesktopHeader={!isMobile}
        {...otherModalProps}
        {...selfProps}
      >
        <>
          {isMobile && (
            <BlurModalHeading
              className={`padding-left16
                        padding-bottom16
                       `}
              heading={
                <>
                  <HeadLines
                    className={`font-weight600 
                            font-size18 
                            line-height21`}
                    text={"Verify your Email"}
                  />
                </>
              }
            />
          )}
          <BlurModalContent className={""}>
            <div
              className={
                isMobile
                  ? `margin16 padding-bottom16`
                  : `margin-top20 margin-bottom20 margin-left16 margin-right16 `
              }
            >
              <HeadLines
                className={`
                        font-weight400
                        ${
                          isMobile
                            ? `font-size12
                              line-height14
                              letterSpacing025`
                            : `
                              font-size16
                              line-height19
                              letterSpacing05`
                        }
                        colorDark9`}
              >
                Please enter the 6-digit OTP sent to the email address
              </HeadLines>
              <InputText
                className={`margin-top12 boxRadius4 lightBorder padding12   width-100 ${
                  isMobile
                    ? `font-weight400 font-size14 line-height16`
                    : `font-weight500 font-size18 line-height21 textColorBlack`
                } letterSpacing05 ${buttonDisable ? "colorDark9" : ""}`}
                value={data.otp}
                onChange={verifyingOtp ? null : onChange}
                name="otp"
                showError={error.otp}
                type="number"
                placeholder="Enter OTP"
                errorInputClassName={"input-style-error"}
                errorClassName={"input-error-style"}
                errorIcon={errorInfoIcon}
              />
              <ResentOTP
                reSendOtp={reSendOtp}
                {...{
                  timer,
                  setTimer,
                  enable,
                  setEnable,
                  firstTimeOTP,
                  setFirstTimeOTP,
                }}
              />
            </div>
            <hr></hr>
            <div
              className={
                isMobile
                  ? `margin-left16 margin-right16 margin-bottom12 margin-top12`
                  : `w-50 mx-auto margin-bottom20 margin-top20`
              }
            >
              <Button
                disabled={buttonDisable || verifyingOtp}
                //   disabled={isButtondisable()}
                //   onClick={() => {
                //     onClick(startDate, endDate);
                //     onHide();
                //   }}
                className={`
                    
                  padding12
                  boxRadius6 textColorWhite width-100 
                  ${
                    isMobile
                      ? `line-height16
                        font-size14`
                      : `font-size16
                        line-height19
                        letterSpacing05`
                  }
                  textColorWhite
                  
                  ${
                    buttonDisable || verifyingOtp
                      ? s["backgrey"]
                      : "backAccent3"
                  }
                  `}
                title={
                  verifyingOtp ? (
                    <HeadLines>
                      Verifying otp
                      <LoaderCircle
                        spinnerClassName={s["verify-otp-loader"]}
                        circleColor="#E2E2E2"
                      />
                    </HeadLines>
                  ) : (
                    "Submit"
                  )
                }
                onClick={() => verifyOtp(data.otp)}
              />
            </div>
            {/* {stepNo == 0 ? (
              <NumberEntry
                setStepNo={setStepNo}
                setMobileNumber={setMobileNumber}
                mobileNumber={mobileNumber}
                setIsLoading={setIsLoading}
              />
            ) : (
              <OtpVerification
                mobileNumber={mobileNumber}
                setStepNo={setStepNo}
                setShow={setShow}
                setIsLoading={setIsLoading}
                setRegShow={setRegShow}
                callBackFunction={callBackFunction}
              />
            )} */}
          </BlurModalContent>
        </>
      </BlurModal>
    </div>
  );
};

export default VerifyEmailModal;
