import React from "react";
import s from "./styles.module.css";
const OptionItem = (props) => {
  const { icon, text, className, onClick, iconClassName = "" } = props;
  return (
    <div className={className} onClick={onClick}>
      <div className={`text-center ${s["margin-bottom7"]}`}>
        <img className={iconClassName} src={icon} />
      </div>
      <div>{text}</div>
    </div>
  );
};

export default OptionItem;
