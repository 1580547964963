import { useSelector } from "react-redux";

const useIsUserLoggedIn = (props) => {
  const userLoggedIn = useSelector(
    (globalState) => globalState?.userInfoReducer?.userLoggedIn
  );

  return [userLoggedIn ? true : false];
};

export default useIsUserLoggedIn;
