import React, { useState } from "react";
import { errorIcon } from "../../icon_assets";
import "../../commonStyle.css";
import s from "./styles.module.css";
import HeadLines from "../HeadLines";
import FromDevice from "./FromDevice";
import { FileUploadService } from "../../../../../_services/CommnService/FileUploadService/FileUploadService";
import ImagePreview from "./ImagePreview";
import Button from "../Button";
import FromCam from "./FromCam";
import { isMobile } from "../../helperFunc";
const ImageUpload = (props) => {
  const {
    allowMultiUpload = false,
    validExtensions = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG", "ico"],
    errMsg = "Please Upload Valid Image and Max Size Should be 6 MB",
    maxFileSize = 6000000,
    heading = "UPLOAD COMMODITY IMAGE",
    pictureTitle = "Commodity Image",
    limitationMsg = "Maximum of 4 images, max file size limit 6 MB in(jpeg/png)",
    filePath = "arya/warehouseRequests/",
    noOfImages = 4,
    seperateErrors = false,
    // more than limit files chosen err message
    moreFileErr = "You can upload only 4 files",
    // invalid files and more than max size files err message
    moreFileSizeAndExtensionErr = "",
    // only invalid files err message
    extensionErr = "",
    // more than max size message.
    fileSizeErr = "",
    imagePrwCntrClassName = `${s["img-container"]}`,
    imagePrwClassName = `${s["img"]}`,
    imagePrwCloseClassName = `${s["close"]}`,
    imagePrwRoqClassName = `d-flex justify-content-center`,
    errorClassName = `${s["error"]}`,
    // IMPORTANT
    uploadedImage,
    uploadImageClose,
    sendImagesToParent,
    id1,
    id2,
    icon1,
    icon2,
    bottomText,
    bottomTextClassName,
    errorClassNameParent = "d-flex align-items-center gap4",
  } = props;
  const [err, setErr] = React.useState("");
  const [fileListForUpload, setFileListForUpload] = React.useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const handleDelete = (index) => {
    const filteredFileList = Array.from(fileListForUpload).filter(
      (el, idx) => idx !== index
    );
    setFileListForUpload(filteredFileList);
  };
  // function startUpload() {
  //   var filecontainer = [];
  //   for (var i = 0; i < fileListForUpload.length; i++) {
  //     var file = fileListForUpload[i];
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       filecontainer.push(reader.result);
  //       if (filecontainer.length === fileListForUpload.length) {
  //         uploadImages(filecontainer, fileListForUpload);
  //       }
  //     };
  //     reader.onerror = function (error) {
  //       setFileListForUpload([]);
  //       setErr("please upload valid file");
  //     };
  //   }
  // }

  // const uploadImages = async function (files, filelist) {
  //   let imgarr = [];
  //   let uploadeds3link = [];
  //   let FileNames = [];
  //   files = files.slice(0, noOfImages);
  //   setIsFileUploading(true);
  //   for (var i = 0; i < files.length; i++) {
  //     var fileobj = {
  //       path: filePath,
  //       file: files[i],
  //     };
  //     var apiresponse = await FileUploadService.fileUloadService(fileobj);
  //     if (apiresponse !== null && apiresponse.data.data.file_path) {
  //       var objimg = {
  //         src: apiresponse.data.data.file_path,
  //         thumbnail: apiresponse.data.data.file_path,
  //         thumbnailWidth: 100,
  //         thumbnailHeight: 100,
  //       };
  //       imgarr.push(objimg);
  //       if (filelist[i] && filelist[i].name) FileNames.push(filelist[i].name);
  //       uploadeds3link.push(apiresponse.data.data.file_path);
  //     }
  //   }
  //   setIsFileUploading(false);
  //   //send the images and their s3links to parent
  //   uploadedImage(FileNames, uploadeds3link);
  //   // do whatever parent wants after upload
  //   uploadImageClose();
  // };

  const makeMultiPartDataFromImages = () => {
    // const formData = new FormData();
    // Array.from(fileListForUpload).forEach((i, index) =>
    //   formData.append(`data${index + 1}`, i)
    // );
    sendImagesToParent(fileListForUpload);
    uploadImageClose();
  };
  return (
    <>
      <div className={`${s["cov-pic-modal-padding"]}`}>
        {/* <SelectButton icon={profilePic} title="click new Photo" /> */}
        {!(Array.from(fileListForUpload).length > 0) && (
          <>
            {isMobile && (
              <>
                <FromCam
                  allowMultiUpload={allowMultiUpload}
                  validExtensions={validExtensions}
                  errMsg={errMsg}
                  maxFileSize={maxFileSize}
                  heading={heading}
                  pictureTitle={pictureTitle}
                  limitationMsg={limitationMsg}
                  filePath={filePath}
                  noOfImages={noOfImages}
                  seperateErrors={seperateErrors}
                  moreFileErr={moreFileErr}
                  moreFileSizeAndExtensionErr={moreFileSizeAndExtensionErr}
                  extensionErr={extensionErr}
                  fileSizeErr={fileSizeErr}
                  err={err}
                  setErr={setErr}
                  fileListForUpload={fileListForUpload}
                  setFileListForUpload={setFileListForUpload}
                  id={id1}
                  icon={icon1}
                />
                <HeadLines
                  className={`m-auto 
                            text-center 
                            line-height16 
                            padding-top12
                            padding-bottom12`}
                  text={"or"}
                ></HeadLines>
              </>
            )}

            <FromDevice
              allowMultiUpload={allowMultiUpload}
              validExtensions={validExtensions}
              errMsg={errMsg}
              maxFileSize={maxFileSize}
              heading={heading}
              pictureTitle={pictureTitle}
              limitationMsg={limitationMsg}
              filePath={filePath}
              noOfImages={noOfImages}
              seperateErrors={seperateErrors}
              moreFileErr={moreFileErr}
              moreFileSizeAndExtensionErr={moreFileSizeAndExtensionErr}
              extensionErr={extensionErr}
              fileSizeErr={fileSizeErr}
              err={err}
              setErr={setErr}
              fileListForUpload={fileListForUpload}
              setFileListForUpload={setFileListForUpload}
              id={id2}
              icon={icon2}
            />
            {bottomText && !err && (
              <HeadLines
                className={`${bottomTextClassName} margin-top16`}
                text={bottomText}
              />
            )}
          </>
        )}

        <ImagePreview
          imagePrwRoqClassName={imagePrwRoqClassName}
          fileListForUpload={fileListForUpload}
          imagePrwCntrClassName={imagePrwCntrClassName}
          imagePrwClassName={imagePrwClassName}
          imagePrwCloseClassName={imagePrwCloseClassName}
          handleDelete={handleDelete}
        />
        {err && (
          <div className={errorClassNameParent}>
            <img src={errorIcon} alt="" />
            <HeadLines className={errorClassName} text={err} />
          </div>
        )}
      </div>
      {isMobile && <hr className="m-0" />}
      <div
        className={`  ${
          isMobile
            ? ` padding-left16 padding-right16 ${s["paddingY-12"]}`
            : `${s["w90per"]} padding-top16 padding-bottom16 mx-auto`
        }`}
      >
        <Button
          disabled={
            isFileUploading || Array.from(fileListForUpload).length <= 0
          }
          onClick={makeMultiPartDataFromImages}
          // onClick={isFileUploading ? null : () => startUpload()}
          className={`
                          ${s["bg-dark-6"]} 
                          ${s["border-radius6"]}  
                           
                          width-100 
                          padding-bottom12
                          padding-top12
                           
                          ${
                            isMobile
                              ? `line-height16 
                                font-size14 
                                font-weight500 
                                letterSpacing0125
                                ${s["button-margin"]}
                                `
                              : `font-weight500
                                font-size16
                                line-height19
                                letter-spacing05
                                `
                          } 
                          textColorWhite
                          ${
                            Array.from(fileListForUpload).length <= 0 &&
                            s["disable-button"]
                          }
                          `}
          title={
            <HeadLines
              text={isFileUploading ? "Loading.." : "Save"}
              className={s["text-grey"]}
            />
          }
        />
      </div>
    </>
  );
};

export default ImageUpload;
