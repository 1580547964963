import React from "react";
import SelectButton from "../SelectButton/SelectButton";
import "../../commonStyle.css";
const FromCam = (props) => {
  const {
    validExtensions = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG", "ico"],
    errMsg = "Please Upload Valid Image and Max Size Should be 6 MB",
    maxFileSize = 6000000,

    noOfImages = 4,
    seperateErrors = false,
    // more than limit files chosen err message
    moreFileErr = "You can upload only 4 files",
    // invalid files and more than max size files err message
    moreFileSizeAndExtensionErr = "",
    // only invalid files err message
    extensionErr = "",
    // more than max size message.
    fileSizeErr = "",
    setFileListForUpload,
    icon,
    setErr,
    id2 = "customFile2",
  } = props;
  function handleChange(e) {
    setErr("");
    var fileList = e.target.files;
    // if You dont want Seperate Errors
    if (!seperateErrors) {
      // check for names, size and extensions
      if (fileValidator(fileList)) {
        setFileListForUpload(fileList);
      } else {
        setFileListForUpload("");
        setErr(errMsg);
      }
    }
    // If you want seperate errors
    else {
      // check if number of files is valid
      if (fileList.length > noOfImages) {
        setFileListForUpload("");
        setErr(moreFileErr || errMsg);
      } else {
        if (checkFilesForOtherConditions(fileList)) {
          setFileListForUpload(fileList);
        } else {
          setFileListForUpload("");
        }
      }
    }
  }
  // check valid file extenion,name and file size
  function fileValidator(files) {
    let extention = validExtensions;
    for (let [key, value] of Object.entries(files)) {
      let type_temp = value.name.split(".");
      let filesize = value.size;
      let type = type_temp[type_temp.length - 1];
      if (
        !(value?.name && extention.includes(type) && filesize < maxFileSize)
      ) {
        return false;
      }
    }
    return true;
  }

  function checkFilesForOtherConditions(files) {
    let extention = validExtensions;
    for (let [key, value] of Object.entries(files)) {
      let type_temp = value.name.split(".");
      let filesize = value.size;
      let type = type_temp[type_temp.length - 1];

      if (!extention.includes(type) && filesize > maxFileSize) {
        setErr(moreFileSizeAndExtensionErr);
        return false;
      } else if (!extention.includes(type)) {
        setErr(extensionErr);
        return false;
      } else if (filesize > maxFileSize) {
        setErr(fileSizeErr);
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <SelectButton htmlFor={id2} icon={icon} title="click new Photo" />
      <input
        id={id2}
        onChange={(e) => handleChange(e)}
        type="file"
        className="d-none"
        name="file[]"
        autoComplete="off"
        accept="image/*"
        capture="user"
      />
    </>
  );
};

export default FromCam;
