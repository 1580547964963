import { Rules } from "./Rules";

export function Validation(state, field) {
  var errobj = {};
  for (var i = 0; i < field.length; i++) {
    Object.keys(state).map((index) => {
      if (index === field[i].field_name) {
        /**call rule file here */
        var errormsg = Rules(state[index], field[i]);
        if (errormsg !== "") {
          if (
            !field[i].isRequired &&
            (state[index] === "" || state[index] === null)
          ) {
            errobj[index] = "";
          } else {
            errobj[index] = field[i].errorMsg || errormsg;
          }
        } else {
          errobj[index] = "";
        }
      }
    });
  }
  return errobj;
}
