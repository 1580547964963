// https://api-uat.arya.ag/api/find-district-and-state-by-pincode/700052

import { RestMethod } from "../../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../../../_helpers/FilterResponse";
import { customToastr } from "../../../../_Pages/_webapp/arya2.0/CustomToastr";

const callApi = async (api, Method, data) => {
  try {
    const response = await RestMethod[Method](api, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getWalletDetail = async (urlParamString) => {
  return callApi(
    `/v2/lac/get-limit-info${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getLacData = async (urlParamString) => {
  return callApi(
    `/v2/lac/get-stock-info${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const loanRequest = async (urlParamString, data) => {
  return callApi(
    `/v2/lac/dtr-loan-request/${urlParamString ? urlParamString : ""}`,
    "POST",
    data
  );
};

export const LoanApprovalAPI = async (urlParamString) => {
  return callApi(
    `/v2/disbursement-user-detail${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getWRFStatement = async (payload) => {
  return callApi(`/v2/download-wrf-statement`, "POST", payload);
};
export const downloadPaymentLedger = async (payload) => {
  return callApi(`/v2/download-payment-ledger`, "POST", payload);
};
