import React from "react";
import "../commonStyle.css";
import s from "./style.module.css";
const OptionButton = (props) => {
  const {
    className = `${s["border-rad-30"]} text-center ${s["def-option-style"]}`,
    text,
    isSelected,
    selectedClassName,
    notSelectedClassName,
    onClick,
    id,
    extraClassName,
  } = props;
  //   const [selected, setSelected] = useState(isSelected);
  return (
    <div
      onClick={() => onClick(id)}
      className={
        className +
        " " +
        (isSelected ? selectedClassName : notSelectedClassName) +
        " " +
        extraClassName
      }
    >
      {text}
    </div>
  );
};

export default OptionButton;
