
import { RestMethod } from '../../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import toastr from 'toastr'

export const FileUploadService = {
    fileUloadService,
    WHImportService,
    WHSampleDownload,
    WHChamberSampleDownload,
    WHChamberImportService,
    imageImportService,
    updateImageSequence,
    WHImageSampleDownload,
    fileUloadPdfService,
    retailDataDownload,
    retailImportService
}

/**
 * 
 * @Tapas
 * For upload  service
 */

 async function retailImportService(fileData) {

    try {
        const response = await RestMethod.POST('/import-retail', fileData);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function fileUloadService(fileData) {

    try {
        const response = await RestMethod.POST('/upload', fileData);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function fileUloadPdfService(fileData) {

    try {
        const response = await RestMethod.POST('/upload-pdf', fileData);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function WHImportService(fileData) {

    try {
        const response = await RestMethod.POST('/import-warehouse', fileData);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function WHChamberImportService(fileData) {

    try {
        const response = await RestMethod.POST('/import-chamber', fileData);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function WHSampleDownload() {

    try {
        let respone = await RestMethod.GET('/sample-file')
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'WHSample.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function WHChamberSampleDownload() {

    try {
        let respone = await RestMethod.GET('/sample-chamber')
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ChamberSample.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function WHImageSampleDownload() {
    try {
        let respone = await RestMethod.GET('/sample-image')
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'imageSample.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function imageImportService(fileData) {

    try {
        const response = await RestMethod.POST('/import-image', fileData);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function updateImageSequence(fileData,id) {
    try {
        const response = await RestMethod.PUT('/update-warehouse-image/'+id, fileData);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}



async function retailDataDownload() {

    try {
        let respone = await RestMethod.GET('/sample-retail')
        const url = window.URL.createObjectURL(new Blob([respone.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'RetailSample.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}