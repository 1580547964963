import React, { useState } from "react";
import HeadLines from "./HeadLines";
import "../commonStyle.css";
import styles from "./style.module.css";
import { whiteBackArrow } from "../icon_assets";
import AryaCertified from "../MyWishlist/WishlistCard/AryaCertified/AryaCertified";
import { useHistory } from "react-router";
import SelectLangContent from "../Profile/SelectLangContent";
import BlurModal from "./BlurModal/BlurModal";
import { isMobile } from "../helperFunc";

const Header = ({
  title,
  subtitle,
  backArrow = whiteBackArrow,
  secondaryIcon,
  onSecondaryIconClick,
  onBackArrowClick,
  className,
  titleClassName = ``,
  subtitleClassName = ``,
  secondaryText = "",
  className2 = "",
  divClassName = "margin-left16",
  iconClassName = "",
  secondaryIconClass = "",
  topPaddingClassName = " padding-bottom20 padding-top20",
  showSecondaryText = false,
  backArrowClass = "",
  aryaCertified = false,
  aryaCertifiedCSS = "font-weight500",
  langIcon = "",
  langIconClassName = "",
  langIconAryaIconClassName = "",
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  return (
    <div
      className={`d-flex 
                  justify-content-between
                  textColorWhite
                 ${topPaddingClassName}
                  ${className}`}
    >
      <BlurModal
        show={show}
        onHide={() => setShow(false)}
        showDesktopHeader={!isMobile}
        desktopHeading="Select Language"
      >
        <SelectLangContent />
      </BlurModal>
      <div
        className={`d-flex align-content-center align-items-center
       `}
      >
        {backArrow ? (
          <div
            onClick={
              window.history.length > 2
                ? onBackArrowClick
                : () => history.push("/")
            }
            className={iconClassName}
          >
            <img
              src={backArrow}
              alt=""
              className={`${styles["primaryIconHeightWidth"]} ${backArrowClass}`}
            />
          </div>
        ) : (
          ""
        )}
        <div className={`${divClassName}`}>
          <HeadLines
            className={`font-weight500  letterSpacing015
              ${titleClassName ? titleClassName : `font-size18 line-height21`}
                        `}
            text={title}
          />
          {subtitle && (
            <HeadLines className={subtitleClassName} text={subtitle} />
          )}
        </div>
      </div>
      {secondaryIcon || showSecondaryText ? (
        <div onClick={onSecondaryIconClick} className={className2}>
          <HeadLines text={secondaryText} />
          <img
            src={secondaryIcon}
            alt=""
            className={`${
              secondaryIcon ? styles["secondaryIconHeightWidth"] : ""
            } ${secondaryIconClass}`}
          />
        </div>
      ) : (
        ""
      )}
      {(langIcon || aryaCertified) && (
        <div className={langIconAryaIconClassName}>
          {langIcon ? (
            <img
              src={langIcon}
              className={`${langIconClassName}`}
              onClick={() => setShow(true)}
              alt=""
            />
          ) : (
            ""
          )}
          {aryaCertified ? (
            <AryaCertified
              className={`${styles["backUnset"]} ${aryaCertifiedCSS}`}
              certifiedTextCSS="colorAccent2 font-weight500 line-height12 letterSpacing004 font-size10"
            />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
