import React, { useEffect, useRef, useState } from "react";
import MainHeader from "./MainHeader";
import i18next from "i18next";

// import { isMobile } from "../../helperFunc";
import { eventTracker } from "../../GATracker3.0/eventTracker";
import { LoanApprovalAPI } from "../../../../../_services/Arya2.0/Finance/LAC";
import { LoginServices } from "../../../../../_services/Arya2.0/Login/LoginServices";
import { setUserInfo } from "../../Redux/UserInfo/actions";
import useUserLanguage from "../../CustomHooks/useUserLanguage";
import useIsUserLoggedIn from "../../CustomHooks/useIsUserLoggedIn";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";
import ApprovalSuccessPopUp from "../../Finance/LAC/GetLoanDisbursement/AppprovalSuceessPopUp";
import RejectedPopUp from "../../Finance/LAC/GetLoanDisbursement/RejectedPopUp";
import {
  getLanguageFromStore,
  setUserLanguage,
} from "../../Redux/UserLang/actions";
import { useTranslation } from "react-i18next";
import { getQueryParam } from "../../helperFunc";
import { languageOptions } from "../../Profile/constants";
import { titleCase } from "../../CommonFunctions/Function";
import useUserIP from "../../CustomHooks/useUserIP";

const DesktopHeader = (props) => {
  const HeaderRef = useRef();
  const [stickyHeader, setStickyHeader] = useState(false);
  const [show, setShow] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);

  const [userLoggedIn] = useIsUserLoggedIn();
  const dispatch = useDispatch();
  const userIP = useUserIP(); // set USER IP in local storage
  const userLanguage = useUserLanguage();
  const [disbursedData, setDisbursedData] = useState("");
  const location = useLocation();
  const paramsLanguage = getQueryParam("lang");
  const languageFromReducer = useSelector(getLanguageFromStore) || {};

  const { i18n } = useTranslation();

  const handleScroll = () => {
    const scrollPosition = window.scrollY || window.pageYOffset;
    if (scrollPosition >= 40 && stickyHeader === false) {
      setStickyHeader(true);
      HeaderRef?.current?.classList?.add("HeaderFixedCSS");
    } else {
      setStickyHeader(false);
      HeaderRef?.current?.classList?.remove("HeaderFixedCSS");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      handleScroll();
    });
    return () => {
      document.removeEventListener("scroll", () => {
        handleScroll();
      });
    };
  }, []);

  const logout = async () => {
    let lang = localStorage.getItem("lang");
    var response = await LoginServices.userLogout();

    if (response && response !== null) {
      dispatch(setUserInfo({ userLoggedIn: false }));
      localStorage.clear();
      localStorage.setItem("lang", lang);
      localStorage.setItem("logout", true);
      if (userLanguage) {
        i18next.changeLanguage(userLanguage["lang_id"]);
      }
    }
  };

  useEffect(() => {
    let sessionLogout = localStorage.getItem("sessionLogout");
    if (sessionLogout) {
      var a = moment(sessionLogout);
      var b = moment(new Date());
      var c = Math.abs(a.diff(b, "minutes"));
      var minutes = c;
      if (userLoggedIn) {
        if (minutes > 29) {
          logout();
        }
      }
    }

    setTimeout(() => {
      let disbruse = localStorage.getItem("LacPopup");
      if (disbruse === "approved") {
        apiDisburseCall();
      } else if (disbruse === "expired") {
        setRejectShow(true);
        localStorage.setItem("LacPopup", false);
      }
    }, 3000);
  }, []);

  const apiDisburseCall = async () => {
    const requestList = await LoanApprovalAPI();
    if (
      requestList &&
      requestList?.data?.data &&
      !Array.isArray(requestList?.data?.data)
    ) {
      setDisbursedData(requestList?.data?.data);
      setShow(true);
      localStorage.setItem("LacPopup", false);
    }
  };

  const selectedLanguage = languageOptions.find(
    (el) => el.lang_id == paramsLanguage
  );

  useEffect(() => {
    if (window.location.origin === "https://www.arya.ag") {
      eventTracker("event", "page_view", {
        send_to: "G-JWZVJZLDN9", // PROD GA4
        page_location: window.location.href,
        lang:
          languageFromReducer?.lang_id === "en" || !languageFromReducer?.lang_id
            ? "English"
            : titleCase(languageFromReducer?.lang_id),
        page_path: location.pathname === "/" ? "homepage" : location.pathname,
      });
    } else {
      eventTracker("event", "page_view", {
        send_to: "G-XB7HL3B5Z4", //UAT GA4
        lang:
          languageFromReducer?.lang_id === "en" || !languageFromReducer?.lang_id
            ? "English"
            : titleCase(languageFromReducer?.lang_id),
        page_location: window.location.href,
        page_path: location.pathname === "/" ? "homepage" : location.pathname,
      });
    }

    if (paramsLanguage) {
      localStorage.setItem("lang", selectedLanguage?.lang_id);

      i18n.changeLanguage(selectedLanguage?.lang_id);
      dispatch(
        setUserLanguage({
          ...selectedLanguage,
        })
      );
    }
  }, [location?.pathname]);

  return (
    <>
      {/* <div className={style["sticky-footer-height"]}></div> */}
      {disbursedData && show ? (
        <ApprovalSuccessPopUp
          data={disbursedData}
          show={show}
          setShow={setShow}
        />
      ) : (
        ""
      )}
      {rejectShow && (
        <RejectedPopUp show={rejectShow} setShow={setRejectShow} />
      )}
      <div className="stdFontStyle" ref={HeaderRef}>
        {/* <TopOfHeader /> */}
        <MainHeader
          isActive={props.isActive}
          workEvenIfActive={props.workEvenIfActive}
          stickyHeader={stickyHeader}
          className={props.className}
        />
      </div>
    </>
  );
};
export default DesktopHeader;
