import React from "react";
import TwoLineWidget from "../../../CommonComponents/TwoLineWidget/TwoLineWidget";
import s from "./styles.module.css";
const GridContainer = (props) => {
  const {
    dataDetails = [],
    wrapper = s["grid-container"],
    titleClassName = `${s["two-line-title"]}`,
    subtitleClassName = `${s["two-line-subtitle"]}`,
    titleTexTtruncateRequire = true,
  } = props;
  return (
    <div className={`${wrapper ? wrapper : s["grid-container"]}`}>
      {dataDetails.length > 0 &&
        dataDetails.map((detail) => {
          return (
            <TwoLineWidget
              titleClassName={`${
                titleTexTtruncateRequire && "text-truncate"
              } ${titleClassName}`}
              subtitleClassName={subtitleClassName}
              title={detail.title}
              subtitle={detail.sub_title}
            />
          );
        })}
    </div>
  );
};
export default GridContainer;
