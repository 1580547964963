import React, { useState } from "react";
import style from "./style.module.css";
import Filter from "./Filter";
import Switch from "../../CommonComponents/Switch/Switch";
import HeadLines from "../../CommonComponents/HeadLines";

const FilterByDate = ({
  data = [
    {
      title: " 1 Week",
      id: 0,
    },
    {
      title: " 1 Month",
      id: 1,
    },
    {
      title: " 3 Months",
      id: 2,
    },
    {
      title: " 6 Months",
      id: 3,
    },
    {
      title: " 1 Year",
      id: 4,
    },
  ],
}) => {
  const [activeCan, setActiveCan] = useState(0);
  const [compareSwitch, setCompareSwitch] = useState(false);
  return (
    <>
      <div className="d-flex justify-content-space-between stdFontStyles">
        <div className="d-flex gap10">
          {data.map((item, index) => (
            <Filter
              data={item}
              activeId={activeCan}
              setActiveId={setActiveCan}
              className={`${style["can-common"]} backAccent9  colorAccent4 font-weight400 font-size16 line-height19 letterSpacing05 cursor-pointer`}
              activeClassName={`${style["can-common"]} backAccent4 textColorWhite font-weight400 font-size16 line-height19 letterSpacing05 cursor-pointer`}
            />
          ))}
        </div>
        <div className="d-flex align-items-center">
          <HeadLines
            text="Compare"
            className={
              "font-size14 line-height16 colorDark6 margin-right8 font-weight500 letterSpacing05"
            }
          />
          <Switch
            checked={compareSwitch}
            onChange={() => setCompareSwitch(!compareSwitch)}
            id="compare"
            name="compare"
          />
        </div>
      </div>
      <div className="padding-top12 font-size16 line-height19 letterSpacing05">
        <span>Lowest Price in {data[activeCan].title}: </span>
        <span className="font-weight600 colorDark6">
          {data[activeCan].lowest_price}
        </span>
        <span> (</span>
        <span className="font-weight500 textColorError">
          - {data[activeCan].price_change}
        </span>
        <span> compared to last {data[activeCan].title})</span>
      </div>
      <div className="padding-top12 font-size16 line-height19 letterSpacing05">
        <span>Highest Price in {data[activeCan].title}: </span>
        <span className="font-weight600 colorDark6">
          {data[activeCan].highest_price}
        </span>
        <span> (</span>
        <span className="font-weight500 textColorSuccess">
          + {data[activeCan].price_change}
        </span>
        <span> compared to last {data[activeCan].title})</span>
      </div>
    </>
  );
};

export default FilterByDate;
