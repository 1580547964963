import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import BlurModal from "../../CommonComponents/BlurModal/BlurModal";
import BlurModalContent from "../../CommonComponents/BlurModal/BlurModalContent";
import { CircleLoader } from "react-spinners";
import LoaderCircle from "../../CommonComponents/Loader/LoaderCircle/LoaderCircle";
import { isMobile } from "../../helperFunc";
import BlurModalHeading from "../../CommonComponents/BlurModal/BlurModalHeading";
import HeadLines from "../../CommonComponents/HeadLines";
const VerifyCounter = ({ showModal, onHide, setShowSuccessFailPopup }) => {
  const [countDown, setCountDown] = useState(60);
  const countDownRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      setCountDown(60);
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      countDownRef.current = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(countDownRef.current);
    }
  }, [showModal]);

  useEffect(() => {
    if (countDown === 0) {
      onHide();
      clearInterval(countDownRef.current);
      setShowSuccessFailPopup(true);
    }
  }, [countDown, showModal]);
  return (
    <div className="stdFontStyle">
      <BlurModal show={showModal} onHide={() => onHide()}>
        <BlurModalContent>
          <div
            className={`d-flex flex-d-col align-items-center ${
              isMobile
                ? style["verify-padding"]
                : "padding-top24 padding-left16 padding-right16 padding-bottom24"
            }  justify-content-center ${style["timerModalHeight"]}`}
          >
            <div className="d-flex align-items-center justify-content-center">
              <LoaderCircle circleColor="#A7D3F1" r="16" circleThickness={3} />
              <HeadLines
                text={countDown}
                className={`font-weight600 ${
                  isMobile
                    ? "font-size18 line-height21"
                    : "font-size16 line-height19"
                } letterSpacing005 colorBlue1 text-center`}
              />
            </div>
            <HeadLines
              className={`font-weight400   ${
                isMobile
                  ? `font-size14 line-height16 ${style["margin-top32"]}`
                  : "font-size16 line-height19 margin-top24"
              } letterSpacing005 colorAccent4`}
              text={
                <div className={"textCenter"}>
                  <u
                    className={`font-weight600 text-decoration-none  margin-top24 ${
                      isMobile
                        ? "font-size14 line-height16"
                        : "font-size16 line-height19"
                    } letterSpacing005 colorAccent4 `}
                  >
                    We are verifying your seller’s account details.
                  </u>
                  <br />
                  {` This process may take upto 60 seconds. Please do not go back or exit.`}
                </div>
              }
            />
          </div>
        </BlurModalContent>
      </BlurModal>
    </div>
  );
};
export default VerifyCounter;
