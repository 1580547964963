
import {TRANSLATION_CONSTANT} from '../_constants/Redux/index'

export const translateActions={
    addTranslation,
}

function addTranslation(data){
    return {type:TRANSLATION_CONSTANT.TRANSLATION_SAVE,data}
}
