import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import BlurModal from "../../CommonComponents/BlurModal/BlurModal";
import BlurModalContent from "../../CommonComponents/BlurModal/BlurModalContent";
import { CircleLoader } from "react-spinners";
import LoaderCircle from "../../CommonComponents/Loader/LoaderCircle/LoaderCircle";
import { isMobile } from "../../helperFunc";
import BlurModalHeading from "../../CommonComponents/BlurModal/BlurModalHeading";
import HeadLines from "../../CommonComponents/HeadLines";
import { circleCheck, redUnsuccessIcon } from "../../icon_assets";
import Button from "../../CommonComponents/Button";
const DeletePopUp = ({
  showModal,
  onHide,
  setShowGetHelpPopup,
  setShowBankDetails,
}) => {
  return (
    <div className="stdFontStyle">
      <BlurModal
        show={showModal}
        onHide={() => onHide()}
        showDesktopHeader={!isMobile}
        desktopHeading={"Delete Bank Account"}
      >
        {isMobile && (
          <BlurModalHeading
            className="colorDark5 font-size18 line-height21 font-weight500"
            heading="Delete Bank Account"
          />
        )}
        <BlurModalContent>
          <div>
            <div
              className={`d-flex justify-content-center align-items-center ${
                isMobile ? "margin-top16" : "margin-top24"
              } flex-column`}
            >
              <img
                className={style["success-faild-icon"]}
                src={redUnsuccessIcon}
                alt=""
                srcset=""
              />
              <HeadLines
                className={`${
                  isMobile
                    ? "margin-top16 padding-left16 padding-right16 text-center font-size14 line-height16"
                    : "margin-top16  font-size18 line-height21 "
                } colorDark9  font-weight400`}
                text="Are you sure want to delete the bank account."
              />
              <div
                className={
                  isMobile
                    ? "margin-top16 margin-bottom16 d-flex gap8 w-100 padding-left-right16"
                    : "margin-top16 margin-bottom40"
                }
              >
                <Button
                  className={`  ${
                    isMobile
                      ? `${style["green-border-btn-style"]} w-100 font-weight500 colorAccent3   font-size14 line-height16 padding-top12 padding-bottom12 padding-right6 padding-left6`
                      : `font-size16 padding105 line-height19 ${style["bank-delete-no-style"]}`
                  }  boxRadius6 font-weight500`}
                  title={"No"}
                  onClick={() => {
                    onHide();
                  }}
                />
                <Button
                  className={`${
                    isMobile
                      ? "w-100 padding-top12 padding-bottom12 text-center font-size14 line-height16"
                      : `margin-left8 padding105 font-size16 line-height19 ${style["bank-delete-yes-style"]}`
                  } text-white font-weight500 boxRadius6 backAccent3`}
                  title={"Yes"}
                  onClick={() => {
                    onHide();
                  }}
                />
              </div>
            </div>
          </div>
        </BlurModalContent>
      </BlurModal>
    </div>
  );
};
export default DeletePopUp;
