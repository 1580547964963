import React from "react";
import JSEncrypt from "jsencrypt";
import { public_key_encryption } from "../../../_constants/Config/AryaConstants";
import moment from "moment";

export const encryptData = (data) => {
  // var publicKey = public_key_encryption;
  // "-----BEGIN PUBLIC KEY-----" + $publicKey + "\n-----END PUBLIC KEY-----";
  const publicKey = public_key_encryption;
  const encrypt = new JSEncrypt();

  // Set the public key
  encrypt.setPublicKey(publicKey);

  // Encrypt the data
  const encrypted = encrypt.encrypt(data);

  return encrypted;
};

export const isMobile = window.matchMedia(
  "only screen and (max-width: 1023px)"
).matches;

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setDeviceCookie = () => {
  const platform = window.navigator.platform;
  const randomString =
    Math.random().toString(20).substring(2, 14) +
    Math.random().toString(20).substring(2, 14);

  const deviceID = `${platform}-${randomString}`;
  document.cookie = "Device-Id=" + deviceID + ";domain=.arya.ag";
};
export const setCrTokenCookie = () => {
  document.cookie =
    "arya_ag_cr_token=aed5437865e06db5414f56a69e334a3d;domain=.arya.ag";
};

export const getChunkedArray = (inputArray = [], perChunk = 2) => {
  // items per chunk

  const result =
    inputArray?.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []) || [];

  return result;
};

export const toNumberFunc = (string) => Number(string || 0);

export const getQueryParam = (key) => {
  const query = new URLSearchParams(window.location.search);
  return decodeURIComponent(query.get(key)) === "null"
    ? null
    : decodeURIComponent(query.get(key));
};

export const exactDecimalPlaces = (string, decimalPlaces) => {
  var regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`);
  var output = string.toString().match(regex)[0];
  return output;
};

//Will Return data format2 like 3rd Jan’ 24
export const CustomDateFormat = ({ date }) => {
  // Function to add the appropriate ordinal suffix to a day
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Function to format a date in the desired format with superscript for the ordinal
  const formatDateWithSuperscript = (inputDate) => {
    const date = new Date(inputDate);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date?.getDate();
    const monthIndex = date?.getMonth();
    const year = date?.getFullYear();

    return (
      <>
        {day}
        <sup>{getOrdinalSuffix(day)}</sup> {monthNames[monthIndex]}’ {year}
      </>
    );
  };

  return <>{formatDateWithSuperscript(date)}</>;
};

//take
export const getLastMonthDates = (numberofLastmonth) => {
  var today = new Date();
  var end = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the current month
  var start = new Date(end);
  start.setMonth(start.getMonth() - numberofLastmonth); // Move back to number months

  // Adjust start date to the first day of the month
  start.setDate(1);

  // Adjust end date to the last day of the month
  end.setDate(0);

  return {
    start: moment(start).format("YYYY-MM-DD"),
    end: moment(end).format("YYYY-MM-DD"),
  };
};
