import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "../helperFunc";
import HeadLines from "./HeadLines";
import style from "./style.module.css";

const InputText = ({
  className,
  disableInputClassName = "",
  errorInputClassName = "",
  errorClassName = "",
  errorIcon = "",
  errorIconClassName = `${style["error-icon-style"]}`,
  disabled = false,
  placeholder = "",
  value,
  onChange,
  name,
  showError,
  isVerified,
  verifiedClassName,
  type = "text",
  maxLength = "",
  onBlur,
  onMouseLeave,
  rightInputFixedText = "",
  rightInputFixedTextClassName,
  leftInputFixedText = "",
  onClick = () => null,
  autoComplete,
  inputRef = null,
  onKeyUp = () => {},
  isMultiple = false,
  accept = "",
  errorTextClassName,
}) => {
  let options = {};
  if (autoComplete) options = { ...options, autoComplete };
  if (accept) options = { ...options, accept };
  if (onClick) options = { ...options, onClick };
  const { t } = useTranslation();

  return (
    <>
      {leftInputFixedText?.length > 0 && (
        <span
          className={`positionAbsolute line-height16 ${style["left-input-fixed-text"]} colorDark9`}
        >
          {leftInputFixedText}
        </span>
      )}
      <input
        onWheel={(event) => event.currentTarget.blur()}
        className={`${className} 
        ${disabled && disableInputClassName}
        ${showError && errorInputClassName}
        ${isVerified && verifiedClassName}
        overWriteInputButtonCSS`}
        placeholder={t(placeholder)}
        value={value}
        autoComplete="off"
        multiple={isMultiple}
        onChange={(e) => {
          //check only done for number
          if (type === "number") {
            if (
              //if not invalid character
              !isNaN(Number(e.target.value)) &&
              // if not spaces
              !(e.target.value.length > 0 && e.target.value.trim() === "")
            )
              //let it change the value
              onChange(e);
          } else onChange(e);
        }}
        name={name}
        disabled={disabled}
        type={type === "number" ? "text" : type}
        onKeyUp={onKeyUp}
        // onKeyDown={(e) => {
        //   const event = e;
        //   alert(event.key, event.keyCode, event.which);
        // }}
        // onKeyDown={type === "number" ? keyPressObject.onKeyPress : () => {}} //not allowing - or + keypress when type === number
        maxLength={maxLength}
        onBlur={onBlur}
        onMouseLeave={onMouseLeave}
        ref={inputRef}
        {...options}
      />
      {rightInputFixedText?.length > 0 && (
        <span
          className={`positionAbsolute line-height16 ${style["right-input-fixed-text"]} colorDark9 ${rightInputFixedTextClassName}`}
        >
          {rightInputFixedText}
        </span>
      )}
      {showError ? (
        <div
          className={`d-flex align-items-center padding-top4 ${errorClassName}`}
        >
          {errorIcon && (
            <img
              src={errorIcon}
              className={`${
                style["errorCSS"]
              } ${errorIconClassName} display-inline-block margin-right4 ${
                isMobile ? "" : style["width18px"]
              }`}
              alt=""
            />
          )}
          <HeadLines
            className={`${style["errorCSS"]}
            ${errorTextClassName}
            font-size12 display-inline-block   ${
              isMobile ? "" : style["desktopCssImp"]
            }`}
            text={
              showError
              // <>
              //   {errorIcon && (
              //     <img
              //       src={errorIcon}
              //       className={`${errorIconClassName}`}
              //       alt=""
              //     />
              //   )}
              //   {showError}
              // </>
            }
          ></HeadLines>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default InputText;
