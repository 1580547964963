import { useState, useEffect } from "react";

export const useClickOutside = (ref, initialvalue = false) => {
  const [flag, setFlag] = useState(initialvalue);
  useEffect(() => {
    document.addEventListener("click", callback);
    return () => {
      document.removeEventListener("click", callback);
    };
  }, []);
  const callback = function (e) {
    if (ref?.current?.contains?.(e.target)) {
      setFlag(false);
    } else setFlag(true);
  };

  return flag;
};
