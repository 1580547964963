import React, { useEffect, useRef } from "react";
import { manageUserOptions } from "./constant";
import style from "./styles.module.css";
import { useClickOutside } from "../CustomHooks/useClickOutside";

const ManageUserMenu = ({
  showDropdown,
  setShowDropdown,
  handleEditUser,
  handleDeleteUser,
}) => {
  const ref = useRef();
  const flag = useClickOutside(ref);

  useEffect(() => {
    if (flag) {
      setShowDropdown(false);
    }
  }, [flag]);
  return (
    <div ref={ref}>
      {showDropdown && (
        <div className={style["desktopMenuDiv"]}>
          {manageUserOptions.map((item) => (
            <div key={item.name}>
              <div
                onClick={() => {
                  if (item.name === "Edit") handleEditUser();
                  else handleDeleteUser();
                  setShowDropdown(false);
                }}
                className={`margin-bottom16 ${style["desktopMenuOptionDiv"]}`}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManageUserMenu;
