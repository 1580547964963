import React from "react";

const iconsFetch = (iconName, isActive, desktop) => {
  let color = desktop
    ? isActive
      ? "#ffffff"
      : "#e2e2e2"
    : isActive
    ? "#127960"
    : "#656E71";
  const icons = {
    Home: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clip-path="url(#clip0_461_4412)">
          <path
            d="M14.25 19.5V15C14.25 14.801 14.171 14.6103 14.0303 14.4696C13.8897 14.329 13.6989 14.25 13.5 14.25H10.5C10.3011 14.25 10.1103 14.329 9.96967 14.4696C9.82902 14.6103 9.75 14.801 9.75 15V19.5C9.75 19.6989 9.67098 19.8896 9.53033 20.0303C9.38968 20.1709 9.19891 20.25 9 20.25H4.5C4.30109 20.25 4.11032 20.1709 3.96967 20.0303C3.82902 19.8896 3.75 19.6989 3.75 19.5V10.8281C3.75168 10.7243 3.77411 10.6219 3.81597 10.5269C3.85783 10.4319 3.91828 10.3462 3.99375 10.275L11.4937 3.45933C11.632 3.33284 11.8126 3.2627 12 3.2627C12.1874 3.2627 12.368 3.33284 12.5062 3.45933L20.0062 10.275C20.0817 10.3462 20.1422 10.4319 20.184 10.5269C20.2259 10.6219 20.2483 10.7243 20.25 10.8281V19.5C20.25 19.6989 20.171 19.8896 20.0303 20.0303C19.8897 20.1709 19.6989 20.25 19.5 20.25H15C14.8011 20.25 14.6103 20.1709 14.4697 20.0303C14.329 19.8896 14.25 19.6989 14.25 19.5Z"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
        </g>
        <defs>
          <clipPath id="clip0_461_4412">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    Profile: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clip-path="url(#clip0_12463_2554)">
          <path
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
            stroke="#656E71"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
            stroke="#656E71"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.16797 18.849C6.41548 18.0252 6.92194 17.3032 7.61222 16.79C8.30249 16.2768 9.13982 15.9997 9.99997 16H14C14.8612 15.9997 15.6996 16.2774 16.3904 16.7918C17.0811 17.3062 17.5874 18.0298 17.834 18.855"
            stroke="#656E71"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_12463_2554">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    Storage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clip-path="url(#clip0_461_4417)">
          <path
            d="M3 21V8L12 4L21 8V21"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
          <path
            d="M13 13H17V21H7V15H13"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
          <path
            d="M13 21V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11H10C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12V15"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
        </g>
        <defs>
          <clipPath id="clip0_461_4417">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    Trade: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clip-path="url(#clip0_461_4424)">
          <path
            d="M7.5 15H19.5C19.6989 15 19.8897 14.921 20.0303 14.7803C20.171 14.6397 20.25 14.4489 20.25 14.25V4.5C20.25 4.30109 20.171 4.11032 20.0303 3.96967C19.8897 3.82902 19.6989 3.75 19.5 3.75H9C8.80109 3.75 8.61032 3.82902 8.46967 3.96967C8.32902 4.11032 8.25 4.30109 8.25 4.5V5.25"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
          <path
            d="M9.75 12.75L7.5 15L9.75 17.25"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
          <path
            d="M16.5 9H4.5C4.30109 9 4.11032 9.07902 3.96967 9.21967C3.82902 9.36032 3.75 9.55109 3.75 9.75V19.5C3.75 19.6989 3.82902 19.8897 3.96967 20.0303C4.11032 20.171 4.30109 20.25 4.5 20.25H15C15.1989 20.25 15.3897 20.171 15.5303 20.0303C15.671 19.8897 15.75 19.6989 15.75 19.5V18.75"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
          <path
            d="M14.25 11.25L16.5 9L14.25 6.75"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
        </g>
        <defs>
          <clipPath id="clip0_461_4424">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    Finance: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clip-path="url(#clip0_461_4432)">
          <path
            d="M18 5H7H10C11.0609 5 12.0783 5.42143 12.8284 6.17157C13.5786 6.92172 14 7.93913 14 9C14 10.0609 13.5786 11.0783 12.8284 11.8284C12.0783 12.5786 11.0609 13 10 13H7L13 19"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
          <path
            d="M7 9H18"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width={isActive ? "1.5" : "1"}
          />
        </g>
        <defs>
          <clipPath id="clip0_461_4432">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    BackToTop: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clip-path="url(#clip0_1_4604)">
          <path
            d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
            stroke="#127960"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.0003 6.66602L6.66699 9.99935"
            stroke="#127960"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 6.66602V13.3327"
            stroke="#127960"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.3333 9.99935L10 6.66602"
            stroke="#127960"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_4604">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  };
  return icons[iconName];
};
export default function IconsSvg({ iconName, isActive, desktop = false }) {
  return <>{iconsFetch(iconName, isActive, desktop)}</>;
}
