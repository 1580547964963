import React from "react";
import { socialIcons } from "./constant";
import style from "./style.module.css";

const SocialLinks = () => {
  return (
    <>
      {socialIcons.map((item) => {
        return (
          <a href={item.link} target="_blank" rel="noreferrer">
            <img
              src={item.icon}
              alt=""
              className={`margin-right8 margin-bottom8 ${
                item.height
                  ? style["IconHeightWidth20"]
                  : style["IconHeightWidth24"]
              }`}
            />
          </a>
        );
      })}
    </>
  );
};
export default SocialLinks;
