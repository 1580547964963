import React, { useEffect, useState } from "react";
import HeadLines from "../CommonComponents/HeadLines";
import { isMobile } from "../helperFunc";
import style from "./style.module.css";
const Suggestion = (props) => {
  const { defaultMarginClass = "" } = props;
  const [selected, setSelected] = useState(
    props.type == "location"
      ? props.data.preferred_buyer_locations
      : props.data.preferred_buyer_commodities
  );
  const [suggestion, setSuggestion] = useState(props.dataArray);
  useEffect(() => {
    props.setData((prev) => ({
      ...prev,
      [props.type == "location"
        ? "preferred_buyer_locations"
        : "preferred_buyer_commodities"]: selected,
    }));
  }, [selected]);
  useEffect(() => {
    setSelected(
      props.type == "location"
        ? props?.data?.preferred_buyer_locations
        : props?.data?.preferred_buyer_commodities
    );
  }, [props?.data?.preferred_buyer_commodities?.length, props?.data?.preferred_buyer_locations?.length])
  useEffect(() => {
    setSuggestion(props.dataArray);
  }, [props.dataArray]);
  const RecommendationsClicked = (label, obj) => {
    let newArray = suggestion.filter((item) => {
      let newLabel = item?.label;
      return newLabel != label;
    });
    setSuggestion(newArray);
    setSelected((prev) => [...prev, obj]);
    props.onChange(props.selectedCount + 1);
  };
  const SelectedClicked = (label, obj) => {
    let newArray = selected.filter((item) => {
      let newLabel = item?.label;
      return newLabel != label;
    });
    setSelected(newArray);
    setSuggestion((prev) => [...prev, obj]);
    props.onChange(props.selectedCount - 1);
  };

  return (
    <>
      {suggestion?.length ? (
        <>
          {" "}
          <HeadLines
            text="Recommendations"
            className={`${defaultMarginClass} margin-left16 font-weight500 ${style["padding-bottom10"]
              } ${isMobile
                ? ` font-size12 line-height14  `
                : `  font-size14 line-height16 `
              } ${style["lightGrey"]} letterSpacing025`}
          />{" "}
          <div className={`${defaultMarginClass} d-flex flex-wrap margin-left16 margin-right16 margin-bottom8`}>
            {suggestion.map((item) => {
              let flag = 1;
              if (selected?.length) {
                selected?.filter((ele) => {
                  if (ele?.label == item?.label) {
                    flag = 0;
                  }
                });
              }
              if (flag)
                return (
                  <div
                    onClick={() => {
                      RecommendationsClicked(item?.label, item);
                    }}
                    className={`${isMobile
                      ? ` font-weight400 font-size12 line-height14 letterSpacing025 `
                      : `  cursorPointer font-weight400 font-size16 line-height19 letterSpacing05 colorDark2`
                      }  margin-bottom8 padding-top4 margin-right8 padding-bottom4 padding-left8 padding-right8 ${style["sug_back"]
                      } ${style["boxRadius40"]}`}
                  >
                    {item?.city_lang
                      ? item?.city_lang
                      : item?.commodity_lang
                        ? item?.commodity_lang
                        : item.label}
                  </div>
                );
            })}
          </div>
        </>
      ) : (
        ""
      )}
      {selected?.length ? (
        <>
          {" "}
          <HeadLines
            text="Selected"
            className={`${defaultMarginClass} margin-left16 ${style["padding-bottom10"]
              } font-weight500 ${isMobile
                ? ` font-size12 line-height14  `
                : `  font-size14 line-height16 `
              } letterSpacing025 ${style["lightGrey"]}`}
          />{" "}
          <div className={`${defaultMarginClass} d-flex flex-wrap  margin-left16 margin-right`}>
            {selected.map((item) => {
              return (
                <div
                  onClick={() => {
                    SelectedClicked(item?.label, item);
                  }}
                  className={`${isMobile
                    ? ` font-weight400 font-size12 line-height14 letterSpacing025 `
                    : `   cursorPointer font-weight400 font-size16 line-height19 letterSpacing05 colorDark2`
                    } margin-bottom8 padding-top4 margin-right8 padding-bottom4 padding-left8 padding-right8 ${style["sel_back"]
                    } ${style["boxRadius40"]}`}
                >
                  {item?.city_lang
                    ? item?.city_lang
                    : item?.commodity_lang
                      ? item?.commodity_lang
                      : item?.label}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default Suggestion;
