import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import BlurModal from "../../CommonComponents/BlurModal/BlurModal";
import BlurModalContent from "../../CommonComponents/BlurModal/BlurModalContent";
import { CircleLoader } from "react-spinners";
import LoaderCircle from "../../CommonComponents/Loader/LoaderCircle/LoaderCircle";
import { isMobile } from "../../helperFunc";
import BlurModalHeading from "../../CommonComponents/BlurModal/BlurModalHeading";
import HeadLines from "../../CommonComponents/HeadLines";
import { circleCheck, redUnsuccessIcon } from "../../icon_assets";
import Button from "../../CommonComponents/Button";
const VerifySuccessFailPopUp = ({
  showModal,
  onHide,
  result = "failed",
  setShowGetHelpPopup,
  setShowBankDetails,
}) => {
  return (
    <div className="stdFontStyle">
      <BlurModal
        show={showModal}
        onHide={() => onHide()}
        showDesktopHeader={!isMobile && result === "success"}
        showCross={!isMobile && result === "failed"}
        desktopHeading={"Verify"}
      >
        <BlurModalContent>
          <div>
            <div
              className={`d-flex justify-content-center align-items-center ${
                isMobile ? "margin-top30" : "margin-top40"
              } flex-column`}
            >
              <img
                className={style["success-faild-icon"]}
                src={result === "failed" ? redUnsuccessIcon : circleCheck}
                alt=""
                srcset=""
              />
              <HeadLines
                className={`${
                  isMobile
                    ? "margin-top16  font-weight500 font-size14 line-height16"
                    : "margin-top24  font-weight600 font-size18 line-height21"
                } textColorBlack`}
                text="Verification Failed"
              />
              <HeadLines
                className={`${
                  isMobile
                    ? "margin-top16 padding-left32 padding-right32 text-center"
                    : "margin-top24 "
                } colorDark9 font-size14 line-height16  font-weight400`}
                text="Your bank verification failed. Our team will get in touch with you shortly."
              />
              <div
                className={
                  isMobile
                    ? "margin-top16 margin-bottom16 d-flex gap8 w-100 padding-left-right16"
                    : "margin-top24 margin-bottom40"
                }
              >
                <Button
                  className={`  ${
                    isMobile
                      ? `${style["green-border-btn-style"]} w-100 font-weight500 colorAccent3   font-size14 line-height16 padding-top12 padding-bottom12 padding-right6 padding-left6`
                      : `backAccent3  font-size16 line-height19 text-white ${style["failed-btn-styles"]}`
                  }  boxRadius6 font-weight500`}
                  title={"Get Help in Verification"}
                  onClick={() => {
                    onHide();
                    setShowGetHelpPopup(true);
                  }}
                />
                <Button
                  className={`${
                    isMobile
                      ? "w-100 padding-top12 padding-bottom12 text-center font-size14 line-height16"
                      : `margin-left8   font-size16 line-height19 ${style["failed-btn-styles"]}`
                  } text-white font-weight500 boxRadius6 backAccent3`}
                  title={"Resubmit & Validate"}
                  onClick={() => {
                    onHide();
                    setShowBankDetails(true);
                  }}
                />
              </div>
            </div>
          </div>
        </BlurModalContent>
      </BlurModal>
    </div>
  );
};
export default VerifySuccessFailPopUp;
