import React, { useEffect } from "react";
import HeadLines from "../CommonComponents/HeadLines";
import s from "./styles.module.css";
import { isMobile } from "../helperFunc";
let i;
const ResentOTP = (props) => {
  const { reSendOtp, timer, setTimer, enable, setEnable, firstTimeOTP } = props;

  useEffect(() => {
    if (enable) return;
    i = setInterval(() => setTimer((prev) => prev - 1), 1000);
    return () => {
      clearInterval(i);
    };
  }, [enable]);

  useEffect(() => {
    if (timer === 0 || timer === "0") {
      clearInterval(i);
      setEnable(true);
    }
  }, [timer]);

  return (
    <div>
      <HeadLines
        className={`
                    margin-top12
                    font-weight400
                    ${
                      isMobile
                        ? `font-size12
                          line-height14`
                        : `font-size16
                          line-height19`
                    }
                    letterSpacing025
                    colorDark9
                    ${isMobile ? s["margin-bottom84"] : ``}
      ${enable ? "textColorBlue" : "colorDark9"}`}
        onClick={() => {
          if (enable) {
            reSendOtp();
          }
        }}
      >
        {firstTimeOTP ? "Resend OTP?" : "OTP Resent"}
        {"   "}
        {!enable &&
          `00:${Number(timer).toLocaleString("en-IN", {
            minimumIntegerDigits: 2,
          })}`}
      </HeadLines>
    </div>
  );
};

export default ResentOTP;
