import React from "react";
import HeadLines from "../HeadLines";
import s from "./styles.module.css";
const BlurModalHeading = ({
  className = "heading",
  heading,
  showHeadingSepertor = true,
  hrClassName = "",
  isDesktop = false,
}) => {
  return (
    <>
      <HeadLines
        className={
          ` padding-left16 padding-bottom16 ${
            isDesktop ? "padding-top12" : s["padding-top20"]
          } ` + className
        }
      >
        {heading}
      </HeadLines>
      {showHeadingSepertor && <hr className={hrClassName} />}
    </>
  );
};

export default BlurModalHeading;
