import React from "react";
import { editProfile } from "../icon_assets";

const EditButton = (props) => {
  const { onEditButtonClick, className = "", editIconWidth = 20 } = props;
  return (
    <div onClick={onEditButtonClick} className={className}>
      <img src={editProfile} alt="" width={editIconWidth || 20} />
    </div>
  );
};

export default EditButton;
