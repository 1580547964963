import React from "react";
import { certifiedIcon as cicon } from "../../../icon_assets";
import HeadLines from "../../../CommonComponents/HeadLines";
import "../../../commonStyle.css";

import styles from "./styles.module.css";
import { isMobile } from "../../../helperFunc";

const AryaCertified = (props) => {
  const {
    className,
    certifiedIcon = cicon,
    iconWidth = isMobile ? "10.5px" : "16",
    iconHeight = isMobile ? "11px" : "16",
    topLeftCorderRoundedForCertified = true,
    certifiedTextCSS = `colorAccent2 ${
      isMobile
        ? `font-weight400 line-height12 letterSpacing004 font-size10`
        : `font-weight400 line-height19 letterSpacing05 font-size16`
    }`,
  } = props;
  return (
    <div
      className={`d-flex ${styles["aryaCertifiedCSS"]}
    ${!topLeftCorderRoundedForCertified && styles["border-left-rad-not-rouned"]}
    gap8 ${className}
    `}
    >
      <img
        src={certifiedIcon}
        width={iconWidth}
        height={iconHeight}
        alt="certifiedIcon"
      />
      <HeadLines text="Arya Certified" className={certifiedTextCSS} />
    </div>
  );
};

export default AryaCertified;
