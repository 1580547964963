import React from "react";

const Filter = ({ data, activeId, setActiveId, className, activeClassName }) => {
  return (
    <div
      className={activeId === data.id ? activeClassName : className}
      onClick={() => setActiveId(data.id)}
    >
      {"Last " + data.title}
    </div>
  );
};

export default Filter;
