import React from "react";

export default function ClockSvg(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke={props?.color ? props.color : "#F4B53F"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 7.99935L10 9.33268M8 4.66602V7.99935"
        stroke={props?.color ? props.color : "#F4B53F"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
