/**
 * @param {string} command Command to GA, usually 'event'
 * @param {string} action type of event
 * @param {object} event Object for extra properties
 */

import { isMobile } from "../helperFunc";
import { getLastSixDigits } from "./utils";

export const eventTracker = (command = "event", action, params = {}) => {
  const userDashBoardData = JSON.parse(
    localStorage.getItem("userDashBoardData")
  );

  const isGetOTPPhoneNumber = params.phone_number;
  const localIP = localStorage.getItem("IP");

  const eventPhoneNumber = getLastSixDigits(
    isGetOTPPhoneNumber || userDashBoardData?.member?.phone_number
  );

  return window.gtag
    ? window.gtag(command, action, {
        ...params,
        userid: userDashBoardData?.member?.id || "NA",
        phone_number: eventPhoneNumber || "NA",
        user_agent: window.navigator.userAgent,
        device_type: isMobile ? "Mobile" : "Desktop",
        is_old_arya: false,
        ...(localIP && { ip_address: localIP }),
      })
    : "";
};
