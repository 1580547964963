import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'

const parserApi = async (api,message=false) => {
    try {
        const response = await RestMethod.GET(api ? api : '');
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
       if(message) toastr.error(message+". Please try later")
       else toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

export const bookingContractList = async () => {
    var reuestlink = "/booking-contract-list-data";
    return parserApi(reuestlink,"Failed to load filter data")
}


export const paymentLog = async (id) => {
    var reuestlink = "/contract-rent-history?contract_id="+id;
    return parserApi(reuestlink)
}



