import React from "react";
import { useHistory } from "react-router";
import BlurModal from "../../../CommonComponents/BlurModal/BlurModal";
import BlurModalContent from "../../../CommonComponents/BlurModal/BlurModalContent";
import BlurModalHeading from "../../../CommonComponents/BlurModal/BlurModalHeading";
import Button from "../../../CommonComponents/Button";
import HeadLines from "../../../CommonComponents/HeadLines";
import { circleCheck } from "../../../icon_assets";

const ApprovalSuccessPopUp = ({ show, setShow, data }) => {
  const history = useHistory();
  return (
    <>
      <BlurModal
        className={"blur-modal"}
        show={show}
        onHide={() => setShow(false)}
      >
        <>
          <BlurModalHeading
            className={`
                    padding-top24 
                    padding-bottom24
                    margin-top8
                    textCenter
                    `}
            heading={
              <>
                <img src={circleCheck} alt="" />
                <HeadLines
                  text={"Congratulations"}
                  className="font-weight700 colorAccent4 padding-top12"
                />
              </>
            }
            showHeadingSepertor={false}
          />
          <BlurModalContent className="text-center">
            <div className="d-flex align-items-center justify-content-center margin-left16 margin-right16 ">
              <HeadLines
                className={`font-weight400 
                        font-size12
                        line-height14
                        letterSpacing025
                        colorDark9
                        padding-left16 padding-right16
                        `}
                text={`You have received Arya's WRF offer on ${data.commodity} of Rs. ${data.final_disbursement_amount} against ${data.net_weight_funded} MT. Offer Valid till ${data.valid_till}`}
              />
            </div>
            <div className="margin16">
              <Button
                className={`font-weight500 font-size14 line-height16 letterSpacing0125 backAccent4 padding-top12 padding-bottom12 boxRadius6 textColorWhite width-100  `}
                title="View Details"
                onClick={() => history.push("/disbursement/" + data.sr_id)}
              />
            </div>
          </BlurModalContent>
        </>
      </BlurModal>
    </>
  );
};
export default ApprovalSuccessPopUp;
