import React from "react";
import HeadLines from "../HeadLines";

const Title = (props) => {
  const { titleClassName, title, isCompulsory, compulsoryStarClassName } =
    props;
  return (
    <div className="d-flex">
      <HeadLines className={titleClassName} text={title} />
      {isCompulsory && <div className={`${compulsoryStarClassName}`}>*</div>}
    </div>
  );
};

export default Title;
