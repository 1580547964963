import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../_helpers/FilterResponse";
import toastr from "toastr";

const callApi = async (api, Method, data, message = "", bool = false) => {
  try {
    const response = await RestMethod[Method](api, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    if (message) {
      if (bool) toastr.warning(message);
      else toastr.error(message);
    } else toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const warehouseBookedData = async () => {
  var reuestlink = "/warehouse-booked-data";
  return callApi(
    reuestlink,
    "GET",
    "",
    "Failed to load warehouses on previous contracts. Please try later",
    true
  );
};

export const getLocationByLatLong = async (data) => {
  var reuestlink = "/location-lat-long";
  return callApi(
    reuestlink,
    "POST",
    data,
    "Failed to fetch location. Please try later"
  );
};

export const warehouseBookingRequest = async (data) => {
  var reuestlink = "warehouse-booking-request";
  return callApi(
    reuestlink,
    "POST",
    data,
    "Failed to book warehouse. Please try later"
  );
};

export const getLatLongByIP = async () => {
  let res = await fetch("https://geolocation-db.com/json/").then((res) =>
    res.json()
  );

  return res;
};

export const getLocationByIp = async () => {
  var reuestlink = "/location-ip";
  return callApi(reuestlink, "GET");
};

export const getCityStateByLatLong = async (data) => {
  var reuestlink = "/getCityStateByLatLong";
  return callApi(
    reuestlink,
    "POST",
    data,
    "Failed to fetch location. Please try later"
  );
};
