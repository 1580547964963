import React from "react";
import BlurModal from "../BlurModal/BlurModal";
import BlurModalContent from "../BlurModal/BlurModalContent";
import BlurModalHeading from "../BlurModal/BlurModalHeading";

const OverThePageComponent = ({
  show,
  setShow,
  children,
  parentDivClassName = null,
}) => {
  //   const [selected, setSelected] = useState("");
  return (
    <>
      <BlurModal
        className={"blur-modal OverThePageModal"}
        show={show}
        onHide={() => setShow(false)}
      >
        <>
          <BlurModalHeading
            className={`padding-left16
                        padding-bottom16
                       `}
            // heading={heading}
          />
          <BlurModalContent
            className={"margin16 padding-bottom16 "}
            parentDivClassName={parentDivClassName}
          >
            {children}
          </BlurModalContent>
        </>
      </BlurModal>
    </>
  );
};
export default OverThePageComponent;
