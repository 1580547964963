import React from "react";
import s from "./styles.module.css";
import { isMobile } from "../../helperFunc";
const CustomRadioBtn = (props) => {
  const { id = "test", className, inputProps } = props;
  return (
    <>
      <label
        htmlFor={id}
        className={`${s["custom-radiobtn"]}  ${className} ${
          !isMobile && "cursorPointer"
        }`}
      >
        <input name={id} id={id} type={"radio"} {...inputProps} />
        <span className="radio" />
      </label>
    </>
  );
};

export default CustomRadioBtn;
