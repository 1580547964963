import React from "react";
import MultiLanguageTranslation from "../../../../_components/Translate/MultiLangTranslate";
const HeadLines = ({ className, text, style, onClick, children }) => {
  return (
    <div className={className} style={style} onClick={onClick}>
      {/* {children || text} */}
      {children ? (
        children && typeof children === "string" ? (
          <MultiLanguageTranslation title={children} />
        ) : (
          children
        )
      ) : text && typeof text === "string" ? (
        <MultiLanguageTranslation title={text} />
      ) : (
        text
      )}
    </div>
  );
};
export default HeadLines;
