import { recentConstants } from '../_constants/Redux';

export const recentActions = {
    add,
    error,
    clear
};

function add(id) {
    return { type: recentConstants.ADD, id }
}

function error(message) {
    return { type: recentConstants.ERROR, message };
}

function clear() {
    return { type: recentConstants.CLEAR };
}