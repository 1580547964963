import React from "react";
import s from "./style.module.css";
import NotificationCards from "../../Header/Notification/NotificationCards";

const NotificationPopUp = React.forwardRef(
  ({ ref, data = [], className = "" }) => {
    // const [internalSelectedOption, setInternalSelectedOption] = useState(null);

    return (
      <div className={`${s["w-card-style"]} ${className}`} ref={ref}>
        {/* <HeadLines className={`${s["card-title"]} `} text={headingTittle} /> */}
        <div className="">
          <NotificationCards data={data} />
        </div>
      </div>
    );
  }
);

export default NotificationPopUp;
