import React from "react";
import { useHistory } from "react-router";
import LoginPart from "./LoginPart";
import MainModuleHeading from "./MainModuleHeading";
import style from "./style.module.css";
import NotificationDesktop from "./NotificationDesktop";
import Widgets from "./Widgets";
import aryaLogo from "./../../DesktopImages/Home/aryaLogo.svg";
const MainHeader = ({
  className,
  isActive,
  stickyHeader,
  workEvenIfActive,
}) => {
  const history = useHistory();
  // const LinkClick = (url) =>{
  //     history.push(url)
  // }
  return (
    <>
      <div
        className={`${
          stickyHeader ? style["positionFixedHeader"] : ""
        } d-flex justify-content-space-between padding-left-right100  align-items-center  ${
          style["height60"]
        } ${className ? className : style["linearGradient"]}`}
      >
        <div className="align-items-center d-flex gap24  colorDark6">
          <div className={``}>
            <img
              src={aryaLogo}
              alt="logo"
              className={` cursorPointer`}
              onClick={() => {
                history.push("/");
              }}
            />
          </div>
          <div className={`d-flex ${style["gap32"]}`}>
            <MainModuleHeading
              isActive={isActive}
              workEvenIfActive={workEvenIfActive}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap24">
          <Widgets />
          <NotificationDesktop />
          <LoginPart />
        </div>
      </div>
    </>
  );
};
export default MainHeader;
