import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../_helpers/FilterResponse";
import toastr from "toastr";

const callApi = async (api, Method, data) => {
  try {
    const response = await RestMethod[Method](api, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    // toastr.error('Something Went Wrong')
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const addBankOnDTR = async (data) => {
  var reuestlink = "/dtr-add-bank";
  return callApi(reuestlink, "POST", data);
};

export const getDTRState = async () => {
  var reuestlink = "/dtr-state";
  return callApi(reuestlink, "GET");
};

export const getDTRCity = async (id) => {
  var reuestlink = "/dtr-city/" + id;
  return callApi(reuestlink, "GET");
};

export const getBankOfUser = async (id) => {
  var reuestlink = "/dtr-bank-list/" + id;
  return callApi(reuestlink, "GET");
};

export const getQualityParameter = async (id, type) => {
  var reuestlink = "/get-qp/" + id;
  if (type) {
    reuestlink = reuestlink + "?type=arya";
  }
  return callApi(reuestlink, "GET");
};

export const Options = async (option) => {
  var reuestlink = "/options/" + option;
  return callApi(reuestlink, "GET");
};

export const getDTRWarehose = async (id) => {
  var reuestlink = "/dtr-wh-list/" + id;
  return callApi(reuestlink, "GET");
};

export const saveStpContract = async (data) => {
  var reuestlink = "/stp-contract";
  return callApi(reuestlink, "POST", data);
};

export const getBuyerContracts = async (data) => {
  var reuestlink = "/dtr-buyer-contract-list";
  return callApi(reuestlink, "POST", data);
};

export const getBuyerContractDetails = async (id) => {
  var reuestlink = "/dtr-buyer-contract/" + id;
  return callApi(reuestlink, "GET");
};

export const getContractsByBD = async (data = {}) => {
  var reuestlink = "/dtr-contract-by-bd";
  return callApi(reuestlink, "POST", data);
};

export const uploadContractAgreement = async (data) => {
  var reuestlink = "/dtr-upload-agreement";
  return callApi(reuestlink, "POST", data);
};

export const dtrContractDetails = async (id) => {
  var reuestlink = "/dtr-contract-detail/" + id;
  return callApi(reuestlink, "GET");
};

export const updateContractStatus = async (data = {}) => {
  var reuestlink = "/contract/updateContractStatus";
  return callApi(reuestlink, "POST", data);
};

export const sanctionLimit = async (id) => {
  var reuestlink = "sanction-limit/" + id;
  return callApi(reuestlink, "GET");
};

export const BankDetailsData = async (id) => {
  var reuestlink = "/dtr-bank-details/" + id;
  return callApi(reuestlink, "GET");
};

export const marginPayment = async (data) => {
  var reuestlink = "/margin-payment";
  return callApi(reuestlink, "POST", data);
};

export const updateStpUser = async (id, data) => {
  var reuestlink = "/update-stp-user/" + id;
  return callApi(reuestlink, "PUT", data);
};

export const dtrBankDetails = async (id) => {
  var reuestlink = "/dtrbank-details/" + id;
  return callApi(reuestlink, "GET");
};

export const updateDTRBank = async (data) => {
  var reuestlink = "/dtr-update-bank";
  return callApi(reuestlink, "POST", data);
};

//Invoices apis

export const getWarehouseDataOfSeller = async (id) => {
  var reuestlink = "/supplier-warehouse-list/" + id;
  return callApi(reuestlink, "GET");
};

export const getCommodityOfWh = async (data) => {
  var reuestlink = "/supplier-commodity-list";
  return callApi(reuestlink, "POST", data);
};

export const getWarehouseDataOfSellerDTR = async (id) => {
  var reuestlink = "/get-all-suppliers";
  return callApi(reuestlink, "GET");
};

export const getInwardData = async (data) => {
  var reuestlink = "/get-inward";
  return callApi(reuestlink, "POST", data);
};

export const saveInvoiceData = async (data) => {
  var reuestlink = "/contract-invoice-save";
  return callApi(reuestlink, "POST", data);
};

export const tpBuyerInvoiceList = async (data) => {
  var reuestlink = "/buyer-invoice-list";
  return callApi(reuestlink, "POST", data);
};

// contract list of LTP
export const ltpBuyerInvoiceContractList = async (data) => {
  var reuestlink = "/ltp/LtpBuyerContracts";
  return callApi(reuestlink, "POST", data);
};

//invoice listing for LTP
export const ltpBuyerInvoiceListingData = async (data) => {
  var reuestlink = "/ltp/LtpInvoiceList";
  return callApi(reuestlink, "POST", data);
};

export const tpBuyerInvoiceDetails = async (id) => {
  var reuestlink = `/buyer-invoice-details?invoice_no=${id}`;
  return callApi(reuestlink, "GET");
};

export const tpSellerInvoiceDetails = async (id) => {
  var reuestlink = `/seller-invoice-details?invoice_id=${id}`;
  return callApi(reuestlink, "GET");
};

export const tpBuyerContractsList = async (
  limit,
  page,
  type,
  service_type,
  statusSelect
) => {
  let reuestlink = `buyer-contract-list-details?&limit=${limit}&page=${page}&contract_type=${type}&service_type=${service_type}&contract_status=${statusSelect}`;
  return callApi(reuestlink, "GET");
};

export const tpWalletDetails = async (id, service_type) => {
  var reuestlink = `/stp-credit-wallet-details?client_id=${id}&service_type=${service_type}`;
  return callApi(reuestlink, "GET");
};

export const tpBuyerDueInvoice = async (id) => {
  var reuestlink = `/buyer-due-invoice?client_id=${id}`;
  return callApi(reuestlink, "GET");
};

export const tpWalletPay = async (data) => {
  var reuestlink = "/stp-pay-credit-wallet";
  return callApi(reuestlink, "POST", data);
};

export const saveMandiReceiptData = async (data) => {
  var reuestlink = "/save-mandi-receipt";
  return callApi(reuestlink, "POST", data);
};

export const validateAndSubmtInvoice = async (id) => {
  var reuestlink =
    "/validate-mandi-receipt?invoice_id=" + id + "&payment_req_id=" + id;
  return callApi(reuestlink, "GET");
};

export const buyerInvoiceEditListing = async (id) => {
  var reuestlink = "/buyer-edit-invoice?payment_req_id=" + id;
  return callApi(reuestlink, "GET");
};

export const stpInvoiceListingData = async (data) => {
  var reuestlink = "/stp-invoice-listing";
  return callApi(reuestlink, "POST", data);
};

export const stpInvoiceDetailsPage = async (id) => {
  var reuestlink = "/stp-invoice-datails?payment_req_id=" + id;
  return callApi(reuestlink, "GET");
};

export const stpInvoiceDeduction = async (id) => {
  var reuestlink = "/get-quality-deduction?invoice_id=" + id;
  return callApi(reuestlink, "GET");
};
export const updateCreditWalletStatus = async (data) => {
  var reuestlink = "/stp-update-status-credit-wallet";
  return callApi(reuestlink, "POST", data);
};

export const failCreditWalletStatus = async (data) => {
  var reuestlink = "/stp-update-fail-credit-wallet";
  return callApi(reuestlink, "POST", data);
};

export const tpSellerInvoiceList = async (data) => {
  var reuestlink = "/seller-invoice-list";
  return callApi(reuestlink, "POST", data);
};

export const tpGetQualityDeduction = async (id) => {
  var reuestlink = `/get-quality-deduction?invoice_id=${id}`;
  return callApi(reuestlink, "GET");
};

export const getFacilitator = async (id) => {
  var reuestlink = "/get-contract-facilitators";
  return callApi(reuestlink, "GET");
};

export const tpSellerBuyerContract = async (type) => {
  var reuestlink = `/seller-buyer-contract-lists?contract_type=${type}`;
  return callApi(reuestlink, "GET");
};

export const tpSTPMarginPay = async (data) => {
  var reuestlink = "/pay-stp-margin-payment";
  return callApi(reuestlink, "POST", data);
};

export const updateSTPMarginStatus = async (data) => {
  var reuestlink = "/update-pay-stp-payment-margin";
  return callApi(reuestlink, "POST", data);
};

export const getWarehouseDataOfSupplierDTR = async (data) => {
  console.log("data", data);
  var reuestlink = "/get-supplier";
  return callApi(reuestlink, "POST", data);
};

export const getvarietyrate = async (data) => {
  var reuestlink = "/get-variety-rate";
  return callApi(reuestlink, "POST", data);
};

export const dtrInvoiceListingData = async (data) => {
  var reuestlink = "/get-dtr-invoice-listing";
  return callApi(reuestlink, "POST", data);
};
export const storageContractType = async () => {
  var reuestlink = "/storage-contract-type";
  return callApi(reuestlink, "GET");
};
export const commodityVarietyLists = async (data) => {
  var reuestlink = "/commodity-variety-lists";
  return callApi(reuestlink, "POST", data);
};
export const storageContract = async (data) => {
  var reuestlink = "/storage-contract";
  return callApi(reuestlink, "POST", data);
};

export const getLeads = async (data) => {
  var reuestlink = "/get-dtr-lead-details";
  return callApi(reuestlink, "POST", data);
};

export const getsprate = async (data) => {
  var reuestlink = "/getsprate";
  return callApi(reuestlink, "POST", data);
};
export const validateQtyAmount = async (data) => {
  var reuestlink = "/validate-qty-amount";
  return callApi(reuestlink, "POST", data);
};

//dashboard requests
export const getContracts = async (data) => {
  var reuestlink = "/dashboard/contracts";
  return callApi(reuestlink, "POST", data);
};

export const getPayment = async (data) => {
  var reuestlink = "/dashboard/payment";
  return callApi(reuestlink, "POST", data);
};

export const getAvgInvoices = async (data) => {
  var reuestlink = "/dashboard/avginvoices";
  return callApi(reuestlink, "POST", data);
};

export const getTrends = async (data) => {
  var reuestlink = "/dashboard/trends/supplier";
  return callApi(reuestlink, "POST", data);
};

export const getInvoices = async (data) => {
  var reuestlink = "/dashboard/invoices";
  return callApi(reuestlink, "POST", data);
};

export const getQuantities = async (data) => {
  var reuestlink = "/dashboard/quantities/buyer";
  return callApi(reuestlink, "POST", data);
};

export const getQuantitiesByCommodity = async (data) => {
  var reuestlink = "/dashboard/quantitiesbycommodity";
  return callApi(reuestlink, "POST", data);
};

//api call for release contracts ltp
export const getCalculateLtpCost = async (data) => {
  var requestLink = "/calculate-ltp-cost";
  return callApi(requestLink, "POST", data);
};
export const payLtpStock = async (data) => {
  var requestLink = "/pay-ltp-stock";
  return callApi(requestLink, "POST", data);
};

//bd-spot-pricing
export const getSupplierListSpotPricing = async (param) => {
  if (param) {
    var requestLink = `/spotpricing/getSupplierList?type=${param}`;
  } else {
    var requestLink = `/spotpricing/getSupplierList`;
  }
  return callApi(requestLink, "GET");
};

export const getWarehouseList = async (data) => {
  var requestLink = "/spotpricing/getWarehouseList";
  return callApi(requestLink, "POST", data);
};

export const getCommodityList = async (data) => {
  var requestLink = "/spotpricing/getCommodityList";
  return callApi(requestLink, "POST", data);
};

export const getBuyerClient = async (data) => {
  var requestLink = "/spotpricing/getBuyerClient";
  return callApi(requestLink, "POST", data);
};

export const getSupplierContractList = async (data) => {
  var requestLink = "/spotpricing/getSupplierContractList";
  return callApi(requestLink, "POST", data);
};

export const getContractDetails = async (data) => {
  var requestLink = "/spotpricing/getContractDetails";
  return callApi(requestLink, "POST", data);
};

export const saveBuyerSpotPriceDetails = async (data) => {
  var requestLink = "/spotpricing/saveBuyerSpotPriceDetails";
  return callApi(requestLink, "POST", data);
};

//past quotation list bd
export const getBuyerPriceRequestList = async (data) => {
  var requestLink = "/spotpricing/getBuyerPriceRequestList";
  return callApi(requestLink, "POST", data);
};

export const getSpotDetails = async (data) => {
  var reuestlink = `/get-spot-listing/${data}`;
  return callApi(reuestlink, "GET");
};

export const updateSpotpricing = async (data) => {
  var reuestlink = "update-spot-pricing-status";
  return callApi(reuestlink, "POST", data);
};
export const getTradingRateHistory = async (data) => {
  var reuestlink = "get-trading-rate-history";
  return callApi(reuestlink, "POST", data);
};

export const getInvoiceFilters = async (id, type) => {
  var reuestlink = `/get-invoice-filters/${id}?type=${type}`;
  return callApi(reuestlink, "GET");
};

export const getBuyerContractsSpotpricing = async (data) => {
  var reuestlink = "/spotpricing/getBuyerContracts";
  return callApi(reuestlink, "POST", data);
};

export const updateQuotationRequest = async (data) => {
  var reuestlink = "/spotpricing/updateQuotationRequest";
  return callApi(reuestlink, "POST", data);
};

export const updateQuotationStatus = async (data) => {
  var reuestlink = "/spotpricing/updateQuotationStatus";
  return callApi(reuestlink, "POST", data);
};

export const getQualityDeductionDetails = async (id) => {
  var reuestlink = "/getQualityDeductionDetails/" + id;
  return callApi(reuestlink, "GET");
};
export const saveQualityParameter = async (data) => {
  var reuestlink = "/saveQualityParameter";
  return callApi(reuestlink, "POST", data);
};
