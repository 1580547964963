import {
  settingIcon,
  detailIcon,
  langIcon2,
  userIcon,
  walletIcon,
  loginIcon,
  notifIcon,
} from "../icon_assets";

export const userMenuDetails = [
  { title: "General Details", icon: detailIcon },
  { title: "Manage Users", icon: userIcon },
  { title: "Commodity & Location Preference", icon: settingIcon },
  { title: "Language Preference", icon: langIcon2 },
  { title: "Manage Notifications", icon: notifIcon },
  { title: "Manage Payment Methods", icon: walletIcon },
  { title: "Location Preference", icon: loginIcon },
];

export const textDetails = [
  "1st Option",
  "2nd Option",
  "3rd Option",
  "4th Option",
  "5th Option",
  "6th Option",
  "7th Option",
];

export const userDetails = [
  {
    name: "Akshita Garg",
    phoneNum: 9317876887,
    role: "Accounts",
    permissions: "Add User, Delete User, Payments",
  },
  {
    name: "Prince Mehta",
    phoneNum: 8637786987,
    role: "Admin",
    permissions: "Add User, Payments",
  },
  {
    name: "Vaibhav Kotiyal",
    phoneNum: 9765676754,
    role: "Sub Admin",
    permissions: "Delete User, Payments",
  },
  {
    name: "Shourya Goel",
    phoneNum: 9317876887,
    role: "Admin",
    permissions: "Add User, Payments",
  },
];

export const headerName = ["Name", "Mobile Number", "Role", "Permissions", ""];

export const roleOptions = [
  {
    value: "Admin",
    label: "Admin",
    permissions: "Add User, Delete User, Check Payments",
  },
  {
    value: "Accounts",
    label: "Accounts",
    permissions: "Check Payments",
  },
  {
    value: "Sub Admin",
    label: "Sub Admin",
    permissions: "Add User, Delete User",
  },
  {
    value: "Super Admin",
    label: "Super Admin",
    permissions: "Add User, Delete User, Check Payments, Add Admin",
  },
];

export const manageUserOptions = [
  { name: "Edit", href: "" },
  { name: "Delete", href: "" },
];

export const addUserDetails = () => {
  return [
    {
      field_name: "fName",
      label: "Full Name",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "phoneNum",
      label: "Phone Number",
      type: "number",
      min: 10,
      max: 10,
      isRequired: true,
    },
    {
      field_name: "role",
      label: "Role",
      isRequired: true,
    },
  ];
};

export const notificationType = [
  { name: "All Channels", id: "option1" },
  { name: "In app notifications", id: "option2" },
  { name: "Push Notifications", id: "option3" },
  { name: "Email", id: "option4" },
  { name: "SMS", id: "option5" },
  { name: "WhatsApp", id: "option6" },
];

export const notificationFor = [
  { name: "Receive notification for all services", id: "option7" },
  { name: "Storage updates", id: "option8" },
  { name: "Trade updates", id: "option9" },
  { name: "Finance updates", id: "option10" },
  { name: "Arya's exciting updates", id: "option11" },
  { name: "Marketing related notifications", id: "option12" },
];
