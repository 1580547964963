import React from "react";
import Card from "../CommonComponents/Card";
import HeadLines from "../CommonComponents/HeadLines";
import { Info } from "./Constant";
import style from "./style.module.css";
import Certified from "./../Images/CommonImages/Certified.png";
import { isMobile } from "../helperFunc";

const InfoRegardingKyc = (props) => {
  return (
    <Card
      className={`${style["card-gradient"]} margin16  colorAccent2 boxRadius4 ${
        isMobile
          ? "padding16"
          : "padding20 font-size20 line-height23 letterSpacing001"
      }`}
    >
      <HeadLines
        text="Why verify yourself?"
        className={`font-weight600 ${isMobile ? "" : style["padding-left20"]}`}
      />
      {Info[props.type].map((item) => {
        return (
          <div
            className={` d-flex align-items-center ${
              isMobile
                ? "padding-top12"
                : `${style["padding-left20"]} padding-top16`
            }`}
          >
            <img src={Certified} className={style["iconHeightWidth16"]} />
            <span className={`display-inline-block `}>
              <HeadLines
                text={item}
                className={` font-weight500 ${
                  isMobile
                    ? "line-height16 font-size14 padding-left8"
                    : "padding-left12"
                } letterSpacing001 `}
              />
            </span>
          </div>
        );
      })}
    </Card>
  );
};
export default InfoRegardingKyc;
