import React, { useState } from "react";
import AddUserModal from "../AddUserModal";
import { userDetails as data } from "../constant";
import Button from "../../CommonComponents/Button";
import CardComponent from "../CardComponent";
import Header from "../../CommonComponents/Header";
import HeaderBackground from "../../CommonComponents/HeaderBackground";
import { useHistory } from "react-router";
import { backArrow } from "../../icon_assets";
import { isMobile } from "../../helperFunc";
import s from "./style.module.css";
import StickyFooter from "../../StickyFooter/StickyFooter";
const AccountTableCard = () => {
  const history = useHistory();

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  return (
    <>
      {isMobile && (
        <HeaderBackground
          className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 ${s["header-style"]}`}
        >
          <Header
            title="Manage Users"
            backArrow={backArrow}
            iconClassName={`${s["back-arrow"]} flex-center`}
            onSecondaryIconClick={() => null}
            onBackArrowClick={() => history.goBack()}
            titleClassName="font-weight500 font-size16 colorDark5 line-height19 letterSpacing015"
            className="padding-bottom16 padding-top20"
          />
        </HeaderBackground>
      )}
      <div className="padding16 stdFontStyle gap16 width-100">
        <div className="d-flex justify-content-space-between font-weight500 font-size16">
          <div>{data.length} users found</div>
          <div className="">
            <Button
              className={`colorAccent3 backgroundWhite`}
              onClick={() => {
                setShowAddUserModal(true);
              }}
            >
              + Add User
            </Button>
          </div>
        </div>
        <hr />
        <div>
          {showAddUserModal && (
            <AddUserModal
              showModal={showAddUserModal}
              onHide={() => {
                setShowAddUserModal(false);
              }}
            />
          )}

          {data.length > 0 ? (
            data.map((item, index) => (
              <CardComponent key={index} item={item} index={index} />
            ))
          ) : (
            <div>No data available</div>
          )}
        </div>
      </div>
      {isMobile && <StickyFooter isActive="Profile" workEvenIfActive />}
    </>
  );
};

export default AccountTableCard;
