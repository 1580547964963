import React from "react";

const BlurModalContent = (props) => {
  const { className, children, parentDivClassName , mh60vh="mh60vh"} = props;
  return (
    <div className={mh60vh
      +` scroll-vertical ${parentDivClassName}`}>
      <div className={className}>{children}</div>
    </div>
  );
};

export default BlurModalContent;
