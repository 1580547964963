export const REQ_MODE = {
  DEMAND: 1,
  SUPPLY: 2,
};

export const WAREHOUSE_TYPE = {
  ARYA: 1,
  OUTSIDE: 2,
  NONE: -1,
};
let _ = [
  {
    warehouse_id: "3760",
    warehouse_name: "Abhishek Warehouse",
    warehouse_code: "5236",
    aryapro_id: "4629",
    commodity_name: "Bajra",
    commodity_id: "4",
    MT: "8.000",
    Bags: "160",
    entry_date: "2021-05-08",
    moisture: "12",
    live_insect: "0",
    khapra: "0",
    contract_id: "11643",
    rate_per_month: 0,
    is_farm_vertical: 0,
    collectionBRate: "2000.00",
    collectionARate: "2000.00",
    total_rent: 12600,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 12600,
        download_link: "NA",
      },
    ],
    pincode: "201304",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=VnAxUEptcnpSbzhDeFRHSnEwbGdITVdYWmszenhtYlVNazRCbkpPSE5YSElXODZnZkprdjRZVGl4ejMxMmpSUzY1WFFoVTdPTC9GU0k0emJQVTJWdkE9PQ==",
    expiry_time: "2022-12-27 19:48:13",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Alasandi",
    commodity_id: "1",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11871",
    rate_per_month: "100.00",
    is_farm_vertical: 0,
    collectionBRate: "",
    collectionARate: "",
    total_rent: 20903,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20903,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 17:43:40",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "CASHEW",
    commodity_id: "10",
    MT: "64.000",
    Bags: "2560",
    entry_date: "2021-09-23",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11584",
    rate_per_month: 0,
    is_farm_vertical: 0,
    collectionBRate: "1500.00",
    collectionARate: "1500.00",
    total_rent: 160000,
    status: "1",
    funded_bag: "580",
    funded_stock: "25.000",
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 160000,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Yellow Mustard",
    commodity_id: "101",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11872",
    rate_per_month: "110.00",
    is_farm_vertical: 0,
    collectionBRate: "0.00",
    collectionARate: "0.00",
    total_rent: 20067,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20067,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "White Chick Peas",
    commodity_id: "103",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11872",
    rate_per_month: "90.00",
    is_farm_vertical: 0,
    collectionBRate: "0.00",
    collectionARate: "0.00",
    total_rent: 20067,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20067,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Alsi",
    commodity_id: "114",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11871",
    rate_per_month: "100.00",
    is_farm_vertical: 0,
    collectionBRate: "",
    collectionARate: "",
    total_rent: 20903,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20903,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Ajwain",
    commodity_id: "122",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "1",
    live_insect: "1",
    khapra: "0",
    contract_id: "11871",
    rate_per_month: "100.00",
    is_farm_vertical: 0,
    collectionBRate: "",
    collectionARate: "",
    total_rent: 20903,
    status: "1",
    funded_bag: "40",
    funded_stock: "2.000",
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20903,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Arrowroot",
    commodity_id: "126",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-21",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11900",
    rate_per_month: "90.00",
    is_farm_vertical: 0,
    collectionBRate: "0.00",
    collectionARate: "0.00",
    total_rent: 15050.32,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 15050.32,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Ashwagandha Powder",
    commodity_id: "129",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-21",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11900",
    rate_per_month: "70.00",
    is_farm_vertical: 0,
    collectionBRate: "0.00",
    collectionARate: "0.00",
    total_rent: 0,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Yellow Batri",
    commodity_id: "149",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11872",
    rate_per_month: "100.00",
    is_farm_vertical: 0,
    collectionBRate: "0.00",
    collectionARate: "0.00",
    total_rent: 20067,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20067,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Ashwagandha",
    commodity_id: "158",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-21",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11900",
    rate_per_month: "80.00",
    is_farm_vertical: 0,
    collectionBRate: "0.00",
    collectionARate: "0.00",
    total_rent: 0,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Arecanut",
    commodity_id: "3",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11871",
    rate_per_month: "100.00",
    is_farm_vertical: 0,
    collectionBRate: "",
    collectionARate: "",
    total_rent: 20903,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20903,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Bajra",
    commodity_id: "4",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "1",
    live_insect: "0",
    khapra: "0",
    contract_id: "11900",
    rate_per_month: "100.00",
    is_farm_vertical: 0,
    collectionBRate: "0.00",
    collectionARate: "0.00",
    total_rent: 0,
    status: "1",
    funded_bag: "40",
    funded_stock: "2.000",
    summary: [],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "MAIZE",
    commodity_id: "53",
    MT: "93.000",
    Bags: "1960",
    entry_date: "2022-03-17",
    moisture: "1",
    live_insect: "10",
    khapra: "0",
    contract_id: "11577",
    rate_per_month: "100.00",
    is_farm_vertical: 0,
    collectionBRate: "",
    collectionARate: "",
    total_rent: 87,
    status: "1",
    funded_bag: "212",
    funded_stock: "8.480",
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 87,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "MAIZE",
    commodity_id: "53",
    MT: "93.000",
    Bags: "1960",
    entry_date: "2022-03-17",
    moisture: "1",
    live_insect: "10",
    khapra: "0",
    contract_id: "11907",
    rate_per_month: "10.00",
    is_farm_vertical: 0,
    collectionBRate: "",
    collectionARate: "",
    total_rent: 87,
    status: "1",
    funded_bag: "212",
    funded_stock: "8.480",
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 87,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "YELLOW PEAS",
    commodity_id: "97",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11872",
    rate_per_month: "120.00",
    is_farm_vertical: 0,
    collectionBRate: "0.00",
    collectionARate: "0.00",
    total_rent: 20067,
    status: "1",
    funded_bag: "20",
    funded_stock: "1.000",
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20067,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
  {
    warehouse_id: "3887",
    warehouse_name: "Ankur warehousing services",
    warehouse_code: "9001",
    aryapro_id: "4629",
    commodity_name: "Amarnath Seed",
    commodity_id: "99",
    MT: "48.000",
    Bags: "960",
    entry_date: "2022-04-20",
    moisture: "0",
    live_insect: "0",
    khapra: "0",
    contract_id: "11871",
    rate_per_month: "100.00",
    is_farm_vertical: 0,
    collectionBRate: "",
    collectionARate: "",
    total_rent: 20903,
    status: "1",
    funded_bag: 0,
    funded_stock: 0,
    summary: [
      {
        start_date: "2022-12-01",
        end_date: "2022-12-27",
        invoice_no: "NA",
        amount: 20903,
        download_link: "NA",
      },
    ],
    pincode: "201301",
    district: "696",
    state: "2",
    district_name: "Noida",
    state_name: "UTTAR PRADESH",
    is_live: 1,
    camera_url:
      "https://guardtour.iviscloud.net/arya/live.jsp?q=Q1Z6citabDZUY3FmMFJkM3VvbmlmWlhCcGMwSTRXQ3FST2JrYzA3ZUg3QklDWDE3aFN2eVJCa0ZPS2lacVZHbWJKNVdsUnhCRDlnd0hpamptSWV3MGc9PQ==",
    expiry_time: "2022-12-27 20:03:49",
    marque: "",
    contact_name: "ANKUR TRIVEDI",
  },
];
export const getAryaWarehouseCommodities = async () => {
  let __ = _.reduce((prev, cur) => {
    let { commodity_name, commodity_id } = cur;
    if (prev.find((el) => el.value == commodity_id)) return prev;
    else return [...prev, { label: commodity_name, value: commodity_id }];
  }, []);
  return __;
};

export const getWarehouses = async (commodity) => {
  return _.filter((el) => el.commodity_id == commodity?.value).map(
    ({
      district,
      state,
      district_name,
      state_name,
      warehouse_id,
      warehouse_name,
      warehouse_code,
    }) => ({
      district,
      state,
      district_name,
      state_name,
      value: warehouse_id,
      label: warehouse_name,
      warehouse_code,
    })
  );
};

export const COTTON_BALES = "COTTON BALES";
