import React from "react";
import { useHistory } from "react-router";
import BlurModal from "../../../CommonComponents/BlurModal/BlurModal";
import BlurModalContent from "../../../CommonComponents/BlurModal/BlurModalContent";
import BlurModalHeading from "../../../CommonComponents/BlurModal/BlurModalHeading";
import Button from "../../../CommonComponents/Button";
import HeadLines from "../../../CommonComponents/HeadLines";
import style from "./style.module.css";

const RejectedPopUp = ({ show, setShow }) => {
  const history = useHistory();
  return (
    <>
      <BlurModal
        className={"blur-modal"}
        show={show}
        onHide={() => setShow(false)}
      >
        <>
          <BlurModalHeading
            className={`
                    padding-top24 
                    padding-bottom24
                    margin-top8
                    textCenter
                    `}
            heading={<></>}
            showHeadingSepertor={false}
          />
          <BlurModalContent className="text-center">
            <div
              className={` ${style["rejectPopUpCSS"]} textCenter margin-left16 margin-right16 `}
            >
              <HeadLines
                className={`font-weight700 
                        `}
                text={`OOPS!`}
              />
              <HeadLines
                className={`font-weight400 
                        font-size14
                        line-height16
                        letterSpacing05
                        padding-top16 padding-bottom16
                        `}
                text={`Arya's WRF offer expired on 31th Mar 2023`}
              />
            </div>
            <div className="margin16">
              <Button
                className={`font-weight500 font-size14 line-height16 letterSpacing0125 backAccent4 padding-top12 padding-bottom12 boxRadius6 textColorWhite width-100  `}
                title="Get Quote"
                onClick={() => history.push("/get-loan-disbursement")}
              />
            </div>
          </BlurModalContent>
        </>
      </BlurModal>
    </>
  );
};
export default RejectedPopUp;
