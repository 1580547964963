import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'

let userDashBoardData = JSON.parse(localStorage.getItem("userDashBoardData"))
userDashBoardData = userDashBoardData && userDashBoardData.member && userDashBoardData.member.arya_id ? userDashBoardData.member.arya_id : ""

export const AryaPro = {
    getRmName,
    borrowerStocks,
    clientwisestock,
    getCHR,
    getInvoice,
    getCertificate,
    aryaProStockReport,
    addLoanRequest,
    myLoanRequest,
    loanAmoutCalculator,
    getWarehouseDetails,
    aryaProRent,
    comodityIdByName,
    vewLoanStatements,
    releaseOrder,
    aryaProCurrentLoan,
    aryaProVerifyRent,
    aryaProVerifyRentNew,
    payHistory,
    payDetails,
    liveStream,
    buyerSellerListing
}

// async function getRmName() {
//     let formData = new FormData()
//     formData.append("token", "eaa6e653e7f0d23bd2ad460e5cf95aa2")
//     return fetch('https://dtr.aryacma.co.in/api/a2z/areamanagers', {
//         method: 'POST',
//         mode: 'no-cors',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: formData
//     })
// }

// async function getRmName() {
//     return fetch('https://dtr.aryacma.co.in/api/a2z/areamanagers', {
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             token: "eaa6e653e7f0d23bd2ad460e5cf95aa2",
//         })
//     })
// }




// async function getRmName() {
//     let formData = new FormData()
//     formData.append("token", "eaa6e653e7f0d23bd2ad460e5cf95aa2")
//     return fetch('https://dtr.aryacma.co.in/api/a2z/areamanagerspost', {
//         // mode: '*', // no-cors, *cors, same-origin
//        // crossDomain:true,
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: formData
//     })
// }


// async function getRmName() {
//     return fetch('https://dtr.aryacma.co.in/api/a2z/areamanagers', {
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             token: "eaa6e653e7f0d23bd2ad460e5cf95aa2",
//         })
//     })
// }


async function getCHR(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-chr', data);
        return response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getInvoice(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-invoice', data);
        // const api_response = FilterResponse(response);
        return response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getCertificate(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-certificate', data);
        // const api_response = FilterResponse(response);
        return response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getRmName() {
    try {
        const response = await RestMethod.GET('/arya-pro-area-manager');
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function clientwisestock(params,homeWidget=false) {
    try {
        const response = await RestMethod.GET(`/arya-pro-stock${params ? params : ''}`);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
       if(!homeWidget) toastr.error("Stock loading failed. Please try later");
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function borrowerStocks(params) {
    try {
        const response = await RestMethod.GET(`/arya-pro-borrower-stock${params ? params : ''}`);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error("Failed to load release stock. Please try later")
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function aryaProStockReport(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-stock-report-data', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error("Failed to load stock report. Please try later");
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function addLoanRequest(data) {
    try {
        const response = await RestMethod.POST('/add-loan-request', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error("Failed to add loan details. Please try later");
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function myLoanRequest() {
    try {
        const response = await RestMethod.GET('/my-loan-request');
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function loanAmoutCalculator(data) {
    try {
        const response = await RestMethod.POST('/loan-amount-calculator', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error("Failed to calculate loan details. Please try later");
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getWarehouseDetails(code) {
    try {
        const response = await RestMethod.GET('/warehouse-code/' + code);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function aryaProRent(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-rent', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.warning("Failed to load rent details. Please try later");
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function comodityIdByName(id) {
    try {
        const response = await RestMethod.GET('/arya-commodity/' + id);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function vewLoanStatements(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-sr-details', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function releaseOrder(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-current-rent', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}



async function aryaProCurrentLoan(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-current-loan', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function aryaProVerifyRent(data) {
    try {
        const response = await RestMethod.POST('/arya-pro-verify-rent', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error("Failed to load rent. Please try later")
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function aryaProVerifyRentNew(data) {

    try {
        const response = await RestMethod.POST('/arya-pro-verify-rent', data);
        return response;
    }
    catch (error) {
        toastr.error("Failed to load rent. Please try later")
        console.log("error detected while fetching data from api");
        return null;
    }




}

async function payHistory(id) {
    try {
        const response = await RestMethod.GET('/pay-history/' + id);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function payDetails(id) {
    try {
        const response = await RestMethod.GET('/pay-detail/' + id);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function liveStream(data) {
    try {
        const response = await RestMethod.POST('warehouse-live-camera', data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error("Failed to load warehouse camera. Please try later")
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function buyerSellerListing(type,service_type) {
    try {
        let tempType;
        if(service_type == 'ltp'){
             tempType = '?ltp=1'
        }
        if(service_type == 'stp'){
             tempType = '?stp=1'
        }
        const response = await RestMethod.GET('/buyer-seller-list/' + type + tempType);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

//http://164.52.209.160:9002/public/api/arya-commodity/37