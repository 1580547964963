import { useSelector } from "react-redux";

const useUserDetails = (props) => {
  const userDetails = useSelector(
    (globalState) => globalState?.userInfoReducer
  );
  return Object.keys(userDetails).length === 0 ? {} : userDetails;
};

export default useUserDetails;
