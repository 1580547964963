import React, { useEffect } from "react";
import HeadLines from "../CommonComponents/HeadLines";
import { isMobile } from "../helperFunc";
import { CardsData, skipArray } from "./Constant";
import SkipSubmitButton from "./SkipSubmitButton";
import style from "./style.module.css";
const SelectRole = (props) => {
  const submitClick = () => {
    props.setCompletedStep(2);
    props.setCurrentStep(2);
  };
  useEffect(() => {
    props.ScrollToTop();
  }, [])
  return (
    <div>
      <HeadLines
        text="Please select your user type"
        className={` margin-left16   padding-left4 padding-right4 ${isMobile
            ? "margin-top16 font-weight400 font-size12 line-height14 letterSpacing025"
            : `margin-top24 font-weight500 colorDark5 font-size20 line-height23 letterSpacing001`
          }`}
      />
      <div
        className={`d-flex flex-wrap margin16 ${isMobile ? "padding4" : ""}`}
      >
        {CardsData.map((item, index) => {
          return (
            <>
              <div
                onClick={() => {
                  props.setData((prev) => ({
                    ...prev,
                    ["userType"]: item.label,
                  }));
                }}
                className={`cursorPointer boxRadius6 ${props.userType == item.label
                    ? style["selectedBorder"]
                    : "light2Border"
                  }  ${isMobile
                    ? `${style["flex45"]} ${style["margin-bottom14"]}`
                    : `${style["flex22"]} margin-bottom16 margin-top8`
                  } ${index % 2 ? style["margin-left20"] : ""}
                ${!isMobile && (index == 2 || index == 6)
                    ? style["margin-left20"]
                    : ""
                  } 
                `}
              >
                <div
                  className={`d-flex textCenter  ${style[item.background]} ${isMobile ? style["Height_95"] : style["Height_121"]
                    }`}
                >
                  <img
                    src={item.image}
                    className={`m-auto ${style[item.height]} width-100`}
                  />
                </div>
                <HeadLines
                  text={item.label}
                  className={`${props.userType == item.label ? "colorAccent4" : ""
                    } textCenter padding8 font-weight500 ${isMobile
                      ? "font-size12 line-height14"
                      : "font-size16 line-height19"
                    } letterSpacing025`}
                />
              </div>
            </>
          );
        })}
      </div>
      <SkipSubmitButton
        submitClick={submitClick}
        buttonEnable={props.userType ? true : false}
        skipClick={() => {
          props.updateKycNotification();
          props.setShow(false);
        }}
        skipText={skipArray[0]}
        setBlurBackGround={props.setBlurBackGround}
      />
    </div>
  );
};
export default SelectRole;
