import React, { useState } from "react";
import { isMobile } from "../helperFunc";
import { defPic, picIcon, profilePic } from "../icon_assets";
import BlurModal from "./BlurModal/BlurModal";
import BlurModalContent from "./BlurModal/BlurModalContent";
import BlurModalHeading from "./BlurModal/BlurModalHeading";
import EditButton from "./EditButton";
import HeadLines from "./HeadLines";
import ImageUpload from "./ImageUpload/ImageUpload";
import s from "./style.module.css";
const ProfilePic = (props) => {
  const {
    imageSource,
    showEditIcon = true,
    className = `position-relative ${s["width-fit-content"]}`,
    setProfileImage = () => null,
    profilePicClassName = ``,
    editIconClassName = ``,
    editIconWidth = ``,
  } = props;
  const [show, setShow] = useState();
  const handleSelectedProfileImage = (imageFiles) => {
    setProfileImage(imageFiles[0]);
  };
  // const handleUploadedProfileImage = (imageNameArr, imageUrlArr) => {

  //   setProfileImage(imageUrlArr[0]);
  //   //TO DO:
  // };
  return (
    <>
      <div className={className}>
        <div className={`${s["profile-div"]} ${profilePicClassName}`}>
          <img
            className={`${s["profile-img"]}`}
            src={imageSource || defPic}
            width={`100%`}
            alt=""
            height={`100%`}
          />
        </div>
        {showEditIcon && (
          <EditButton
            onEditButtonClick={() => setShow(true)}
            className={`cursor-pointer position-absolute ${s["edit-icon"]} ${editIconClassName}`}
            editIconWidth={editIconWidth}
          />
        )}
      </div>
      <BlurModal
        show={show}
        onHide={() => setShow(false)}
        showDesktopHeader={!isMobile ? true : false}
        desktopHeading={"New Profile Photo"}
      >
        <>
          {isMobile && (
            <BlurModalHeading
              className={`padding-left16
                        padding-bottom16
                        padding-top20`}
              heading={
                <HeadLines
                  className={`font-weight600 
                            font-size18 
                            line-height21`}
                  text={"New Profile Photo"}
                />
              }
            />
          )}
          <BlurModalContent>
            <ImageUpload
              bottomTextClassName="margin-top8 font-size14 line-height16 letter-spacing05"
              bottomText="Please upload a jpeg/png. Maximum file size limit is 2MB."
              maxFileSize={2000000}
              errMsg={
                isMobile
                  ? "Please Upload Valid Image and Max Size Should be 2 MB"
                  : "File format not supported or the file you uploaded exceeds 2MB"
              }
              sendImagesToParent={handleSelectedProfileImage}
              // uploadedImage={handleUploadedProfileImage}
              uploadImageClose={() => setShow(false)}
              id1={"campic"}
              id2={"devicefile"}
              icon1={profilePic}
              icon2={picIcon}
              errorClassNameParent={
                isMobile
                  ? undefined
                  : "d-flex align-items-center gap4 margin-top8"
              }
            />
          </BlurModalContent>
        </>
      </BlurModal>
    </>
  );
};

export default ProfilePic;
