import {
  GET_USER_LANGUAGE,
  SET_USER_LANGUAGE,
  UPDATE_USER_LANGUAGE,
} from "./types";

export const setUserLanguage = (payload) => {
  return { type: SET_USER_LANGUAGE, payload };
};
export const updateUserLanguage = (payload) => {
  return { type: UPDATE_USER_LANGUAGE, payload };
};

export const getLanguageFromStore = (state) => {
  return state.userLanguageReducer;
};
