import React, { useCallback, useEffect, useState } from "react";
import {
  getUserCommodityPref,
  getUserLocPref,
} from "../../../../_services/Arya2.0/Registration";

import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import { debounce } from "../CommonFunctions/Function";
import { isMobile } from "../helperFunc";
import { errorInfoIcon } from "../icon_assets";
import { skipArray } from "./Constant";
import SkipSubmitButton from "./SkipSubmitButton";
import style from "./style.module.css";
import Suggestion from "./Suggestion";

const SelectPref = (props) => {
  const [location, setLocation] = useState("");
  const [commodity, setCommodity] = useState("");
  const [locArray, setLocArray] = useState("");
  const [comArray, setComArray] = useState("");
  const submitClick = () => {
    props.setCompletedStep(3);
    props.setCurrentStep(3);
  };
  useEffect(() => {
    props.ScrollToTop();
  }, [])
  const onChange = (value) => {
    props.setData((prev) => ({ ...prev, ["selectedCount"]: value }));
  };

  const handleChangeLocation = async (value) => {
    if (value == "") {
      setLocArray(props.locDataArray);
    } else {
      const res = await getUserLocPref("?q=" + value);
      if (res?.data?.length) {
        setLocArray(res?.data?.slice(0, 5));
      } else {
        setLocArray("");
      }
    }
  };
  const handleChangeCommodity = async (value) => {
    if (value == "") {
      setComArray(props.commodityDataArray);
    } else {
      const res1 = await getUserCommodityPref("?q=" + value);
      if (res1?.data?.length) {
        setComArray(res1?.data?.slice(0, 5));
      } else {
        setComArray("");
      }
    }
  };

  const optimizedFn = useCallback(debounce(handleChangeLocation), []);
  const optimizedFn2 = useCallback(debounce(handleChangeCommodity), []);

  return (
    <div>
      <InputFieldWithTitle
        titleClassName={` margin-top16 margin-left16 ${isMobile
            ? "font-weight500 font-size14 line-height16 letterSpacing0015"
            : `font-weight500 colorDark5 font-size20 line-height23 letterSpacing001`
          }`}
        title={"Select Commodity Preference"}
        disabled={false}
        inputClassName={`${isMobile ? "backgroundLightBrown margin-top8" : "margin-top12"
          } margin-left16 margin-right16 width-100 boxRadius4 padding12 ${style["inputBorder"]
          } font-weight400 font-size14 line-height16 letterSpacing001`}
        errorInputClassName={"input-style-error"}
        errorClassName={"input-error-style"}
        showError={""}
        errorIcon={errorInfoIcon}
        inputParentClassName="d-flex"
        value={commodity}
        onChange={(e) => {
          optimizedFn2(e.target.value);
          setCommodity(e.target.value);
        }}
      />
      {props.commodityDataArray.length || comArray?.length ? (
        <Suggestion
          dataArray={comArray ? comArray : props.commodityDataArray}
          type={"commodity"}
          data={props.data}
          setData={props.setData}
          onChange={onChange}
          selectedCount={props.data.selectedCount}
        />
      ) : (
        ""
      )}
      <InputFieldWithTitle
        titleClassName={`margin-top16 margin-left16 ${isMobile
            ? "font-weight500 font-size14 line-height16 letterSpacing0015"
            : `font-weight500 colorDark5 font-size20 line-height23 letterSpacing001`
          }`}
        title={"Select Location Preference"}
        disabled={false}
        inputClassName={`${isMobile ? "backgroundLightBrown margin-top8" : "margin-top12"
          } margin-left16 margin-right16 width-100 boxRadius4 padding12 ${style["inputBorder"]
          } font-weight400 font-size14 line-height16 letterSpacing001`}
        errorInputClassName={"input-style-error"}
        errorClassName={"input-error-style"}
        showError={""}
        errorIcon={errorInfoIcon}
        inputParentClassName="d-flex"
        value={location}
        onChange={(e) => {
          optimizedFn(e.target.value);
          setLocation(e.target.value);
        }}
      />
      {props.locDataArray.length || locArray?.length ? (
        <Suggestion
          dataArray={locArray ? locArray : props.locDataArray}
          type={"location"}
          data={props.data}
          setData={props.setData}
          onChange={onChange}
          selectedCount={props.data.selectedCount}
        />
      ) : (
        ""
      )}

      <SkipSubmitButton
        submitClick={submitClick}
        buttonEnable={props.data.selectedCount ? true : false}
        skipClick={() => {
          props.setCompletedStep(3);
          props.setCurrentStep(3);
        }}
        skipText={skipArray[1]}
        setBlurBackGround={props.setBlurBackGround}
      />
    </div>
  );
};
export default SelectPref;
