//Login flow
export const LOGIN_CTA_CLICKED = "Login CTA clicked";
export const LOGIN_POPUP_APPEARING = "Login popup appearing";
export const LOGIN_POPUP_CLOSED = "Login popup closed";
export const VERIFY_NUMBER_CTA_CLICKED = "Verify number CTA clicked";
export const RESEND_OTP_VIA_SMS = "Resend OTP via SMS";
export const RESEND_OTP_VIA_CALL = "Resend OTP via Call";
export const CHANGE_NUMBER = "Change number";
export const LOGIN_AFTER_OTP = "Login after OTP";

//Post Trade Requirement

export const POST_TRADE_REQUIREMENT_CLICKED = "Post trade requirement clicked";
export const POST_REQUIREMENT_FORM_APPEARS = "Post requirement form appears";
export const REQUIREMENT_SUBMIT_CLICKED = "Requirement submit clicked";

// Requirement detail page

export const REQUIREMENT_CARD_CLICKED = "Requirement card clicked";
export const SUBMIT_QUOTE_CLICKED = "Submit quote clicked";
export const CALL_BUYER_SELLER_CLICKED = "Call Buyer/Seller Clicked";
export const SHARE_ICON_CLICKED = "Share icon clicked";
export const QUOTE_FORM_OPEN = "Quote form open";
export const QUOTE_FORM_SUBMITTED = "Quote form submitted";

//Warehouse

export const BOOK_WAREHOUSE_BANNER_CLICKED = "Book warehouse banner clicked";
export const WAREHOUSE_LISTING_SEARCH_CLICKED =
  "Warehouse listing search clicked";
export const LOCATION_SELECTION_MANUAL = "warehouse_location_manual";
export const LOCATION_SELECTION_NEAR_ME = "warehouse_location_nearme";
export const WAREHOUSE_CARD_CLICKED = "Warehouse card clicked";
export const BOOK_NOW_CTA_CLICKED = "Book now CTA clicked";
export const BOOK_NOW_FORM_OPEN = "Book now form open";
export const BOOK_NOW_FORM_SUBMITTED = "Book now form submitted";

//Release commodity
export const RELEASE_STOCK_CLICKED_OLD_NEW = "Release Stock Clicked (old/new)";
export const PROCEED_TO_RELEASE_STOCK_OLD = "Proceed To Release Stock (old)";
export const ENTER_QUANTITY_OLD_NEW = "Enter Quantity (old/new)";
export const PAY_NOW_CLICKED_OLD_NEW = "Pay Now Clicked (old/new)";
export const SELECT_CONTRACTS_NEW = "Select Contracts (new)";
export const PAYMENT_METHOD_SELECTION = "Payment method selection";
export const PAYMENT_SUCCESSFUL = "Payment method successful/unsuccessful";
//Products Certified/Noncertified

export const PRODUCT_CLICKED = "Product clicked";
export const CONTACT_SELLER_CLICKED = "Product contact seller";
export const ENQUIRY_FORM_OPENED = "Enquiry form opened";
export const ENQUIRY_FORM_SUBMITTED = "Enquiry form submitted";
