import React, { Suspense, useEffect, useRef, useState } from "react";
import { notification } from "../../icon_assets";
import { HomePageServices } from "../../../../../_services/Arya2.0/HomePageServices";
import useIsUserLoggedIn from "../../CustomHooks/useIsUserLoggedIn";
import { useClickOutside } from "../../CustomHooks/useClickOutside";
import NotificationPopUp from "./NotificationPopUp";
import { useHistory } from "react-router";
import HeadLines from "../../CommonComponents/HeadLines";
import style from "./style.module.css";
import { eventTracker } from "../../GATracker3.0/eventTracker";
import {
  NOTIFICATION_CLICKED,
  addInfoNotificationValue,
} from "../../GATracker3.0/constants";
import useUserDetails from "../../CustomHooks/useUserDetails";
import EmailRegisterPopUp from "../../Registration/EmailRegisterPopUp";
import EmailLinkSentPopUp from "../../Registration/EmailLinkSentPopUp";
const Registration = React.lazy(() =>
  import("../../Registration/Registration")
);

const NotificationDesktop = () => {
  const ref = useRef();
  const flag = useClickOutside(ref);
  const userData = useUserDetails();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [userLoggedIn] = useIsUserLoggedIn();
  const [regShow, setRegShow] = useState(false);
  const [showEmailVerifyPopUp, setShowEmailVerifyPopUp] = useState(false);
  const [showEmailSentPopUp, setShowEmailSentPopUp] = useState(false);
  const [email, setEmail] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (flag) {
      setShow(false);
    }
  }, [flag]);
  const modifyData = (dataArray) => {
    let newArray = [];

    dataArray.forEach((obj) => {
      let newObj = {
        title: obj.title,
        body: obj.body,
        finance:
          obj.type === "LOAN_DISBURSEMENT" || obj.type === "RELEASE_STOCK"
            ? true
            : false,
        onClick: () => {
          getNotificationReadData(obj);
          eventTracker("event", NOTIFICATION_CLICKED, {
            pagename: "notification",
            addinfo: addInfoNotificationValue[obj.type],
          });
          redirectUrl(obj);
        },
      };
      newArray.push(newObj);
    });
    setData(newArray);
  };
  const redirectUrl = (dataObj) => {
    if (dataObj.type === "LOAN_DISBURSEMENT") {
      history.push("/disbursement/" + dataObj?.reference_data?.sr_id);
    } else if (dataObj.type === "RELEASE_STOCK") {
      history.push(
        "/arya2/release-stock/" +
        dataObj?.reference_data?.warehouse_code +
        "/" +
        dataObj?.reference_data?.commodity_id +
        "/" +
        dataObj?.reference_data?.contract_id
      );
    } else if (dataObj.type === "LEAD_QUOTATION") {
      history.push("/buyer-requirements/" + dataObj?.reference_data?.unique_id);
    } else if (dataObj.type === "SPOT_CONTRACT_CREATION") {
      history.push("/view-contract/" + dataObj?.reference_data?.contract_id);
    } else if (dataObj.type === "COMMODITY_INWARD") {
      history.push("/token-details/" + dataObj?.reference_data?.token_hash);
    } else if (dataObj.type === "EMAIL_VERIFICATION") {
      handleEmailVerifyPopUp();
    } else {
      setRegShow(true);
    }
  };
  const handleEmailVerifyPopUp = () => {
    const data = userData;
    setShowEmailVerifyPopUp(true);
    setEmail(data.email)
  }
  const getNotificationReadData = async (data) => {
    let obj = { id: data.id };
    await HomePageServices.getUserReadNotification(obj);
  };

  const getNotificationData = async () => {
    const res = await HomePageServices.getUserNotification();
    // console.log(res);
    if (res?.data?.length) {
      modifyData(res.data);
    }
  };

  useEffect(() => {
    if (userLoggedIn) getNotificationData();
    else {
      setData([]);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (show && ref?.current) ref.current.click();
  }, [show]);

  return (
    <>
      {regShow && (
        <Suspense fallback={<></>}>
          <Registration show={regShow} setShow={setRegShow} />
        </Suspense>
      )}
      {showEmailVerifyPopUp && <EmailRegisterPopUp setShowEmailVerifyPopUp={setShowEmailVerifyPopUp} showEmailVerifyPopUp={showEmailVerifyPopUp} setEmail={setEmail} email={email} setShowEmailSentPopUp={setShowEmailSentPopUp} showVerifyEmailPopUSent={true} />}
      {showEmailSentPopUp && <EmailLinkSentPopUp
        setShowEmailSentPopUp={setShowEmailSentPopUp}
        showEmailSentPopUp={showEmailSentPopUp}
        email={email}
      />}
      {data?.length > 0 ? (
        <>
          {show && (
            <div className={style["notification-style"]} ref={ref}>
              <NotificationPopUp
                show={show}
                setShow={setShow}
                //   setSorting_by={props.setSorting_by}
                //   sorting_by={props.sorting_by}
                data={data}
              />
            </div>
          )}
          <div
            onClick={() => setShow(true)}
            className="positionRelative cursorPointer"
          >
            <HeadLines
              className={`positionAbsolute  ${style["notificationCSS"]} ${data?.length < 10
                ? style["width12"]
                : data?.length > 99
                  ? style["width21"]
                  : style["width16"]
                } font-size12 line-height14 letterSpacing025`}
              text={data?.length > 99 ? "99+" : data.length}
            />
            <img
              src={notification}
              alt=""
              // className={` margin-left16`}
              height={"32"}
              width={"32"}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {/* {sho
        w && (
        <OverThePageComponent show={show} setShow={setShow}>
          <Notifications setShow={setShow} notifications={notifications} />
        </OverThePageComponent>
      )} */}
    </>
  );
};
export default NotificationDesktop;
