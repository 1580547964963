import React from "react";
import HeadLines from "../../../CommonComponents/HeadLines";
import InputText from "../../../CommonComponents/InputText";
import file from "./file.svg";
import style from "./style.module.css";
import paperclip from "./paperclip.svg";
import Title from "../../../CommonComponents/InputFieldWithTitle/Title";
import { isMobile } from "../../../helperFunc";

function UploadDocumentComponent({
  title,
  className,
  inputClassName,
  errorInputClassName,
  customTextClassName = "",
  errorClassName,
  showError,
  errorIcon,
  titleClassName,
  placeholder = "",
  name = "",
  value = "",
  inputParentClassName = "",
  isCompulsory = false,
  compulsoryStarClassName = "",
  financePage = true,
  onChange,
  kycForm = false,
  marginTop = isMobile ? "margin-top8" : "margin-top4",
  isMultiple = false,
  acceptExtension = "",
}) {
  return (
    <div className={`${className}`}>
      {title && financePage ? (
        <HeadLines
          text={title}
          className={`font-weight600 ${
            isMobile ? "font-size12 line-height14" : "font-size14 line-height16"
          }  letterSpacing025 colorDark8 margin-top16 ${titleClassName}`}
        />
      ) : (
        <Title
          titleClassName={titleClassName}
          title={title}
          isCompulsory={isCompulsory}
          compulsoryStarClassName={`asterisk ${compulsoryStarClassName}`}
        />
      )}

      <div
        className={`${style["outerbox"]} ${inputParentClassName}  ${
          !value.length && (kycForm ? "" : style["disabled"])
        } d-flex ${marginTop} align-items-center justify-content-space-between stdFontStyle`}
      >
        <div className="d-flex align-items-center justify-content-left">
          <img src={file} alt="file" />
          <div className="d-inline-grid">
            <HeadLines
              text={placeholder}
              className={`${
                style["imageNameCSS"]
              } ${customTextClassName} font-weight400 ${
                isMobile
                  ? "font-size14 line-height16 letterSpacing001"
                  : "font-size16 line-height19 letterSpacing05"
              } colorDark8 margin-left8`}
            />
          </div>
          <InputText
            type="file"
            onChange={onChange}
            className={`${style["input-file-upload"]} ${style["inputBox"]} ${inputClassName} w-100`}
            name={name}
            placeholder={placeholder}
            showError={showError}
            errorInputClassName={errorInputClassName}
            errorClassName={errorClassName}
            errorIcon={errorIcon}
            isMultiple={isMultiple}
            accept={acceptExtension}
          />
        </div>
        <div className="d-flex align-items-center">
          <img src={paperclip} alt="attachment" />
        </div>
      </div>
    </div>
  );
}

export default UploadDocumentComponent;
