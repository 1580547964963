import {
  LogoutGreenIcon,
  docIcon,
  heartIcon,
  profilePicIcon,
  quoteIcon,
  stockIcon,
  storeIcon,
} from "../../icon_assets";
import ReleaseOrder from "../../Images/CommonImages/release-order.svg";

export const profileMenuOptions = [
  {
    icon: profilePicIcon,
    href: "/my-profile",
    title: "My Profile",
  },
  {
    icon: docIcon,
    title: "Documents",
    href: "/document-center",
  },

  {
    icon: heartIcon,
    href: "/my-wishlist",
    title: "Favourites",
  },
  {
    icon: stockIcon,
    href: "/release-stock-log",
    title: "Delivery Orders",
  },
  {
    icon: ReleaseOrder,
    href: "/release-order",
    title: "Release Orders",
  },
  {
    icon: quoteIcon,
    href: "/my-quotes",
    title: "My Quotes",
  },
  {
    icon: storeIcon,
    href: "/my-store",
    title: "My Store",
  },
  {
    icon: LogoutGreenIcon,
    href: "/",
    title: "Logout",
  },
];
