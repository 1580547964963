import React, { useEffect, useState } from "react";
import BlurModal from "../CommonComponents/BlurModal/BlurModal";
import BlurModalContent from "../CommonComponents/BlurModal/BlurModalContent";
import BlurModalHeading from "../CommonComponents/BlurModal/BlurModalHeading";
import Button from "../CommonComponents/Button";
import HeadLines from "../CommonComponents/HeadLines";
import { isMobile } from "../helperFunc";
import style from "./style.module.css";

const SkipPopUp = ({ show, setShow, onClick, skipText, setBlurBackGround }) => {
  const [selected, setSelected] = useState("");
  useEffect(() => {
    setBlurBackGround(true);
    return () => {
      setBlurBackGround(false);
    };
  }, []);
  return (
    <>
      <BlurModal
        showDesktopHeader={!isMobile}
        desktopHeading={skipText[2]}
        className={"blur-modal"}
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <>
          <BlurModalContent
            className={`margin16 ${
              isMobile ? "padding-top16" : "padding-top8"
            }`}
          >
            <HeadLines
              text={skipText[0]}
              className={`${
                isMobile
                  ? "font-weight500 font-size14 line-height16 "
                  : "font-weight400 font-size18 line-height21 "
              } letterSpacing05 ${style["padding-bottom10"]}`}
            />
            <HeadLines
              text={skipText[1]}
              className={`${
                isMobile
                  ? " font-size12 line-height14 "
                  : " font-size16 line-height19 "
              } font-weight400  letterSpacing05 colorDark12 padding-bottom8`}
            />
            <div className="d-flex margin-top16">
              <Button
                className={`${
                  isMobile
                    ? "font-weight500 font-size14 line-height16 letterSpacing0125 padding12 margin-right8"
                    : ` font-weight500   font-size16 line-height19 ${style["padding105"]} letterSpacing05 `
                }  backgroundWhite   boxRadius6 ${style["skipCSS"]} width-100 `}
                title="Yes, I want to skip"
                onClick={onClick}
              />
              <Button
                className={`${
                  isMobile
                    ? `font-weight500 backAccent4 font-size14 line-height16 letterSpacing0125 margin-right8 padding-top12 padding-bottom12 ${style["padding-left6"]} ${style["padding-right6"]}`
                    : ` font-weight500 backAccent3  font-size16 line-height19 ${style["padding105"]} letterSpacing05 margin-left20 `
                }   boxRadius6 textColorWhite width-100  `}
                title="No, I want to continue"
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>
          </BlurModalContent>
        </>
      </BlurModal>
    </>
  );
};
export default SkipPopUp;
