export const addBankDetailsRules = () => {
  return [
    {
      field_name: "name",
      label: "Bank Name",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "account_number",
      label: "Account Number",
      type: "number",
      min: 8,
      max: 20,
      isRequired: true,
    },
    {
      field_name: "beneficiary_name",
      label: "Beneficiary Name",
      type: "String",
      isRequired: true,
    },
    {
      field_name: "ifsc_code",
      label: "Bank IFSC Code",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "branch_name",
      label: "Bank Branch",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "cancel_check",
      label: "Cancelled Check",
      type: "file",
      isRequired: true,
    },
  ];
};
export const checkFileType = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
];
