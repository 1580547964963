import React from "react";
import HeadLines from "../HeadLines";
import { isMobile } from "../../helperFunc";
import { errorInfoIcon } from "../../icon_assets";
import s from "./style.module.css";
const ErrorMessage = ({
  icon,
  iconClassName = s["icon-style"],
  textClassName = isMobile
    ? `font-weight500 font-size12 line-height14 letterSpacing05 textColorError stdFontStyle`
    : `font-weight400 font-size14 line-height16 textColorError stdFontStyle`,
  className,
  text,
}) => {
  return (
    <div className={`d-flex align-items-center gap6 ${className}`}>
      <img className={iconClassName} src={errorInfoIcon} alt="error-icon" />
      <HeadLines className={textClassName} text={text} />
    </div>
  );
};

export default ErrorMessage;
