import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

const MultiLineChart = ({
  data = {
    labels: ["July10", "July13", "July16"],
    datasets: [
      {
        label: "Dataset 1",
        data: [12, 34, 45],
        borderColor: "#127960",
      },
      {
        label: "Dataset 2",
        data: [23, 49, 54],
        borderColor: "#00C0EF",
      },
    ],
  },
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  return <Line options={options} data={data} />;
};

export default MultiLineChart;
