import {
  ADD_PRODUCT,
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_RESET,
  ADD_PRODUCT_SUCCESS,
} from "../_actions/actionType";

const initialState = {
  data: {},
  isFetching: false,
  error: [],
  procuct_Slug:""
};

export default function AddProduct(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        data: {},
        isFetching: true,
        error: [],
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        product_Slug:action.data?.data?.data?.product?.product_slug?action.data.data.data.product.product_slug:"/manage-products",
        error: [],
      };
    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: action.err,
      };
    case ADD_PRODUCT_RESET:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: [],
      };
    default:
      return state;
  }
}
