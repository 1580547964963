import React from "react";
import s from "./styles.module.css";
import HeadLines from "../CommonComponents/HeadLines";
const StatusBar = (props) => {
  const {
    className,
    className2 = "d-flex align-items-center justify-content-center",
    short,
    text,
    leftIcon = "",
    rightArrow = ">>",
    onClick,
  } = props;
  return (
    <div className={className2} onClick={onClick}>
      <div
        className={`d-flex 
                  justify-content-between 
                  ${s["status-bar"]} 
                  ${className}`}
      >
        <div className="d-flex align-items-center">
          {/* <div>
            <img src={leftIcon} className={`${s["mr-5_5"]}`} />
          </div> */}
          <div>
            <HeadLines text={text} />
          </div>
        </div>
        {rightArrow && <div className="">{rightArrow}</div>}
      </div>
    </div>
  );
};

export default StatusBar;
