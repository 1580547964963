
import {OPTION_CONSTANT} from '../_constants/Redux/OPTION_CONSTANT'
export const optionActions={
    add,
    clear,
    filters,
    addOption,
    commoditySelected,
}

function add(data){
    return {type:OPTION_CONSTANT.ADD,data}
}

function clear(){
    return {type:OPTION_CONSTANT.CLEAR}
}

function filters(data){
    return {type:OPTION_CONSTANT.FILTER, data}
}

function addOption(data) {
    return { type:OPTION_CONSTANT.OPTIONS,data } 
}
function commoditySelected(data) {
    return { type:'COMMODITY',data } 
}