import React, { useState } from "react";
import s from "./style.module.css";
import "../commonStyle.css";
import EditButton from "./EditButton";
import BlurModal from "./BlurModal/BlurModal";
import BlurModalHeading from "./BlurModal/BlurModalHeading";
import HeadLines from "./HeadLines";
import BlurModalContent from "./BlurModal/BlurModalContent";
import ImageUpload from "./ImageUpload/ImageUpload";
import { picIcon, profilePic } from "../icon_assets";
import { isMobile } from "../helperFunc";
const CoverPic = (props) => {
  const {
    imageSource,
    showEditIcon = true,
    className = `position-relative`,
    setCoverImage = () => null,
    coverClassName = ``,
    editIconClassName = ``,
    editIconWidth = ``,
  } = props;
  const [show, setShow] = useState();
  const handleSelectedCoverImage = (imageFiles) => {
    setCoverImage(imageFiles[0]);
  };
  return (
    <>
      <div className={className}>
        <div
          className={`boxRadius8 ${s["cover-height"]}
                     ${imageSource ? "" : s["def-cover"]} ${coverClassName}`}
        >
          {imageSource && (
            <img
              src={imageSource}
              alt=""
              className={`boxRadius8 ${s["cover-image"]}`}
            />
          )}
        </div>
        {showEditIcon && (
          <EditButton
            onEditButtonClick={() => setShow(true)}
            className={`cursor-pointer position-absolute ${s["edit-icon-cover"]}  ${editIconClassName}`}
            editIconWidth={editIconWidth}
          />
        )}
      </div>
      <BlurModal
        show={show}
        onHide={() => setShow(false)}
        showDesktopHeader={!isMobile ? true : false}
        desktopHeading={"New Cover Photo"}
      >
        <>
          {isMobile && (
            <BlurModalHeading
              className={`padding-left16
                        padding-bottom16
                        padding-top20`}
              heading={
                <HeadLines
                  className={`font-weight600 
                            font-size18 
                            line-height21`}
                  text={"New Cover Photo"}
                />
              }
            />
          )}
          <BlurModalContent>
            <ImageUpload
              bottomTextClassName="margin-top8 font-size14 line-height16 letter-spacing05"
              bottomText="Please upload a jpeg/png. Maximum file size limit is 2MB."
              maxFileSize={2000000}
              errMsg={
                isMobile
                  ? "Please Upload Valid Image and Max Size Should be 2 MB"
                  : "File format not supported or the file you uploaded exceeds 2MB"
              }
              sendImagesToParent={handleSelectedCoverImage}
              // uploadedImage={handleUploadedProfileImage}
              uploadImageClose={() => setShow(false)}
              id1={"campic"}
              id2={"devicefile"}
              icon1={profilePic}
              icon2={picIcon}
              errorClassNameParent={
                isMobile
                  ? undefined
                  : "d-flex align-items-center gap4 margin-top8"
              }
            />
          </BlurModalContent>
        </>
      </BlurModal>
    </>
  );
};

export default CoverPic;
