import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { translateActions } from "../../_actions/index";
import { connect } from "react-redux";
import { ContactUs } from "../../_services/CommnService/ContactUs/ContactUs";
const MultiLanguageTranslation = ({ t, title, not_exist, ...props }) => {
  const [lang, setLang] = useState("en");
  useEffect(() => {
    let checkedField = localStorage.getItem("lang");
    setLang(checkedField);
  }, [localStorage.getItem("lang")]);

  // useEffect(() => {
  //   if (title)
  //     translation()
  // }, [title, localStorage.getItem("lang")])

  // const translation = async () => {
  //   let data = {}
  //   if (props.translation && props.translation.translation[title] === undefined && localStorage.getItem("lang") === "hindi" && !props.i18n.exists(title)) {
  //     let obj = localStorage.getItem("getLanguageJson")
  //     if (obj) {
  //       data = { ...JSON.parse(obj) }
  //     }
  //     let res= await ContactUs.googleTranslateApi(title)
  //     if(res.data?.data?.translations[0]?.translatedText && !data[title]){
  //       data[title]=res.data.data.translations[0].translatedText
  //     }
  //     localStorage.setItem("getLanguageJson", JSON.stringify(data))
  //   }
  // }

  if (!props.i18n.exists(title)) {
    if (lang === "en" || lang === null) {
      return t(title);
    } else {
      if (
        props.translation &&
        props.translation.translation[title] &&
        props.translation.translation[title] !== "res"
      ) {
        return props.translation.translation[title];
      } else {
        return t(title);
      }
    }
  } else {
    return t(title);
  }
};

const mapDispatchToProps = {
  addTranslation: translateActions.addTranslation,
};

function mapStateToProps(state) {
  const { translation } = state;
  return {
    translation,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MultiLanguageTranslation));
