import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'

export const getBusbursement = async () => {
    var reuestlink = "/all-disbursement";
    try {
        const response = await RestMethod.GET(reuestlink);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error("Failed to load disbursements. Please try later")
        console.log("error detected while fetching data from api");
        return null;
    }
}


export const getBusbursementDetails = async (id) => {
    var reuestlink = "/disbursement-detail/" + id;
    try {
        const response = await RestMethod.GET(reuestlink);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error("Failed to load disbursement. Please try later")
        console.log("error detected while fetching data from api");
        return null;
    }
}

export const updateBusbursementDetails = async (id, data) => {
    var reuestlink = "/update-disbursement/" + id;
    try {
        const response = await RestMethod.PUT(reuestlink, data);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error("Failed to update disbursement. Please try later")
        console.log("error detected while fetching data from api");
        return null;
    }
}

export const updateBusbursementDetailsHistory = async (id) => {
    var reuestlink = "/disbursement-history/" + id;
    try {
        const response = await RestMethod.GET(reuestlink);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error("Failed to load disbursement history. Please try later")
        console.log("error detected while fetching data from api");
        return null;
    }
}

export const statusBusbursementDetailsHistory = async (id) => {
    var reuestlink = "/disbursement-status/" + id;
    try {
        const response = await RestMethod.GET(reuestlink);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        // toastr.error("Failed to load disbursement")
        console.log("error detected while fetching data from api");
        return null;
    }
}


export const getBusbursementDetailsByuserID = async (id) => {
    var reuestlink = "/disbursement-user-detail" ;
    try {
        const response = await RestMethod.GET(reuestlink);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

