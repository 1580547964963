export function Rules(field, ruleObj) {
  var errorMsg = "";
  if (
    field === "" ||
    field === undefined ||
    field === null ||
    (ruleObj.checkArray && Array.isArray(field) && field.length === 0)
  ) {
    errorMsg = ruleObj.label + " is Required.";
  } else {
    var vehicle = /^[A-Za-z]{2}[0-9]{1,2}(?:[A-Za-z])?(?:[A-Za-z]*)?[0-9]{4}$/;
    if (ruleObj?.minLength && field.length < ruleObj.minLength) {
      errorMsg = "Min length " + ruleObj.minLength + " is Required.";
    } else if (ruleObj.type === "ifsc") {
      var ifsc = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
      if (ifsc.test(field) === false) {
        errorMsg = "Please enter valid IFSC code";
      }
    } else if (ruleObj.type === "vehicle" && vehicle.test(field) === false) {
      errorMsg = "Please enter valid vehicle number";
    } else if (ruleObj.type === "password") {
      var password = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
      var specialCharacter = /^(?=.*[!@#$%^&*])/;
      var Maxlen = /^(?=.{,16})/;
      var len = /^(?=.{8,})/;
      var capital = /^(?=.*[A-Z])/;
      var number = /^(?=.*[0-9])/;

      if (password.test(field) === false) {
        {
          errorMsg = "Please enter valid password";
        }
        if (len.test(field) === false) {
          {
            errorMsg = "Min length 8 is required";
          }
        }
        if (specialCharacter.test(field) === false) {
          {
            errorMsg = "Please enter at least one special character";
          }
        }
        if (number.test(field) === false) {
          {
            errorMsg = "Please enter at least one number";
          }
        }
      } else {
        if (len.test(field) === false) {
          {
            errorMsg = "Min length 8 is required";
          }
        }
        if (specialCharacter.test(field) === false) {
          {
            errorMsg = "Please enter at least one special character";
          }
        }
        if (number.test(field) === false) {
          {
            errorMsg = "Please enter at least one number";
          }
        }
      }
    } else if (ruleObj.type === "number") {
      if (
        ruleObj["max"] !== undefined &&
        field.toString().length > ruleObj.max
      ) {
        errorMsg = "Max " + ruleObj.max + " digit required";
      }
      if (
        ruleObj["min"] !== undefined &&
        field.toString().length < ruleObj.min
      ) {
        errorMsg = "Min " + ruleObj.min + " digit required";
      }

      if (
        ruleObj["graterThen"] !== undefined &&
        +field <= +ruleObj.graterThen
      ) {
        errorMsg = `Please enter greater than ${ruleObj.graterThen}`;
      }
      if (ruleObj["price"] !== undefined && +field < 10000) {
        errorMsg = `Enter price for 1 metric ton`;
      }
      if (ruleObj["lessThen"] !== undefined && +field > +ruleObj.lessThen) {
        errorMsg = `Please enter less than or equal to ${ruleObj.lessThen}`;
      }

      if (
        ruleObj["isNonNegative"] !== undefined &&
        !isNaN(field) &&
        parseFloat(field) < 0
      ) {
        errorMsg = "Negative Value is not Allowed";
      }
      if (ruleObj["toFixed"] !== undefined && !isNaN(field)) {
        let temp = field.toString().split(".");
        let toFixedVal = ruleObj["toFixedVal"] ? ruleObj["toFixedVal"] : 2;
        if (temp.length > 1 && temp[temp.length - 1].length > toFixedVal)
          errorMsg = "Only " + toFixedVal + " Decimal Values are Allowed!";
      }

      if (
        ruleObj["greaterThanNonStrictly"] !== undefined &&
        +field < +ruleObj.greaterThanNonStrictly
      ) {
        errorMsg = `Please enter equals or greater than ${ruleObj.greaterThanNonStrictly}`;
      }

      if (isNaN(field)) {
        errorMsg = "Please enter a valid number";
      }
      if (ruleObj.zero) {
        if (field == 0) {
          errorMsg = "Value must be greater than 0";
        }
      }
    } else if (ruleObj.type === "phone" && !IsMobileNumber(field)) {
      errorMsg = "Please enter valid mobile number";
      // if (
      //   ruleObj["max"] !== undefined &&
      //   field.toString().length > ruleObj.max
      // ) {
      //   errorMsg = "Please enter Valid Mobile Number";
      // }
      // if (
      //   ruleObj["min"] !== undefined &&
      //   field.toString().length < ruleObj.min
      // ) {
      //   errorMsg = "Please enter Valid Mobile Number";
      // }
      // if (isNaN(field)) {
      //   errorMsg = "Please enter Valid Mobile Number";
      // }
      // if (ruleObj.zero) {
      //   if (field == 0) {
      //     errorMsg = "Value must be greater than 0";
      //   }
      // }
    } else if (ruleObj.type === "email") {
      var regEmail =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (regEmail.test(field) === false) {
        errorMsg = "Please enter a valid E-Mail ID";
        // ruleObj.label + " is invalid email";
      }
    } else if (ruleObj.type === "date") {
      var pattern =
        /(0[1-9]|[12][0-9]|3[01])[- -.](0[1-9]|1[012])[- -.](19|20)\d\d/;
      if (pattern.test(field) === false) {
        errorMsg = "Date pattern should be dd-mm-yyyy";
      }
    } else if (ruleObj.type === "string") {
      errorMsg = "";
    } else if (ruleObj.type === "gstin") {
      var gst_number = field;

      if (gst_number.toString().length < 15) {
        return (errorMsg = "Minimum 15 digit required");
      }
      if (gst_number.toString().length > 15) {
        return (errorMsg = "Maximun 15 digit required");
      }

      if (gst_number.charAt(13) !== "Z") {
        return (errorMsg = "Provide a valid GST Number.");
      }

      var third_letter = gst_number.charAt(2);
      var fourth_letter = gst_number.charAt(3);
      var fifth_letter = gst_number.charAt(4);
      var thirteen_letter = gst_number.charAt(12);

      if (
        /\d/.test(parseInt(gst_number.charAt(0))) === false ||
        /\d/.test(parseInt(gst_number.charAt(1))) === false
      ) {
        return (errorMsg = "Provide a valid GSTIN Number.");
      }

      if (/\d/.test(parseInt(thirteen_letter)) === false) {
        return (errorMsg = "Provide a valid GSTIN Number.");
      }

      var regGST = /^[a-zA-Z]{1}$/i;

      if (
        !regGST.test(third_letter) ||
        !regGST.test(fourth_letter) ||
        !regGST.test(fifth_letter)
      ) {
        return (errorMsg = "Provide a valid GSTIN Number.");
      }

      var first_two = parseInt(gst_number.substring(0, 2));

      if (first_two > 37) {
        return (errorMsg = "Provide a valid GSTIN Number.");
      }
    } else if (ruleObj.type === "pan") {
      var panVal = field;
      var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

      if (!regpan.test(panVal)) {
        errorMsg = "Provide a valid PAN Number";
      }
    } else if (ruleObj.type === "volume") {
      if (
        ruleObj["max"] !== undefined &&
        field.toString().length > ruleObj.max
      ) {
        errorMsg = "Max " + ruleObj.max + " digit required";
      }
      if (
        ruleObj["min"] !== undefined &&
        field.toString().length < ruleObj.min
      ) {
        errorMsg = "Min " + ruleObj.min + " digit required";
      }
      if (field <= 0) {
        errorMsg = "Volume must be greater than 0";
      }

      if (isNaN(field)) {
        errorMsg = "Please enter a valid number";
      }
    } else {
    }
  }

  return errorMsg;
}

function IsMobileNumber(txtMobile) {
  var mob = /^[1-9]{1}[0-9]{9}$/;
  if (mob.test(txtMobile) == false) {
    return false;
  }
  return true;
}
