import React, { useState } from "react";
import BlurModalHeading from "../CommonComponents/BlurModal/BlurModalHeading";
import HeadLines from "../CommonComponents/HeadLines";
import BlurModalContent from "../CommonComponents/BlurModal/BlurModalContent";
import Button from "../CommonComponents/Button";
import { isMobile } from "../helperFunc";
import s from "./emailStyle.module.css";
import BlurModal from "../CommonComponents/BlurModal/BlurModal";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import { errorInfoIcon } from "../icon_assets";
import { LoginServices } from "../../../../_services/Arya2.0/Login/LoginServices";
const EmailRegisterPopUp = ({
  setShowEmailVerifyPopUp,
  showEmailVerifyPopUp,
  setEmail,
  text1 = "To access our services & to recieve important updates, please ensure your email ID is updated and verified",
  text2 = "Note: Failure to update may affect your access to certain features",
  email,
  setShowEmailSentPopUp,
  showVerifyEmailPopUSent,
}) => {
  const [verficationSent, setVerficationSent] = useState(false);
  const [error, setError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [verifyLoader, setVerifyLoader] = useState(false);

  const handleEmailVerifyClick = async () => {
    if (email.length < 0) {
      setError("Email is Required");
    } else if (!isValidEmail(email)) {
      setError("Enter a vaild email address");
    } else {
      try {
        setError("");
        setIsDisable(true);
        setVerifyLoader(true);
        const result = await LoginServices.sendEmailVerifyLink({
          email,
        });
        if (result?.data?.status_code === 200) {
          setVerficationSent(true);
          if (showVerifyEmailPopUSent) {
            setShowEmailVerifyPopUp(false);
            setShowEmailSentPopUp(true);
          }
        } else if (result === false) {
          setError("This email is already registered.");
        } else {
          setError("Something went wrong, Try again later");
        }
      } catch (error) {
        throw error;
      } finally {
        setIsDisable(false);
        setVerifyLoader(false);
      }
    }
  };
  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  }
  return (
    <BlurModal
      className={"blur-modal"}
      show={showEmailVerifyPopUp}
      showDesktopHeader={!isMobile}
      desktopHeading={"Update Email"}
      headingClassName={`font-size20 line-height23 letterSpacing001 font-weight500 colorDark5`}
      onHide={() => setShowEmailVerifyPopUp(false)}
    >
      <>
        {isMobile && (
          <BlurModalHeading
            className={`padding-left16
                        padding-bottom16
                       `}
            heading={
              <HeadLines
                className={`font-weight600 
                            font-size18 
                            line-height21`}
                text={"Update Email"}
              />
            }
          />
        )}
        <BlurModalContent
          className={
            isMobile
              ? "padding16 "
              : "padding-top20 padding-left20 padding-right20 padding-bottom20"
          }
        >
          <div className={`  `}>
            <HeadLines
              text={`${text1}`}
              className={`font-weight400  ${
                isMobile
                  ? "font-size12 line-height14"
                  : "font-size16 line-height19"
              } letterSpacing025 colorAccent4`}
            />
            <HeadLines
              text={`${text2}`}
              className={`font-weight400  ${
                isMobile
                  ? "font-size12 line-height14"
                  : "font-size16 line-height19"
              } letterSpacing025 colorDark9 margin-top4`}
            />
            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size12 line-height14`
                  : "font-size16 line-height19 font-weight600"
              } letterSpacing05 margin-top12 colorDark9 `}
              title={"E-Mail ID"}
              disabled={isDisable}
              isCompulsory={false}
              className={s["mb-0"]}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top8   width-100 boxRadius4 padding12 ${
                s["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? "font-size14 line-height16"
                  : "font-size16 line-height19"
              } letterSpacing001 colorDark1`}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              showError={error || ""}
              errorIcon={errorInfoIcon}
              value={email}
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
              verifyClicked={handleEmailVerifyClick}
              marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
              showVerifyText={!verifyLoader}
              verifyLoaderClass={s["verify-loader-style"]}
              verifyText={"SEND LINK"}
              showVerifyTextClassName={
                email === ""
                  ? s["disable-send-link"]
                  : s["to-verify-email-text"]
              }
              rightInputFixedTextClassName={s["to-verify-email-text"]}
              verifyLoader={verifyLoader}
            />

            {/* {verficationSent && ( */}
            <HeadLines
              text={"A verification link will be sent to your email"}
              className={`font-weight400  ${
                isMobile
                  ? "font-size12 line-height14"
                  : "font-size16 line-height19"
              } letterSpacing025 colorAccent5 margin-top4 margin-bottom16`}
            />
            {/* )} */}
          </div>
        </BlurModalContent>
      </>
    </BlurModal>
  );
};
export default EmailRegisterPopUp;
