import React, { Suspense, useState } from "react";
import { useHistory } from "react-router";
import HeadLines from "../../CommonComponents/HeadLines";
import useIsUserLoggedIn from "../../CustomHooks/useIsUserLoggedIn";
import useUserDetails from "../../CustomHooks/useUserDetails";
import { profileIcon } from "../../icon_assets";
import style from "./style.module.css";
import DesktopProfileMenu from "./DesktopProfileMenu";
import { isMobile } from "../../helperFunc";
import useAllUserData from "../../CustomHooks/useAllUserData";
// import Login from "./Login/Login";
const Login = React.lazy(() => import("./../../Login/Login"));
const Registration = React.lazy(() =>
  import("./../../Registration/Registration")
);

const LoginPart = (props) => {
  const history = useHistory();
  const userDetails = useUserDetails();
  const [userLoggedIn] = useIsUserLoggedIn();
  const [regShow, setRegShow] = useState(false);
  const [show, setShow] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const allUserData = useAllUserData() || {};

  const userIsSubAdminOrSuperAdmin =
    allUserData.user_type === "Super Admin" ||
    allUserData.user_type === "Sub Admin";

  String.prototype.isNumber = function () {
    return /^\d+$/.test(this);
  };
  let userName = userDetails?.first_name;
  if (userName) {
    userName = userName.trim().isNumber() ? "" : userName;
  }
  if (userName) {
    userName = userName
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  }

  return (
    <>
      {show && (
        <Suspense fallback={<></>}>
          <Login
            show={show}
            setShow={setShow}
            setRegShow={setRegShow}
            isDesktop={true}
          />
        </Suspense>
      )}
      {regShow && (
        <Suspense fallback={<></>}>
          <Registration show={regShow} setShow={setRegShow} />
        </Suspense>
      )}
      {!userLoggedIn ? (
        <HeadLines
          text="Login"
          className={`boxRadius6 cursorPointer line-height19 letterSpacing05 textColorWhite font-weight500 ${style["borderWhite"]} padding-left12 padding-right12 padding-top8 padding-bottom8`}
          onClick={() => setShow(true)}
        />
      ) : userName ? (
        <div
          className={`font-weight600 cursorPointer line-height16 justify-content-center font-size14 d-flex align-items-center ${style["IconHeightWidth"]}  ${style["border-radius50"]} colorAccent3 ${style["profileBack"]}`}
          onClick={() => {
            if (!isMobile && !userIsSubAdminOrSuperAdmin) {
              setShowDropdown(!showDropdown);
            } else history.push("/my-profile");
          }}
        >
          {userName[0]}
          {userName[1] ? userName[1] : ""}
        </div>
      ) : (
        <div
          // onClick={() => history.push("/my-profile")}
          className="cursorPointer"
          onClick={() => {
            if (!isMobile && !userIsSubAdminOrSuperAdmin) {
              setShowDropdown(!showDropdown);
            } else history.push("/my-profile");
          }}
        >
          <img
            src={profileIcon}
            alt=""
            className={`${style["IconHeightWidth"]} 
            
                ${style["border-radius50"]}`}
          />
        </div>
      )}
      {showDropdown && (
        <DesktopProfileMenu
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
        />
      )}
    </>
  );
};
export default LoginPart;
