import React, { useState } from "react";
import HeadLines from "../CommonComponents/HeadLines";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import { errorInfoIcon } from "../icon_assets";
import { KycFormData } from "./Constant";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import UploadDocumentComponent from "../Finance/Components/UploadFile/UploadDocumentComponent";
import MultiLanguageTranslate from "../../../../_components/Translate/MultiLangTranslate";
import { isMobile } from "../helperFunc";
import eyeIcon from "./../DesktopImages/Home/eye.svg";
import darkeye from "./../DesktopImages/Home/darkeye.svg";
import eyeCutIcon from "./../DesktopImages/Home/eyeCutIcon.svg";

import { fileSizeMax } from "../../../../_constants/Config/AryaConstants";
const KycForm = (props) => {
  const { t: translation } = useTranslation();

  const [kycDoc, setKycDoc] = useState(
    props.type == "farmer" ? "Aadhar" : props.type == "banker" ? "Gst" : "Pan"
  );
  const [aadhaarFrontImage, setAadhaarFrontImage] = useState("");
  const [panFrontImage, setPanFrontImage] = useState("");
  const [gstFrontImage, setGstFrontImage] = useState("");
  const [aadhaarFrontImageBase64, setAadhaarFrontImageBase64] = useState("");
  const [imageName, setImageName] = useState({});
  const [errorAt, setErrorAt] = useState("");
  const [showKycText, setShowKycText] = useState(false);
  const callBackFunction = (image) => {
    if (kycDoc == "Aadhar") setAadhaarFrontImage(image);
    else if (kycDoc == "Pan") setPanFrontImage(image);
    else setGstFrontImage(image);

    props.setData((prev) => ({
      ...prev,
      [kycDoc == "Aadhar"
        ? "aadharDoc"
        : kycDoc == "Pan"
        ? "panDoc"
        : "gstDoc"]: image,
    }));
  };

  // const callBackFunction2 = (image) => {
  //   props.setData((prev) => ({
  //     ...prev,
  //     ["aadharDocBase64"]: image,
  //   }));
  // };

  const handleImageChange = (event) => {
    // window.aaa = event.target.files;
    const a = event.target.files;
    props.setData((prev) => ({
      ...prev,
      ["aadharDoc"]: a?.[0] || {},
    }));
    setImageName(a?.[0]);
  };

  // const handleChange = (e, callFunction, setBase64) => {
  //   var fileList = e.target.files;
  //   let type_temp = fileList[0].name.split(".");
  //   let filesize = fileList[0].size;
  //   let type = type_temp[type_temp.length - 1];
  //   let extention = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"];
  //   if (extention.includes(type) && filesize < 2048000) {
  //     var filecontainer = [];
  //     for (var i = 0; i < fileList.length; i++) {
  //       var file = fileList[i];
  //       let reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => {
  //         if (setBase64) {
  //           setBase64(reader.result);
  //           props.setData((prev) => ({
  //             ...prev,
  //             ["aadharDocBase64"]: reader.result,
  //           }));
  //         }
  //         filecontainer.push(reader.result);
  //         if (filecontainer.length === fileList.length) {
  //           uploadImages(filecontainer, callFunction);
  //         }
  //       };
  //       reader.onerror = function (error) {
  //         toastr.error("please upload valid file");
  //         // setLoader(false);
  //       };
  //     }
  //   } else {
  //     toastr.warning("Please Upload Valid Image and Max Size Should be 2MB");
  //     // setLoader(false);
  //   }
  // };

  // const uploadImages = async (files, callFunction) => {
  //   // setLoader(true);
  //   let imgarr = [];
  //   let uploadeds3link = [];
  //   for (var i = 0; i < files.length; i++) {
  //     var fileobj = {
  //       path: "arya/warehouseRequests/",
  //       file: files[i],
  //     };
  //     var apiresponse = await FileUploadService.fileUloadService(fileobj);
  //     if (apiresponse !== null && apiresponse.data.data.file_path) {
  //       var objimg = {
  //         src: apiresponse.data.data.file_path,
  //         thumbnail: apiresponse.data.data.file_path,
  //         thumbnailWidth: 100,
  //         thumbnailHeight: 100,
  //       };
  //       imgarr.push(objimg);
  //       uploadeds3link.push(apiresponse.data.data.file_path);
  //
  //     }
  //     // setLoader(false);
  //   }
  //   callFunction(uploadeds3link[0]);
  //   props.setData((prev) => ({
  //     ...prev,
  //     [kycDoc == "Aadhar"
  //       ? "aadharDoc"
  //       : kycDoc == "Pan"
  //       ? "panDoc"
  //       : "gstDoc"]: uploadeds3link[0],
  //   }));
  //
  // };
  const fishEyeIcon = (
    <>
      <img
        src={
          props.data[kycDoc] ? (showKycText ? eyeCutIcon : darkeye) : eyeIcon
        }
        onClick={() => setShowKycText(!showKycText)}
        className={`${style["positionEyeCSS"]} positionAbsolute cursorPointer`}
        height={isMobile ? "18" : "20"}
        width={isMobile ? "18" : "20"}
        alt=""
      />
    </>
  );

  return (
    <div
      className={`margin-left16 margin-right16 ${
        isMobile ? "" : "padding-top8"
      }`}
    >
      {props.type != "banker" && (
        <HeadLines
          className={`font-weight400 ${
            isMobile
              ? "font-size12 margin-bottom16 line-height14"
              : "margin-bottom24 font-size16 line-height19"
          } letterSpacing025  `}
          text="Please select any one of the ID proof and upload the required document"
        />
      )}
      <div className="d-flex">
        {KycFormData.map((item, index) => {
          if (
            (props.type == "farmer" &&
              (item.label == "Aadhar" || item.label == "Pan")) ||
            (props.type == "banker" && item.label == "Gst") ||
            (props.type == "others" &&
              (item.label == "Pan" || item.label == "Gst"))
          ) {
            return (
              <>
                <div
                  onClick={() => {
                    setKycDoc(item.label);
                  }}
                  className={`cursorPointer boxRadius6 ${
                    kycDoc == item.label
                      ? style["selectedBorder"]
                      : "light2Border"
                  } ${
                    isMobile
                      ? ` ${style["margin-bottom14"]} margin-right16`
                      : ` margin-bottom24 margin-right24`
                  }`}
                >
                  <div
                    className={`d-flex textCenter  ${
                      style[item.label + "_Background"]
                    } boxRadius6`}
                  >
                    <img
                      src={item.image}
                      className={` ${style[item.label + "_Height"]} width-100 `}
                    />
                  </div>
                  <HeadLines
                    text={item.label}
                    className={`${
                      props.userType == item.label ? "colorAccent4" : ""
                    } textCenter padding4 ${
                      isMobile
                        ? "font-size12 line-height14"
                        : " font-size16 line-height19 colorAccent4"
                    } font-weight500  letterSpacing025 ${
                      props.type == "farmer" ? "" : style["txt-uppercase"]
                    }`}
                  />
                </div>
              </>
            );
          } else {
            return "";
          }
        })}
      </div>
      <div className={isMobile ? "" : "d-flex"}>
        <InputFieldWithTitle
          titleClassName={`  ${
            isMobile
              ? `font-size14 line-height16 font-weight500 ${style["color333333"]}`
              : "font-size16 line-height19 font-weight600 colorDark9"
          } letterSpacing05 `}
          title={
            "Enter " +
            (kycDoc != "Aadhar" ? kycDoc.toUpperCase() : kycDoc) +
            " Number"
          }
          disabled={false}
          isCompulsory={true}
          inputClassName={`width-100 boxRadius4 padding12 ${
            style["inputBorder"]
          } font-weight400  ${
            isMobile
              ? "font-size14 line-height16 letterSpacing001 margin-top8 "
              : "font-size16 line-height19 letterSpacing05 margin-top4 "
          } ${showKycText ? "" : style["pox-password"]}`}
          inputParentClassName=""
          errorInputClassName={"input-style-error"}
          errorClassName={"input-error-style margin-top8"}
          showError={props?.error ? props.error[kycDoc] : ""}
          errorIcon={errorInfoIcon}
          value={props.data[kycDoc]}
          name={kycDoc}
          onChange={props.onChange}
          compulsoryStarClassName={style["margin-top4px"]}
          className="width-100"
          verifyText={fishEyeIcon}
          showVerifyText={true}
        />

        {kycDoc == "Aadhar" ? (
          <div className={isMobile ? "" : "width-100 margin-left24"}>
            <UploadDocumentComponent
              className={`${isMobile ? "margin-top16" : ""} width-100`}
              customTextClassName={`${style["text-truncate-file-upload"]} text-truncate`}
              onChange={(e) => {
                handleImageChange(e);
              }}
              value={imageName?.name}
              placeholder={imageName?.name || "Select Aadhar"}
              inputClassName={`width-100 boxRadius4 padding12 ${
                style["input-border"]
              } font-weight400 ${
                isMobile
                  ? "font-size14 line-height16 letterSpacing001 margin-top8 "
                  : "font-size16 line-height19 letterSpacing05 margin-top4"
              }`}
              inputParentClassName={
                isMobile ? " margin-top8  " : " margin-top4 "
              }
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style"}
              title={"Upload Aadhar Card"}
              showError={""}
              errorIcon={errorInfoIcon}
              titleClassName={`${
                isMobile
                  ? `font-size14 line-height16 font-weight500 ${style["color333333"]}`
                  : "font-size16 line-height19 font-weight600 colorDark9"
              } letterSpacing05`}
              compulsoryStarClassName={style["margin-top4px"]}
              isCompulsory={true}
              financePage={false}
              kycForm={true}
            />

            {props?.error && props.error["aadharDoc"] ? (
              <div className="d-flex align-items-start padding-top4">
                {errorInfoIcon && (
                  <img
                    src={errorInfoIcon}
                    className={`${style["errorCSS"]} ${
                      style["error-icon-style"]
                    } display-inline-block  ${
                      isMobile ? "" : style["width18px"]
                    }`}
                    alt=""
                  />
                )}
                <HeadLines
                  className={`${
                    style["errorCSS"]
                  } input-error-style font-size12 display-inline-block padding-left4 ${
                    isMobile ? "" : `${style["desktopCssImp"]} `
                  }`}
                  text={
                    translation(`${props.error["aadharDoc"]}`)

                    // <>
                    //   {errorIcon && (
                    //     <img
                    //       src={errorIcon}
                    //       className={`${errorIconClassName}`}
                    //       alt=""
                    //     />
                    //   )}
                    //   {showError}
                    // </>
                  }
                ></HeadLines>
              </div>
            ) : (
              ""
            )}
            {/* <UploadDoc
            titleClassName={`${style["color333333"]} font-weight500 font-size14 line-height16 letterSpacing05`}
            title={
              "Upload " +
              (kycDoc != "Aadhar" ? kycDoc.toUpperCase() : kycDoc) +
              " Card"
            }
            inputClassName={`width-100 boxRadius4 padding12 ${style["inputBorder"]} font-weight400 font-size14 line-height16 letterSpacing001`}
            inputParentClassName=" margin-top8  "
            compulsoryStarClassName={style["margin-top4px"]}
            isCompulsory={true}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style"}
            showError={props?.error ? props.error["aadharDoc"] : ""}
            errorIcon={errorInfoIcon}
            value=""
            name="documents"
            onChange={(e) => {
              handleImageChange(e);
            }}
          /> */}
            <HeadLines
              text={`Please upload a jpeg/png/pdf. Maximum file size limit is ${fileSizeMax}MB.`}
              className={` font-weight400 ${
                isMobile
                  ? "font-size10 line-height12 margin-top16 "
                  : "font-size14 line-height16 margin-top8 "
              } letterSpacing025 colorDark7`}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default KycForm;
