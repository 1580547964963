import React from "react";
import HeadLines from "./../../CommonComponents/HeadLines";
import RupeeIcon from "./../../Images/Notification/RupeeIcon.svg";
import RoundArrows from "./../../Images/Notification/roundArrows.svg";
import profile from "./../../Images/Notification/profile.svg";

import TiltArrow from "./../../Images/Notification/TiltArrow.svg";
import style from "./style.module.css";
import { isMobile } from "../../helperFunc";
const NotificationCards = ({ data }) => {
  return (
    <>
      {data.length ? (
        <>
          {data.map((item, index) => {
            return (
              <div
                className={`cursorPointer d-flex align-items-center ${style["border-bottom"]} margin-left16 margin-right16 padding-bottom12 margin-top12`}
                onClick={item.onClick}
              >
                <div
                  className={`${style["padding6"]} ${style["radius50"]} backAccent9`}
                >
                  <img
                    alt=""
                    src={
                      item.title === "Complete KYC"
                        ? profile
                        : item.finance
                        ? RupeeIcon
                        : RoundArrows
                    }
                  />
                </div>
                <div className="width-100">
                  <HeadLines
                    text={item.title}
                    className={`font-weight400 ${
                      isMobile
                        ? `font-size12 line-height14  letterSpacing025`
                        : `font-size14 line-height16  letterSpacing05`
                    }  colorDark5 margin-left16 margin-right16`}
                  />
                  <HeadLines
                    text={item.body}
                    className={`font-weight400 ${
                      isMobile
                        ? `font-size12 line-height14  letterSpacing025`
                        : `font-size14 line-height16  letterSpacing05`
                    } colorDark5  margin-left16 margin-right16`}
                  />
                </div>
                <img src={TiltArrow} alt="" className="mtb-auto" />
              </div>
            );
            //     })}
            //   </>
            // );
          })}
        </>
      ) : (
        ""
      )}
      {/* // ) : (
      //   <div
      //     className={` ${style["border-bottom"]} margin-left16 margin-right16 padding-bottom16 margin-top16`}
      //   >
      //     <HeadLines
      //       text="No New Notifications"
      //       className="textCenter font-weight400 font-size14 line-height16 letterSpacing001 colorDark7"
      //     />
      //   </div>
      // )} */}
    </>
  );
};

export default NotificationCards;
