import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../_helpers/FilterResponse";

import { customToastr } from "../../_Pages/_webapp/arya2.0/CustomToastr";

export const HomePageServices = {
  getCallBack,
  getPageDetailOfCMSby,
  getAllBuyLeadsDetailsList,
  homepageDashoardCount,
  displayWareHouse,
  getUserNotification,
  getUserReadNotification,
  postUserNotification,
};

async function getCallBack(data) {
  try {
    const response = await RestMethod.POST("/v2/callback-request", data);
    const api_response = FilterResponse(response);
    return api_response.data;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getUserNotification() {
  try {
    const response = await RestMethod.GET("/v2/user/notification");
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getUserReadNotification(data) {
  try {
    const response = await RestMethod.POST("/v2/read/user/notification", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function postUserNotification(data) {
  try {
    const response = await RestMethod.POST("/v2/user/notification", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getPageDetailOfCMSby(type, limit, offset, homeWidget = false) {
  var reuestlink = "/v2/content/news?";
  if (limit) {
    reuestlink = reuestlink + "limit=" + limit;
  }
  if (offset) {
    reuestlink = reuestlink + "&offset=" + offset;
  }
  try {
    const response = await RestMethod.GET(reuestlink);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllBuyLeadsDetailsList(
  offset,
  limit,
  search,
  type,
  homeWidget = false,
  searchlead = false
) {
  var url = "/v2/all-buyer-detail-list?";
  if (
    offset !== undefined &&
    limit !== undefined &&
    offset !== null &&
    limit !== null
  ) {
    url = url + "offset=" + offset + "&limit=" + limit;
  }

  if (search !== null) {
    url = url + search;
  }

  if (type) {
    url = url + "&type=" + type;
  }

  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    if (!homeWidget)
      customToastr({
        text: "Something went wrong. Please try again later",
        type: "error",
      });
    if (searchlead)
      customToastr({
        text: "Technical error. Please try later",
        type: "error",
      });
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function homepageDashoardCount() {
  try {
    const response = await RestMethod.GET("/v2/homepage");
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    customToastr({
      text: "Failed to load list in filter. Please try later",
      type: "warning",
    });
    // toastr.warning("Failed to load list in filter. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function displayWareHouse(offset, limit, searchdata, filters, pincode) {
  var url = "/v2/warehouse-search";

  if (offset !== undefined && limit !== undefined) {
    url = url + "?offset=" + offset + "&limit=" + limit;
  }

  if (searchdata !== null && searchdata?.location) {
    url = url + "&search=" + searchdata.location;
  } else if (pincode) {
    url = url + "&search=" + pincode;
  }

  if (searchdata?.comodity || searchdata?.commodity) {
    url =
      url +
      "&warehouse_commodity=" +
      (searchdata.comodity || searchdata?.commodity);
  }

  // if (searchdata && searchdata.location) {
  //     url = url + "&location=" + searchdata.location;
  // }
  if (searchdata && searchdata.capacity) {
    url = url + "&capacity=" + searchdata.capacity;
  }
  if (searchdata && searchdata.date) {
    url = url + "&date=" + searchdata.date;
  }

  if (searchdata && searchdata.arya) {
    url = url + "&arya_certified_fullfiled=" + searchdata.arya;
  }

  if (filters) {
    // url = url + "&login=1";
    url = url + filters;
  }

  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    //toastr.info('Request send  Successfull')
    return api_response;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    // toastr.error("Failed to load warehouses. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
}
