import React from "react";
import HeadLines from "../HeadLines";
import { dcrossIcon } from "../../icon_assets";

const BlurModalDesktopHeading = ({
  className = "",
  headingTextClassName = "",
  closeIconClassName = "d-close-icon cursorPointer",
  showCloseIcon = true,
  heading,
  showHeadingSepertor = true,
  hrClassName = "",
  onHide = () => null,
  crossIcon = dcrossIcon,
}) => {
  return (
    <div className={`dheading ${className}`}>
      <HeadLines className={headingTextClassName}>{heading}</HeadLines>
      {showCloseIcon && (
        <img
          src={crossIcon}
          alt=""
          className={closeIconClassName}
          onClick={() => onHide(false)}
        />
      )}
    </div>
  );
};

export default BlurModalDesktopHeading;
