import React, { useState } from "react";
import TwoLineWidget from "../CommonComponents/TwoLineWidget/TwoLineWidget";
import s from "./styles.module.css";
import { moreOptionsIcon } from "../icon_assets";
import ManageUserMenu from "./ManageUserMenu";
import AddUserModal from "./AddUserModal";
import DeleteUserModal from "./DeleteUserModal";

const CardComponent = ({ item, index }) => {
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleEditUser = () => {
    setShowEditUserModal(true);
  };

  const handleDeleteUser = () => {
    setShowDeleteUserModal(true);
  };

  return (
    <>
      <div className="positionRelative">
        {showDropdown && (
          <ManageUserMenu
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
            handleEditUser={handleEditUser}
            handleDeleteUser={handleDeleteUser}
          />
        )}
        {showEditUserModal && (
          <AddUserModal
            showModal={showEditUserModal}
            onHide={() => {
              setShowEditUserModal(false);
            }}
            editUser={true}
            editUserData={item}
          />
        )}
        {showDeleteUserModal && (
          <DeleteUserModal
            showModal={showDeleteUserModal}
            onHide={() => {
              setShowDeleteUserModal(false);
            }}
          />
        )}
        <div key={index}>
          <div className="d-flex margin-top12">
            <TwoLineWidget
              className="col5"
              title={item?.name}
              subtitle="Name"
              subtitleClassName={`${s["subtitle-2-line"]} `}
              titleClassName={`${s["title-2-line"]}`}
            />
            <TwoLineWidget
              title={item?.phoneNum}
              subtitle="Contact Number"
              subtitleClassName={`${s["subtitle-2-line"]} `}
              titleClassName={`${s["title-2-line"]}`}
            />
          </div>
          <div className="d-flex margin-top10">
            <TwoLineWidget
              className="col5"
              title={item?.role}
              subtitle="Role"
              subtitleClassName={`${s["subtitle-2-line"]} `}
              titleClassName={`${s["title-2-line"]}`}
            />
            <TwoLineWidget
              title={item?.permissions}
              subtitle="Permissions"
              subtitleClassName={`${s["subtitle-2-line"]} `}
              titleClassName={`${s["title-2-line"]}`}
            />
          </div>
        </div>

        <img
          src={moreOptionsIcon}
          onClick={() => setShowDropdown(!showDropdown)}
          className={`${s["dropdown-menu"]}`}
        />
        <hr className={`${s["hr-divider"]}`} />
      </div>
    </>
  );
};

export default CardComponent;
