// https://api-uat.arya.ag/api/find-district-and-state-by-pincode/700052

import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { customToastr } from "../../../_Pages/_webapp/arya2.0/CustomToastr";

const callApi = async (api, Method, data) => {
  try {
    const response = await RestMethod[Method](api, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const generateSignedUrl = async (data) => {
  return callApi(`/v2/get-signed-url`, "POST", data);
};
export const saveDocThroughDoms = async (data) => {
  return callApi(`/v2/save-doc-at-doms`, "POST", data);
};
export const downloadDocThroughDoms = async (data) => {
  return callApi(`/v2/download-doc-from-doms`, "POST", data);
};
export const generateContractPreviewPdf = async (data) => {
  return callApi(`/v2/generate-contract-preview-pdf`, "POST", data);
};
