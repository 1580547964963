import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../_helpers/FilterResponse";
import toastr from "toastr";

export const BuyerEnlistService = {
  addBuyerEnlist,
  getBuyerEnlist,
  getBuyerEnlistById,
  getURL,
  getAllBuyLeadsDetailsList,
  addBuyerEnquiry,
  getBuyerEnlistAdmin,
  getConnctedSeller,
  getBuyerEnlistByIdWithAuth,
  getAllBuyLeadList,
  leadDetails,
  sellerQuote,
  listedEntries,
  deleteRequirement,
  getMarketByCityAndCommodity,
  leadDeletion,
  ThankYouPageData,
};

async function deleteRequirement(data) {
  try {
    const response = await RestMethod.PUT("/delete-buyer-list", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    //toastr.info('Request send  Successfull')
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    return null;
  }
}

async function listedEntries(homeWidget = false) {
  try {
    const response = await RestMethod.GET("/ms-product");
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (!homeWidget) toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function addBuyerEnlist(data, buyerinlist = false) {
  try {
    const response = await RestMethod.POST("/buyer-details", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (buyerinlist) toastr.warning("Unexpected error. Please call 9015260260");
    else toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getBuyerEnlist(offset, limit, search) {
  var url = "/buyer-list?";
  if (
    offset !== undefined &&
    limit !== undefined &&
    offset !== null &&
    limit !== null
  ) {
    url = url + "offset=" + offset + "&limit=" + limit;
  }

  if (search !== null) {
    url = url + search;
  }

  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getBuyerEnlistAdmin(offset, limit, search) {
  var url = "/admin-buyer-detail-list?";
  if (
    offset !== undefined &&
    limit !== undefined &&
    offset !== null &&
    limit !== null
  ) {
    url = url + "offset=" + offset + "&limit=" + limit + "&";
  }

  if (search !== null) {
    url = url + search;
  }

  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getBuyerEnlistById(id, type) {
  var url = "/buyer-details/" + id;
  if (type) {
    url = url + "?type=" + type;
  }
  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getBuyerEnlistByIdWithAuth(id) {
  var url = "/buyer-lead-details/" + id;
  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getURL(id, buyinlist = false) {
  var url = "/short-url/" + id;
  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    if (!buyinlist) toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllBuyLeadsDetailsList(
  offset,
  limit,
  search,
  type,
  homeWidget = false,
  searchlead = false
) {
  var url = "/all-buyer-detail-list?";
  if (
    offset !== undefined &&
    limit !== undefined &&
    offset !== null &&
    limit !== null
  ) {
    url = url + "offset=" + offset + "&limit=" + limit;
  }

  if (search !== null) {
    url = url + search;
  }

  if (type) {
    url = url + "&type=" + type;
  }

  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    if (!homeWidget) toastr.error("Something Went Wrong");
    if (searchlead) toastr.error("Technical error. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function addBuyerEnquiry(id) {
  var url = "/add-buyer-enquiry/" + id;
  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getConnctedSeller(id, filter) {
  var url = "/buyer-enquiry-list/" + id + (filter ? filter : "");
  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function ThankYouPageData(data) {
  var url = "/thank-you-page-data" + data;
  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllBuyLeadList(type, offset, limit, search, urlParam) {
  var url = "/buy-lead" + urlParam;
  // if (offset !== undefined && limit !== undefined && offset !== null && limit !== null) {
  //     url = url + "offset=" + offset + "&limit=" + limit;
  // }

  // if (search !== null) {
  //     url = url + search
  // }

  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function leadDetails(id, type) {
  var url = "/buy-lead/details/" + id + "?type=" + type;
  try {
    const response = await RestMethod.GET(url);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function sellerQuote(data) {
  try {
    const response = await RestMethod.POST("/buy-lead/quotation", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getMarketByCityAndCommodity(data) {
  try {
    const response = await RestMethod.POST("/only-mandi-price", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function leadDeletion(data) {
  try {
    const response = await RestMethod.POST("/delete-buyer-list", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}
