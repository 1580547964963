export const useSideScrollSticky = (
  parent = "sticky-sidebar",
  child = "sidebar",
  defTop = 65,
  minuTop = 300
) => {
  return () => {
    let _ = document?.getElementsByClassName(child);
    let contentHeight = _?.[0]?.clientHeight;
    var topVal = defTop;
    if (contentHeight > minuTop) {
      topVal = -(contentHeight - minuTop);
    }
    let __ = document?.getElementsByClassName(parent)?.[0];
    if (__?.style) __.style.top = `${topVal}px`;
    // `top:${topVal}px`;
  };
};
