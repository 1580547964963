import React from "react";

import StickyFooter from "../../StickyFooter/StickyFooter";
import { useHistory } from "react-router";
import { isMobile } from "../../helperFunc";
import s from "./style.module.css";
import HeaderBackground from "../../CommonComponents/HeaderBackground";
import Header from "../../CommonComponents/Header";
import {
  backArrow,
  bankhollowIcon,
  blackRightArrowIcon,
  detailIcon,
  langIcon2,
  notifIcon,
  settingIcon,
  tradeDetailsIcon,
  userIcon,
} from "../../icon_assets";
import HeadLines from "../../CommonComponents/HeadLines";
const ManageUserAccountSetting = () => {
  const history = useHistory();
  const menuItems = [
    {
      text: "General Details",
      imgSrc: detailIcon,
      url: "/manage-profile/general-details",
    },
    {
      text: "Manage Users",
      imgSrc: userIcon,
      url: "/manage-profile/manage-users",
    },
    {
      text: "Commodity & Location Preference",
      imgSrc: settingIcon,
      url: "/manage-profile/commodity-preferences",
    },
    {
      text: "Language Preference",
      imgSrc: langIcon2,
      url: "/manage-profile/language-preferences",
    },
    {
      text: "Manage Notifications",
      imgSrc: notifIcon,
      url: "/manage-profile/notifications",
    },
    {
      text: "Bank Details",
      imgSrc: bankhollowIcon,
      url: "/manage-profile/bank-details",
    },
    {
      text: "Trade Details",
      imgSrc: tradeDetailsIcon,
      url: "/manage-profile/trade-details",
    },
  ];
  return (
    <>
      {isMobile && (
        <HeaderBackground
          className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 ${s["header-style"]}`}
        >
          <Header
            title="Account Settings"
            backArrow={backArrow}
            iconClassName={`${s["back-arrow"]} flex-center`}
            onSecondaryIconClick={() => null}
            onBackArrowClick={() => history.goBack()}
            titleClassName="font-weight600 font-size16 colorDark5 line-height19 letterSpacing015"
            className="padding-bottom16 padding-top20"
          />
        </HeaderBackground>
      )}
      <div className="padding16 stdFontStyle">
        {/* <CommodityLocationPreferences /> */}
        {/* <LangPreferences /> */}
        {/* <TradeDetail /> */}
        {/* <NotificationPreferences /> */}
        {/* <BankDetails /> */}
        {menuItems &&
          menuItems.length > 0 &&
          menuItems.map((menu, index) => {
            return (
              <div
                className={`lightBorder boxRadius8 ${s["card-style"]} d-flex align-items-center justify-content-between padding-top8 padding-bottom8 padding-right12 padding-left12 margin-bottom12`}
                onClick={() => history.push(menu.url)}
              >
                <div className={`d-flex align-items-center gap12`}>
                  <img
                    src={menu.imgSrc}
                    style={{ width: "20px", height: "20px" }}
                  />
                  <HeadLines
                    text={menu.text}
                    className={`font-weight400 font-size12 line-height14 colorSecondary5 letterSpacing025 `}
                  />
                </div>
                <img
                  src={blackRightArrowIcon}
                  style={{ width: "6px", height: "12px" }}
                />
              </div>
            );
          })}
      </div>
      {isMobile && <StickyFooter isActive="Profile" workEvenIfActive />}
    </>
  );
};
export default ManageUserAccountSetting;
