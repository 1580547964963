export const languageOptions = [
  {
    language: "English",
    lang_id: 1,
    title: "English",
    name: "english",
    enable: true,
    apiValue: "en",
  },
  {
    language: "हिन्दी",
    lang_id: 2,
    title: "Hindi",
    name: "hindi",
    enable: true,
    apiValue: "hi",
  },
  {
    language: "తెలుగు",
    lang_id: 3,
    title: "Telugu",
    name: "telugu",
    enable: true,
    apiValue: "te",
  },
  {
    language: "मराठी",
    lang_id: 4,
    title: "Marathi",
    name: "marahi",
    enable: true,
    apiValue: "mr",
  },
  {
    language: "ગુજરાતી",
    lang_id: 5,
    title: "Gujarati",
    name: "gujarati",
    enable: true,
    apiValue: "gu",
  },
];
