import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'

export const stockPaymentCheckout = {
    createPaymentOrder,
    successPaymentSave,
    paymentFail,
    myPayments,
    createPaymentOrderPayLoan,
    paymentPayLoanFail,
    successPaymentSavePayLoan,
    createPaymentStockOrder,
    successReleaseStockPaymentSave
}

async function createPaymentOrder(data) {
    try {
        const response = await RestMethod.POST("/pay-rent",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function createPaymentStockOrder(data) {
    try {
        const response = await RestMethod.POST("/pay-stock",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function successPaymentSave(data) {
    try {
        const response = await RestMethod.POST("/rent-signature",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function successReleaseStockPaymentSave(data) {
    try {
        const response = await RestMethod.POST("/stock-signature",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}



async function paymentFail(data) {
    try {
        const response = await RestMethod.POST("/update-fail",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function myPayments() {
    try {
        const response = await RestMethod.GET("/my-payment");
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}






async function createPaymentOrderPayLoan(data) {
    try {
        const response = await RestMethod.POST("/pay-loan",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function paymentPayLoanFail(data) {
    try {
        const response = await RestMethod.POST("/update-loan-fail",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function successPaymentSavePayLoan(data) {
    try {
        const response = await RestMethod.POST("/loan-signature",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}
