import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import BlurModal from "../../CommonComponents/BlurModal/BlurModal";
import BlurModalContent from "../../CommonComponents/BlurModal/BlurModalContent";
import { isMobile } from "../../helperFunc";
import BlurModalHeading from "../../CommonComponents/BlurModal/BlurModalHeading";
import HeadLines from "../../CommonComponents/HeadLines";
import { circleCheck } from "../../icon_assets";
const GetHelpPopUp = ({ showModal, onHide, setFormValue }) => {
  useEffect(() => {
    setFormValue();
  }, []);

  return (
    <div className="stdFontStyle">
      <BlurModal
        show={showModal}
        onHide={() => onHide()}
        showDesktopHeader={!isMobile}
        desktopHeading={"Get Help in Verification"}
      >
        <BlurModalContent>
          <div
            className={`d-flex justify-content-center align-items-center ${
              isMobile
                ? "margin-top30 margin-bottom16"
                : "margin-top40 margin-bottom40"
            } flex-column`}
          >
            <img
              className={style["success-faild-icon"]}
              src={circleCheck}
              alt=""
              srcset=""
            />
            <HeadLines
              className={`${
                isMobile
                  ? "margin-top16 font-weight500 font-size14 line-height16"
                  : "margin-top24 font-weight600 font-size18 line-height21 "
              }   textColorBlack`}
              text="Thank You "
            />
            <HeadLines
              className={`${
                isMobile
                  ? "margin-top16 font-size14 line-height16"
                  : "margin-top24 font-size18 line-height21"
              }   colorDark9 font-weight400`}
              text="Your details have been shared with internal team."
            />
            <HeadLines
              className={`${
                isMobile
                  ? "font-size14 line-height16"
                  : "font-size18 line-height21"
              }   colorDark9 font-weight400`}
              text="They will get in touch with you soon."
            />
          </div>
        </BlurModalContent>
      </BlurModal>
    </div>
  );
};
export default GetHelpPopUp;
