import { useEffect, useState } from "react";
import axios from "axios";

const useUserIP = () => {
  const localIP = localStorage.getItem("IP");
  const [ip, setIP] = useState(localIP || "");

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    if (res?.data?.ip) {
      localStorage.setItem("IP", res.data.ip);
      setIP(res.data.ip);
    }
  };
  useEffect(() => {
    if (!localIP) getData();
  }, []);

  return ip;
};

export default useUserIP;
