import React from "react";
import MultiLanguageTranslation from "../../../../_components/Translate/MultiLangTranslate";
const HeadLinesSpan = ({ className, text, style, onClick }) => {
  return (
    <span onClick={onClick ?? null} className={className} style={style}>
      {text && typeof text === "string" ? (
        <MultiLanguageTranslation title={text} />
      ) : (
        text
      )}
    </span>
  );
};
export default HeadLinesSpan;
