import React from "react";
import s from "./styles.module.css";
const CustomCheckBox = (props) => {
  const { id = "test", className, inputProps } = props;
  return (
    <>
      <label
        htmlFor={id}
        className={`${s["custom-checkbox"]}  ${className} cursorPointer`}
      >
        <input name={id} id={id} type={"checkbox"} {...inputProps} />
        <span className={`checkbox`} />
      </label>
    </>
  );
};

export default CustomCheckBox;
