// https://api-uat.arya.ag/api/find-district-and-state-by-pincode/700052

import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { customToastr } from "../../../_Pages/_webapp/arya2.0/CustomToastr";

const callApi = async (api, Method, data, isRow = false) => {
  try {
    const response = await RestMethod[Method](api, data);
    if (isRow) return response.data;
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getCityStateFromPincode = async (urlParamString) => {
  return callApi(
    `/v2/find-district-and-state-by-pincode${
      urlParamString ? urlParamString : ""
    }`,
    "GET"
  );
};
export const getCommodityList = async (urlParamString) => {
  return callApi(
    `/v2/commodity/list${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getStateList = async (urlParamString) => {
  return callApi(
    `/v2/state/list${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getCityList = async (urlParamString) => {
  return callApi(`/v2/city/list${urlParamString ? urlParamString : ""}`, "GET");
};

export const getWarehouseList = async (urlParamString) => {
  return callApi(
    `/v2/warehouse/list${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getRange = async (urlParamString) => {
  return callApi(`v2/lead/range${urlParamString ? urlParamString : ""}`, "GET");
};

export const aryaProStock = async (urlParamString) => {
  return callApi(
    `/v2/arya-pro-stock${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};
export const LACFormApi = async (data) => {
  return callApi(`/v2/finance/loan-request`, "POST", data, true);
};
export const getQualityParams = async (commodity) => {
  return callApi(`/v2/commodity/${commodity}/quality_count`, "GET");
};
export const getSubsrcibeNewsLetter = async (data) => {
  return callApi(`/v2/subscriber`, "POST", data);
};
