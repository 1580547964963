import React from "react";
import { isMobile } from "../../helperFunc";
import Step from "./Step";
import s from "./styles.module.css";
const ProgressTab = (props) => {
  const {
    currentStep = 1,
    totalSteps,
    data,
    className,
    completedClassName = "",
    currentClassName = "",
    titleClassName = "",
    isWithInColumn = false,
    createAuction = false,
    outsiderClassName = ""
  } = props;
  const getStepClassName = (currentIndex) => {
    if (currentIndex === currentStep) return currentClassName;
    if (currentIndex < currentStep) return completedClassName;
    return titleClassName;
  };
  return (
    <div className={`${s["progress-tab"]} ${className}`}>
      <div
        className={`d-flex justify-content-space-between ${outsiderClassName} ${
          isMobile
            ? ""
            : isWithInColumn
            ? ""
            : createAuction
            ? s["width-40"]
            : "d-width-2-col"
        }`}
      >
        {data?.map((step, index) => {
          return (
            <Step
              key={index.toString()}
              isCurrent={index + 1 === currentStep}
              completed={index + 1 < currentStep}
              isFirst={index === 0}
              isLast={index + 1 === totalSteps}
              stepNumber={step.stepNumber}
              stepText={step.stepText}
              titleClassName={getStepClassName(index + 1)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProgressTab;
