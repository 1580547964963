import React, { useEffect } from "react";
import "../commonStyle.css";
import toastr from "toastr";

const HeaderBackground = (props) => {
  const {
    children,
    className = "borderRadiusBottom12 padding-left21 padding-right19 linear-gradient2 ",
  } = props;
  useEffect(() => {
    toastr.options.preventDuplicates = true;
  }, []);
  return <div className={className}>{children}</div>;
};

export default HeaderBackground;
