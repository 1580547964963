import React from "react";
import styles from "./styles.module.css";
const LoaderCircle = (props) => {
  const {
    circleColor = "#85C5B6",
    circleThickness = 5,
    spinnerClassName = "",
    r = "20",
  } = props;
  return (
    <svg className={`${styles["spinner"]} ${spinnerClassName}`} viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r={r}
        stroke={circleColor}
        fill="none"
        stroke-width={circleThickness}
      ></circle>
    </svg>
  );
};

export default LoaderCircle;
