import React, { useState } from "react";
import BlurModal from "../../CommonComponents/BlurModal/BlurModal";
import whiteLanguageIcon from "./../../DesktopImages/Home/whiteLanguageIcon.svg";
import SelectLangContent from "../../Profile/SelectLangContent";
import { isMobile } from "../../helperFunc";
import { useTranslation } from "react-i18next";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
const Widgets = () => {
  const [show, setShow] = useState(false);
  const { t: translation } = useTranslation();
  return (
    <div className="align-items-center d-flex gap24 font-size14 letterSpacing05 line-height16 colorDark6">
      <div>
        <HeadLinesSpan
          text={translation("Call Us")}
          className="textColorWhite font-weight400"
        />
        &nbsp;&nbsp;
        <a className="textColorWhite" href={"tel:+91 9051260260"}>
          <u className="textColorWhite font-weight600">+91-9015260260</u>
        </a>
      </div>
      <img
        src={whiteLanguageIcon}
        className="cursorPointer"
        alt=""
        onClick={() => {
          setShow(true);
        }}
      />
      <BlurModal
        show={show}
        onHide={() => setShow(false)}
        showDesktopHeader={!isMobile}
        desktopHeading="Select Language"
      >
        <SelectLangContent />
      </BlurModal>
    </div>
  );
};
export default Widgets;
