import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import DesktopHeader from "../DesktopCommonComponents/DesktopHeader/DesktopHeader";
import { isMobile } from "../helperFunc";
import BreadcrumbUI from "../DesktopCommonComponents/Breadcrumb/BreadcrumbUI";
import HeaderBackground from "../CommonComponents/HeaderBackground";
import Header from "../CommonComponents/Header";
import StickyFooter from "../StickyFooter/StickyFooter";
import DesktopFooter from "../DesktopCommonComponents/DesktopFooter/DesktopFooter";
import { backArrow } from "../icon_assets";
import AccountMenu from "./AccountMenu";

const AccountController = (props) => {
  const [itemList, setItemList] = useState([
    { text: "My Profile", url: "/my-profile" },
    { text: "Account Settings", url: "" },
  ]);

  useEffect(() => {
    document.body?.classList.add("padding-top-unset");
  }, []);
  const history = useHistory();

  return (
    <div className="stdFontStyle font-size16 letterSpacing015  line-height19 ">
      {!isMobile && <DesktopHeader isActive={"Storage"} />}
      {!isMobile ? (
        <BreadcrumbUI
          items={itemList}
          onBackArrowClick={() => history.goBack()}
        />
      ) : (
        <HeaderBackground
          className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 `}
        >
          <Header
            title={"Manage Users"}
            backArrow={backArrow}
            className={"text-dark"}
            className2={
              " mtb-auto font-weight400  font-size12  line-height14  letterSpacing025"
            }
            onBackArrowClick={() => history.goBack()}
            onSecondaryIconClick={() => null}
          />
        </HeaderBackground>
      )}
      <AccountMenu />
      {isMobile ? (
        <StickyFooter isActive="Storage" showBackToTopWidget={false} />
      ) : (
        <DesktopFooter />
      )}
    </div>
  );
};
export default AccountController;
