import React from "react";
import style from "./style.module.css";
import { errorInfoIcon } from "../icon_assets";
import { isMobile } from "../helperFunc";
import HeadLines from "./HeadLines";
import Title from "./InputFieldWithTitle/Title";
const InputMobileNumber = ({
  className1,
  className2,
  placeholder = "",
  value,
  onChange,
  errorClassName,
  name,
  showError,
  disable = false,
  inputRef = null,
  onkeydown = () => null,
  title = "",
  titleClassName = "",
  compulsoryStarClassName = "",
  isCompulsory = "",
  className3 = "",
  searchClicked = () => {},
  rightIcon = false,
  showCountryCode = true,
}) => {
  return (
    <div className="positionRelative">
      {title && (
        <Title
          titleClassName={titleClassName}
          title={title}
          isCompulsory={isCompulsory}
          compulsoryStarClassName={`asterisk ${compulsoryStarClassName}`}
        />
      )}
      <div className="positionRelative">
        {showCountryCode && (
          <div className={`positionAbsolute ${className2}`}>+91</div>
        )}
        <input
          className={`${className1} overWriteInputButtonCSS`}
          placeholder={placeholder}
          type="tel"
          value={value}
          onChange={onChange}
          name={name}
          autoComplete="off"
          disabled={disable}
          ref={inputRef}
          // on="abc()"
          onKeyUp={onkeydown}
        />
        {rightIcon && (
          <HeadLines
            text="SEARCH"
            className={`positionAbsolute ${className3}`}
            onClick={value ? searchClicked : ""}
          />
        )}
      </div>
      {showError ? (
        <div
          className={`d-flex align-items-center padding-top4  input-error-style ${errorClassName}`}
        >
          {errorInfoIcon && (
            <img
              src={errorInfoIcon}
              className={`${style["errorCSS"]} ${
                style["error-icon-style"]
              } display-inline-block margin-right4 ${
                isMobile ? "" : style["width18px"]
              }`}
              alt=""
            />
          )}
          <HeadLines
            className={`${
              style["errorCSS"]
            } font-size12 display-inline-block   ${
              isMobile ? "" : style["desktopCssImp"]
            }`}
            text={
              showError
              // <>
              //   {errorIcon && (
              //     <img
              //       src={errorIcon}
              //       className={`${errorIconClassName}`}
              //       alt=""
              //     />
              //   )}
              //   {showError}
              // </>
            }
          ></HeadLines>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default InputMobileNumber;
