import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import HeadLines from "../../../../CommonComponents/HeadLines";
import "../../../../commonStyle.css";

const SideNavSelect = ({
  sideNavitems = [],
  titleClassName,
  imageClassName,
  scrollOnActive,
  cardClassName = style["card-style"],
  cardItemStyle = `padding12 ${style["card-item-style"]}`,
  HeadlineText,
  HeadingClassName = `padding-left16 padding-right16 padding-bottom16`
}) => {
  const [selectedItem, setSelectedItem] = useState(0);
  useEffect(() => {
    if (scrollOnActive) {
      setSelectedItem(scrollOnActive);
    }
    if (scrollOnActive === 0) {
      setSelectedItem(0);
    }
  }, [scrollOnActive]);
  return (
    <div className={`${cardClassName} ${style["card-default"]}`}>
      {HeadlineText && <HeadLines className={HeadingClassName} text={HeadlineText}></HeadLines>}
      {sideNavitems &&
        sideNavitems.length > 0 &&
        sideNavitems.map((item, index) => {
          return (
            <div
              className={`${cardItemStyle} ${
                selectedItem === index && style["selected-bg"]
              }  cursor-pointer`}
              key={index}
              onClick={() => {
                item?.onClick();
              }}
            >
              <div className={`d-flex align-items-center gap8`}>
                <img className={imageClassName} src={item.imgSrc} />
                <HeadLines
                  text={item?.text}
                  className={`${titleClassName} ${
                    selectedItem === index && "font-weight500"
                  }`}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default SideNavSelect;
