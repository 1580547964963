import { useSelector } from "react-redux";

const useUserLanguage = (props) => {
  const userLanguage = useSelector(
    (globalState) => globalState?.userLanguageReducer
  );
  return Object.keys(userLanguage).length === 0 ? {} : userLanguage;
};

export default useUserLanguage;
