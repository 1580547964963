import { encryptData } from "../../_Pages/_webapp/arya2.0/helperFunc";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../_helpers/FilterResponse";
import toastr from "toastr";

export const CommonApiService = {
  getEnlistWarehouse,
  getStatesOfCountry,
  getCityOfState,
  getSubDistrict,
  loginUserDetails,
  loginUserDetailsNewApi,
  updateUser,
  updateAuthUser,
  userByID,
  updateKyc,
  leadSignup,
  updateChildUser,
  getAllGST,
  getDetailsByGST,
  getAllCity,
  updatgePrefreredData,
  getPrefreredData,
  getAddressByPincode,
  userType,
  rmsCheckStatus,
  getRmstoken,
  commodityList,
  getCityStateByPincodeDTR,
  saveSignatureData,
  verifyTan,
};

/**
 *
 * @Tapas
 * For Getting all enlist warehouse
 */
async function getEnlistWarehouse() {
  try {
    const response = await RestMethod.GET("/usersk");
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    //toastr.info('Are you the 6 fingered man?')
    toastr.error("error detected while fetching data from api");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getStatesOfCountry(buyinlist = false) {
  try {
    const response = await RestMethod.GET("/state");
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    if (!buyinlist) toastr.error("error detected while fetching data from api");
    else toastr.error("Technical error. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllCity() {
  try {
    const response = await RestMethod.GET("/all-city");
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    toastr.error("error detected while fetching data from api");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getCityOfState(id, buyinlist = false) {
  try {
    const response = await RestMethod.GET("/city/" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    if (buyinlist) toastr.error("Technical error. Please try later");
    else toastr.error("error detected while fetching data from api");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getSubDistrict(id, accdetails = false) {
  try {
    const response = await RestMethod.GET("sub-district/" + id);
    const api_response = FilterResponse(response);
    return api_response;
  } catch (error) {
    if (!accdetails)
      toastr.error("error detected while fetching data from api");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function loginUserDetails() {
  try {
    const response = await RestMethod.GET("/user-details");
    const api_response = FilterResponse(response);
    return api_response.data;
  } catch (error) {
    toastr.error("error detected while fetching data from api");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function loginUserDetailsNewApi(accdetails = false) {
  try {
    const response = await RestMethod.GET("/user-details-new");
    const api_response = FilterResponse(response);
    return api_response.data;
  } catch (error) {
    if (accdetails) toastr.warning("User data not found");
    else toastr.error("error detected while fetching data from api");

    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updateAuthUser(userData, accdetails = false) {
  try {
    const response = await RestMethod.POST("/update-auth-user", userData);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    if (accdetails) toastr.error("Please call 9015260260", "Unexpected error");
    else toastr.error("Something Went Wrong", error);
    return null;
  }
}

async function updateUser(userData, accdetails = false) {
  try {
    const response = await RestMethod.POST("/update-all-user", userData);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    if (accdetails) toastr.error("Please call 9015260260", "Unexpected error");
    else toastr.error("Something Went Wrong", error);
    return null;
  }
}

async function updateChildUser(userData) {
  try {
    const response = await RestMethod.POST("/update-user", userData);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    toastr.error("Something Went Wrong", error);
    return null;
  }
}

async function userByID(id, accdetails = false) {
  try {
    const response = await RestMethod.GET("/user-by-id/" + id);
    const api_response = FilterResponse(response);
    return api_response.data;
  } catch (error) {
    if (accdetails) toastr.warning("User data not found");
    else toastr.error("error detected while fetching data from api");
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updateKyc(userData) {
  try {
    const response = await RestMethod.POST("/update-kyc", userData);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong", error);
    return null;
  }
}

async function leadSignup(userData) {
  try {
    const response = await RestMethod.POST("/lead-signup", userData);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong", error);
    return null;
  }
}

async function getAllGST(data) {
  try {
    const response = await RestMethod.POST("/get-pan", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong", error);
    return null;
  }
}

async function getDetailsByGST(data) {
  try {
    const response = await RestMethod.POST("/gst-detail", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updatgePrefreredData(data, id, accdetails = false) {
  try {
    const response = await RestMethod.PUT(
      "/update-preferred-service/" + id,
      data
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      if (accdetails)
        toastr.error("Please call 9015260260", "Unexpected error");
      else toastr.error("Something Went Wrong", error);
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getPrefreredData(id, buyinlist = false) {
  try {
    const response = await RestMethod.GET("/get-preferred-service/" + id);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420 && !buyinlist) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAddressByPincode(pin, buyinlist = false) {
  try {
    const response = await RestMethod.GET(
      "/find-district-and-state-by-pincode/" + pin
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      if (buyinlist) toastr.warning("No data found");
      else
        toastr.error(
          "Failed to fetch city and state from pincode, Please try later"
        );
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function userType(data) {
  try {
    const response = await RestMethod.POST("/update-user-type", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function rmsCheckStatus(data) {
  try {
    const response = await RestMethod.GET(
      "https://rms.arya.ag?token=DgMfqpcIJfBgzgjllfWNNH5WN9CJaSD4IRY0Z8QoSyjOPBEl8e5fdoC4lerqvBBf",
      data
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getRmstoken() {
  try {
    const response = await RestMethod.GET("/rms-sync");
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.error("Something Went Wrong");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function commodityList() {
  try {
    const response = await RestMethod.GET("/commodity-list");
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      toastr.warning("Failed to load list in filter. Please try later");
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getCityStateByPincodeDTR(pin, buyinlist = false) {
  try {
    const response = await RestMethod.GET(
      "/dtr/find-city-state-by-pincode/" + pin
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      if (buyinlist) toastr.warning("No data found");
      else
        toastr.error(
          "Failed to fetch city and state from pincode, Please try later"
        );
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function saveSignatureData(data) {
  try {
    const response = await RestMethod.POST(
      "/verify-siganture-dtr-payment",
      data
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function verifyTan(data) {
  try {
    data = { ...data, tan: encryptData(data?.tan) };
    const response = await RestMethod.POST("/v2/verify-tan", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
