import React from "react";
import HeadLines from "../HeadLines";
import s from "./styles.module.css";
import "../../commonStyle.css";
import { isMobile } from "../../helperFunc";
const SelectButton = (props) => {
  const { icon, onChange, title, htmlFor } = props;
  return (
    <label
      htmlFor={htmlFor}
      className={`${s["def-style"]}`}
      onChange={onChange}
    >
      <div className={`${s["margin-bottom12"]}`}>
        <img src={icon} alt="" width={24} height={24} />
      </div>

      <HeadLines
        className={`${s["txt-uppercase"]} 
        ${isMobile ? `` : `letterSpacing004`} 
        font-size12 font-weight500 colorAccent2 line-height14`}
        text={title}
      />
    </label>
  );
};

export default SelectButton;
