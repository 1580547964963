import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'
import { URL_CONFIG } from '../../_constants/Config/URL_CONFIG'
export const loanServices = {
    myLoans,
    myRentHistory,
    pdfDownload
}

async function myLoans() {
    try {
        const response = await RestMethod.GET("/my-loan-request");
        const api_response = FilterResponse(response);
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function myRentHistory(limit, offset,query) {
    let pages = ""
    if (limit) {
        pages = "&limit=" + limit + "&offset=" + offset
    }
    try {
        const response = await RestMethod.GET("/my-rent-history?type=2" + pages+query);
        const api_response = FilterResponse(response);
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}


async function pdfDownload(data, name, Isloader) {
    try {
        const token = localStorage.getItem('authToken');
        fetch(URL_CONFIG.API_URL + 'get-pdf-private', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": token ? `Bearer ${token}` : '',
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 500) {
                    toastr.warning('File Error')
                    return;
                }
                return response.blob()
            })
            .then(blob => {
                return URL.createObjectURL(blob)
            })
            .then(uril => {
                Isloader && Isloader(false)
                var link = document.createElement("a");
                link.href = uril;
                link.download = (name ? name : "file") + ".pdf";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    }
    catch (error) {
        Isloader && Isloader(false)
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}