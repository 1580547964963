import { FIRST_CONTENTFUL_PAINT, LARGEST_CONTENTFUL_PAINT } from "./constants";
import { eventTracker } from "./eventTracker";

export const calculateFCP = ({ name, delta, value, id }) => {
  eventTracker("event", FIRST_CONTENTFUL_PAINT, {
    fcp: Math.floor(value),
  });
};

export const calculateLCP = ({ name, delta, value, id }) => {
  eventTracker("event", LARGEST_CONTENTFUL_PAINT, {
    lcp: Math.floor(value),
  });
};

export function getLastSixDigits(input) {
  if (!input) return;

  const inputStr = String(input);

  // Get the last 6 digits by slicing the string
  const lastSixDigits = inputStr.slice(-6);
  return lastSixDigits;
}
