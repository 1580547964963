import React from "react";
import Button from "../CommonComponents/Button";
import HeadLines from "../CommonComponents/HeadLines";
import Switch from "../CommonComponents/Switch/Switch";
import { blackInfoIcon, errorInfoIcon } from "../icon_assets";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import s from "./styles.module.css";
import useUserDetails from "../CustomHooks/useUserDetails";
import { isMobile } from "../helperFunc";
import ErrorMessage from "../CommonComponents/ErrorMessage/ErrorMessage";

const AccountSettingForm = (props) => {
  const {
    onVerifyClick,
    disableSave = true,
    email,
    pincode,
    state,
    city,
    handleEmailChange,
    handlePincode,
    rmName,
    isValidEmailRegex,
    hideDetails,
    permanantAddressLine1,
    permanantAddressLine2,
    handleSwitchChange,
    handleAddressLine1Change,
    handleAddressLine2Change,
    onProfileUpdate,
    accountSettingFormErrors,
    isEmailVerified,
    errorMessage,
  } = props;

  const userDetail = useUserDetails();
  return (
    <>
      <InputFieldWithTitle
        titleClassName={`${s["input-title1"]}`}
        title={"PAN"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        value={userDetail?.pan_number}
      />
      <InputFieldWithTitle
        titleClassName={`${s["input-title1"]}`}
        title={"GST"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        value={userDetail?.gstin_number}
        errorIcon={errorInfoIcon}
      />
      <InputFieldWithTitle
        className={isMobile ? undefined : s["imp-mb24"]}
        titleClassName={`${s["input-title1"]}`}
        title={"TAN"}
        disabled={true}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        value={userDetail?.tan}
      />

      <InputFieldWithTitle
        showVerifyText={
          userDetail?.email_verified === 0 || userDetail?.email !== email
            ? true
            : false
        }
        titleClassName={`${s["input-title1"]}`}
        title={"E-Mail ID"}
        disabled={false}
        inputClassName={`${s["input-style"]} ${
          userDetail?.email_verified === 1 &&
          userDetail?.email === email &&
          s["input-email-verified"]
        }`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={accountSettingFormErrors?.email ?? ""}
        errorIcon={errorInfoIcon}
        showVerifiedCheckIcon={
          userDetail?.email === email && userDetail?.email_verified === 1
            ? true
            : false
        }
        verifiedClassName={s["input-verified"]}
        showVerifyTextClassName={email === "" ? s["disable-send-link"] : ""}
        value={email}
        onChange={handleEmailChange}
        verifyClicked={onVerifyClick}
        verifyIconClassName={s["verify-check-icon"]}
        showInfotext={"A verification link will be sent to your email"}
        showInfotextClassName={"colorAccent5"}
        verifyText="SEND LINK"
      />
      <InputFieldWithTitle
        showVerifyText={false}
        titleClassName={`${s["input-title1"]}`}
        title={"Phone Number"}
        disabled={false}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        isVerified={true}
        verifiedClassName={s["input-verified"]}
        showVerifiedCheckIcon={true}
        value={userDetail?.phone_number || userDetail?.phone_number}
      />
      <InputFieldWithTitle
        showVerifyText={false}
        titleClassName={`${s["input-title1"]}`}
        title={"Pincode"}
        disabled={false}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={accountSettingFormErrors.pincode}
        errorIcon={errorInfoIcon}
        isVerified={false}
        verifiedClassName={s["input-verified"]}
        showVerifiedCheckIcon={false}
        value={pincode}
        type="number"
        maxLength={6}
        onChange={handlePincode}
        className={`${s["margin-bottom21"]}`}
      />
      <div className={`d-flex ${isMobile ? `gap8` : `gap20`}`}>
        <InputFieldWithTitle
          className={isMobile ? "flex100" : "flex100"}
          showVerifyText={false}
          titleClassName={`${s["input-title1"]}`}
          title={"City / District"}
          disabled={true}
          inputClassName={`${s["input-style"]}`}
          disableInputClassName={`${s["input-style-disable"]}`}
          errorInputClassName={`${s["input-style-error"]}`}
          errorClassName={`${s["input-error-style"]}`}
          showError={accountSettingFormErrors.city}
          errorIcon={errorInfoIcon}
          isVerified={false}
          verifiedClassName={s["input-verified"]}
          showVerifiedCheckIcon={false}
          value={city.name}
        />
        <InputFieldWithTitle
          className={isMobile ? "flex100" : "flex100"}
          showVerifyText={false}
          titleClassName={`${s["input-title1"]}`}
          title={"State"}
          disabled={true}
          inputClassName={`${s["input-style"]}`}
          disableInputClassName={`${s["input-style-disable"]}`}
          errorInputClassName={`${s["input-style-error"]}`}
          errorClassName={`${s["input-error-style"]}`}
          showError={accountSettingFormErrors.state}
          errorIcon={errorInfoIcon}
          isVerified={false}
          verifiedClassName={s["input-verified"]}
          showVerifiedCheckIcon={false}
          value={state.name}
        />
      </div>
      <InputFieldWithTitle
        showVerifyText={false}
        // className={`${s["margin-bottom6"]}`}
        titleClassName={`${s["input-title1"]}`}
        title={"Permanent Address"}
        disabled={false}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        isVerified={false}
        verifiedClassName={s["input-verified"]}
        showVerifiedCheckIcon={false}
        value={permanantAddressLine1}
        onChange={handleAddressLine1Change}
        className={`${s["margin-bottom24"]}`}
      />
      {/* <InputFieldWithTitle
        showVerifyText={false}
        titleClassName={`${s["input-title1"]}`}
        title={""}
        disabled={false}
        inputClassName={`${s["input-style"]}`}
        disableInputClassName={`${s["input-style-disable"]}`}
        errorInputClassName={`${s["input-style-error"]}`}
        errorClassName={`${s["input-error-style"]}`}
        showError={""}
        errorIcon={errorInfoIcon}
        isVerified={false}
        verifiedClassName={s["input-verified"]}
        showVerifiedCheckIcon={false}
        value={permanantAddressLine2}
        onChange={handleAddressLine2Change}
        className={`${s["margin-bottom24"]}`}
      /> */}
      {rmName && (
        <InputFieldWithTitle
          showVerifyText={false}
          titleClassName={`${s["input-title1"]}`}
          title={"RM Name"}
          disabled={true}
          inputClassName={`${s["input-style"]}`}
          disableInputClassName={`${s["input-style-disable"]}`}
          errorInputClassName={`${s["input-style-error"]}`}
          errorClassName={`${s["input-error-style"]}`}
          showError={""}
          errorIcon={errorInfoIcon}
          isVerified={false}
          verifiedClassName={s["input-verified"]}
          showVerifiedCheckIcon={false}
          value={rmName}
          className={`${s["margin-bottom24"]}`}
        />
      )}
      {/* <HeadLines
        className={`${s["input-title1"]}`}
        text={"Do you want to proceed as a"}
      />
      <div className="d-flex flex-column gap6 margin-bottom16">
        <ProcessAsWidget
          className
          checkboxClassName
          text="Buyer"
          id="test1"
          checked={buyerChecked}
          onChange={buyerOnChange}
          disabled
        />
        <ProcessAsWidget
          className
          checkboxClassName
          text="Seller"
          id="test2"
          checked={sellerChecked}
          onChange={sellerOnChange}
          disabled
        />
      </div>
      <HeadLines
        className={`${s["input-title1"]}`}
        text={"Select your Trade Preferences"}
      />
      <Card className={`${s["card-style"]} `}>
        <HeadLines className={`${s["title2"]}`} text={"Locations"} />
        <CustomReactSelect
          // styles={customStyles}
          className={"HelloWorld"}
          options={options}
          isMulti={true}
        />
        <HeadLines
          className={`margin-top12 ${s["title2"]}`}
          text={"Commodity"}
        />
        <CustomReactSelect
          // styles={customStyles}
          className={"HelloWorld"}
          options={options}
          isMulti={true}
        />
      </Card> */}
      {errorMessage && (
        <div className={"margin-bottom24"}>
          <ErrorMessage text={errorMessage} />
        </div>
      )}
      <div className={isMobile ? `` : `w-50 mx-auto margin-bottom40`}>
        <Button
          className={`cta-btn`}
          disabled={disableSave}
          title="Save"
          onClick={onProfileUpdate}
        />
      </div>
    </>
  );
};

export default AccountSettingForm;
