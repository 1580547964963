import React, { useState } from "react";
import { useHistory } from "react-router";
import { isMobile } from "../../helperFunc";
import {
  Graph,
  Wheat,
  backArrow,
  carbonTime,
  linkBlack,
  locationGreyIcon2,
} from "../../icon_assets";
import DesktopFooter from "../../DesktopCommonComponents/DesktopFooter/DesktopFooter";
import StickyFooter from "../../StickyFooter/StickyFooter";
import HeaderBackground from "../../CommonComponents/HeaderBackground";
import Header from "../../CommonComponents/Header";
import BreadcrumbUI from "../../DesktopCommonComponents/Breadcrumb/BreadcrumbUI";
import DesktopHeader from "../../DesktopCommonComponents/DesktopHeader/DesktopHeader";
import {
  priceTrendCrumbItems,
  PriceSideBarData,
  PriceDescription,
  CanData,
} from "../constant";
import Loader from "../../CommonComponents/Loader/Loader";
import HeadLines from "../../CommonComponents/HeadLines";
import PriceSideBar from "./PriceSideBar";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import style from "./style.module.css";
import Button from "../../CommonComponents/Button";
import MultiLineChart from "./MultiLineChart";
import FilterByDate from "./FilterByDate";

const PriceTrend = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  return (
    <>
      {!isMobile ? (
        <>
          <DesktopHeader isActive="Storage" />
          <BreadcrumbUI
            onBackArrowClick={() => history.goBack()}
            items={priceTrendCrumbItems}
          />
        </>
      ) : (
        <HeaderBackground
          className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 `}
        >
          <Header
            title="Disbursement Detail"
            subtitle="Pre-approved"
            className={"text-dark "}
            titleClassName="colorDark5 font-size16 line-height19"
            topPaddingClassName="padding-top12 padding-bottom12"
            subtitleClassName="font-weight400  font-size12  line-height14  letterSpacing025 colorDark12"
            backArrow={backArrow}
            className2={
              " mtb-auto font-weight400  font-size12  line-height14  letterSpacing025"
            }
            onSecondaryIconClick={() => null}
            onBackArrowClick={() => history.goBack()}
          />
        </HeaderBackground>
      )}
      <Loader isLoading={isLoading} />
      <div className="w-100 d-flex justify-content-center stdFontStyle margin-top24">
        <div className={!isMobile && "d-width-2-col row m-0 p-0"}>
          <div className={!isMobile && `col-8 m-0`}>
            <div>
              <img src={Wheat} width={"100%"} />
              <div className="margin-top16 d-flex justify-content-space-between">
                <div>
                  <div className="margin-bottom8 font-weight500 line-height24 font-size20 letterSpacing01 colorDark6">
                    {PriceDescription.commodity}
                  </div>
                  <div className="colorDark7 margin-bottom8">
                    <img
                      src={locationGreyIcon2}
                      width="18px"
                      height="18px"
                      className={style["img-top2"]}
                    />
                    <HeadLinesSpan
                      text={PriceDescription.location}
                      className={
                        "margin-left4 letterSpacing025 font-weight400 font-size16 line-height19"
                      }
                    />
                  </div>
                  <div className="font-weight400 line-height19 font-size16 letterSpacing05">
                    <span className="textColorSuccess">
                      {PriceDescription.growth_rate}
                    </span>
                    <span className="textColorSuccess">
                      {", " + PriceDescription.money}
                    </span>
                    <span>{" since " + PriceDescription.last_checked}</span>
                  </div>
                </div>
                <div>
                  <div className="text-align-right margin-bottom8 font-weight500 line-height24 font-size20 letterSpacing01 colorDark6">
                    {PriceDescription.rate + "/MT"}
                  </div>
                  <div className="colorDark7 margin-bottom8">
                    <img
                      src={carbonTime}
                      width="18px"
                      height="18px"
                      className={style["img-top2"]}
                    />
                    <HeadLinesSpan
                      text={"last updated: " + PriceDescription.last_updated}
                      className={
                        "margin-left4 letterSpacing025 font-weight400 font-size16 line-height19"
                      }
                    />
                  </div>
                  <div className="text-align-right">
                    <img src={linkBlack} width="24px" height="24px" />
                  </div>
                </div>
              </div>
              <div className="margin-top16 desk_card_border boxRadius8 padding16 backgroundLightBrown">
                <MultiLineChart />
              </div>
              <div className="margin-top16">
                <FilterByDate data={CanData} />
              </div>
              <div className="margin-top16 boxRadius8 desk_card_border padding12">
                <img
                  src={Graph}
                  height="30px"
                  width="30px"
                  className={style["img-top2"]}
                />
                <HeadLinesSpan
                  text={`Arya’s forecasted price for ${PriceDescription.forecasted_month} ${PriceDescription.forecasted_year}:`}
                  className={
                    "margin-left8 font-weight400 font-size18 line-height21 letterSpacing05 colorDark6"
                  }
                />
                <HeadLinesSpan
                  text={PriceDescription.forecasted_price + " / MT"}
                  className={
                    "margin-left8 font-weight500 font-size18 line-height21 letterSpacing05 colorDark6"
                  }
                />
              </div>
              <div className="margin-top24 d-flex justify-content-center">
                <Button
                  className={`backAccent3 font-size16 line-height19 letterSpacing05 ${style["cta-btn"]}`}
                >
                  Buy Stock
                </Button>
                <Button
                  className={`margin-left24 font-size16 line-height19 letterSpacing05 ${style["cta-btn"]} ${style["backYellow"]}`}
                >
                  Sell Stock
                </Button>
              </div>
            </div>
          </div>
          <div className={!isMobile && `col-4 m-0`}>
            <div>
              <HeadLines
                text={"Maize Mandi Bhav"}
                className={
                  "font-weight500 line-height21 font-size18 letterSpacing015 colorDark5 margin-bottom8"
                }
              />
              <HeadLines
                text={"See Mandi Bhav in different regions"}
                className={
                  "font-weight400 line-height19 font-size16 letterSpacing05 colorDark7"
                }
              />
              {PriceSideBarData.map((item, index) => (
                <PriceSideBar data={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
      {isMobile ? (
        <StickyFooter isActive="Finance" showBackToTopWidget={false} />
      ) : (
        <div className="margin-top40">
          <DesktopFooter />
        </div>
      )}
    </>
  );
};

export default PriceTrend;
