import React from "react";
import HeadLines from "../HeadLines";
import HeadLinesSpan from "../HeadlinesSpan";
import MultiLanguageTranslate from "../../../../../_components/Translate/MultiLangTranslate";
const TextAreaWithTitle = (props) => {
  const {
    title,
    titleClassName,
    titleParentClass = "",
    showWordCount = true,
    countClassName,
    textAreaClassName,
    textAreaProps,
    isLengthParenthesis = true,
    showError = "",
    errorIcon,
    errorIconClassName = "",
    errorClassName = "",
    isCompulsory = false,
    className = "",
    compulsoryStarClassName = "",
    addOptionalTitle = "",
  } = props;
  return (
    <div className={className}>
      <div className={`d-flex justify-content-between`}>
        <div className={`d-flex ${titleParentClass}`}>
          <HeadLines className={titleClassName} text={title} />
          {addOptionalTitle && <>&nbsp;</>}
          {addOptionalTitle !== "" && (
            <HeadLinesSpan
              text={addOptionalTitle}
              className={`colorDark9 font-size10 line-height16`}
            />
          )}
          {isCompulsory && (
            <div className={`asterisk ${compulsoryStarClassName}`}>*</div>
          )}
        </div>
        {showWordCount && (
          <HeadLines
            className={countClassName}
            text={`${isLengthParenthesis ? "(" : ""}${
              (textAreaProps?.value || "")?.length
            }/${textAreaProps?.maxLength}${isLengthParenthesis ? ")" : ""}`}
          />
        )}
      </div>
      <textarea className={textAreaClassName} {...textAreaProps} />
      {showError ? (
        <HeadLines
          className={`${errorClassName} font-size12`}
          text={
            <>
              {errorIcon && (
                <img
                  src={errorIcon}
                  className={`${errorIconClassName}`}
                  alt=""
                />
              )}
              {<MultiLanguageTranslate title={showError} />}
            </>
          }
        ></HeadLines>
      ) : (
        ""
      )}
    </div>
  );
};

export default TextAreaWithTitle;
