import React from "react";
import styles from "./styles.module.css";
const CustomRadioButton = (props) => {
  const { bigger, disabledClassName = "" } = props;
  return (
    <span className={`${styles["custom-radio-span"]}`}>
      <input
        className={`${styles["custom-radio"]} ${
          bigger ? styles["bigger"] : ""
        } ${props.tdsRadio ? styles["tds-radio"] : ""} ${disabledClassName}`}
        type="radio"
        {...props.radioBtnProps}
      />
      {/* <input type={'radio'}/> */}
    </span>
  );
};

export default CustomRadioButton;
