import React from "react";
import TwoLineWidget from "../../CommonComponents/TwoLineWidget/TwoLineWidget";
import { carbonTime, locationGreyIcon2 } from "../../icon_assets";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import style from "./style.module.css";
const PriceSideBar = ({ data }) => {
  console.log(data);
  return (
    <div className="desk_card_border boxRadius8 boxShadow8 margin-top16">
      <div className="padding16 d-flex justify-content-space-between">
        <TwoLineWidget
          title={data.commodity}
          subtitle={data.rate + " / MT"}
          heading1ClassName={
            "font-weight500 font-size16 line-height19 letterSpacing05 colorDark9"
          }
          heading2ClassName={
            "font-weight500 font-size16 line-height19 letterSpacing05 colorDark margin-top8"
          }
        />
        <div className="colorDark7">
          <img
            src={carbonTime}
            width="18px"
            height="18px"
            className={style["img-top2"]}
          />
          <HeadLinesSpan
            text={data.last_updated}
            className={
              "margin-left4 letterSpacing025 font-weight400 font-size16 line-height19"
            }
          />
        </div>
      </div>
      <div className="backAccent9 colorDark7 padding-top6 padding-bottom6 padding-left16">
        <img
          src={locationGreyIcon2}
          width="18px"
          height="18px"
          className={style["img-top2"]}
        />
        <HeadLinesSpan
          text={data.location}
          className={
            "margin-left4 letterSpacing025 font-weight400 font-size16 line-height19"
          }
        />
      </div>
    </div>
  );
};

export default PriceSideBar;
