import React, { useState } from "react";
import s from "./styles.module.css";
import { Table } from "react-bootstrap";
import HeadLines from "../CommonComponents/HeadLines";
import Button from "../CommonComponents/Button";
import { editIcon, deleteIcon } from "../icon_assets";
import { userDetails as userData, headerName } from "./constant";
import AddUserModal from "./AddUserModal";
import DeleteUserModal from "./DeleteUserModal";

const AccountTable = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [data, setData] = useState(userData);

  const openEditUserModal = (userId) => {
    const userToEdit = data.find((user) => user.id === userId);
    setShowEditUserModal(true);
    setEditUserData(userToEdit);
  };

  const handleDeleteUser = () => {
    setShowDeleteUserModal(false);
  };

  return (
    <div className={`stdFontStyle`}>
      <div className="d-flex justify-content-space-between font-weight500 font-size16 margin-bottom14">
        <div className="margin-top20">{data.length} users found</div>
        <div className={`${s["wid183"]}`}>
          <Button
            className={"cta-btn"}
            onClick={() => setShowAddUserModal(true)}
          >
            Add User
          </Button>
        </div>
      </div>
      <div className="width-100">
        {showAddUserModal && (
          <AddUserModal
            showModal={showAddUserModal}
            onHide={(dataObj) => {
              if (dataObj) {
                console.log(dataObj);
                dataObj.role = dataObj?.role?.value;
                dataObj.name = dataObj?.fName;
                console.log(dataObj);
                let abc = data;
                abc.push(dataObj);
                setData(abc);
              }
              setShowAddUserModal(false);
            }}
          />
        )}
        {showEditUserModal && (
          <AddUserModal
            showModal={showEditUserModal}
            onHide={() => setShowEditUserModal(false)}
            editUser={true}
            editUserData={editUserData}
          />
        )}
        {showDeleteUserModal && (
          <DeleteUserModal
            showModal={() => setShowDeleteUserModal(true)}
            onHide={() => setShowDeleteUserModal(false)}
            onDelete={handleDeleteUser}
          />
        )}
        {data.length > 0 ? (
          <Table
            className={`boxShadow15 font-size14 ${s["table-borderRadius"]}`}
          >
            <thead>
              <tr className="font-weight500 backAccent9">
                {headerName &&
                  headerName.map((item, index) => <th key={index}>{item}</th>)}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 1 ? s["backLightGrey3"] : ""}  `}
                >
                  <td className={`${s["width-20"]} ${s["va-amiddle"]}`}>
                    {item?.name}
                  </td>
                  <td className={`${s["width-20"]} ${s["va-amiddle"]}`}>
                    {item?.phoneNum ? item?.phoneNum : "-"}
                  </td>
                  <td className={`${s["width-20"]} ${s["va-amiddle"]}`}>
                    {item?.role}
                  </td>
                  <td className={`${s["width-25"]} ${s["va-amiddle"]}`}>
                    {item?.permissions}
                  </td>
                  <td className={`${s["width-15"]} ${s["va-amiddle"]}`}>
                    <div className="d-flex justify-content-end cursor-pointer gap12">
                      <img
                        src={editIcon}
                        height="28px"
                        width="28px"
                        alt="edit"
                        onClick={() => openEditUserModal(item.id)}
                      />
                      <img
                        src={deleteIcon}
                        height="28px"
                        width="28px"
                        alt="delete"
                        onClick={() => setShowDeleteUserModal(true)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="w-100 margin-top24 margin-bottom24 text-center flex-center">
            <HeadLines className={"text-center"} text={"No Records Found"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountTable;
