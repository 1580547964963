




import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'

export const doc = {
    addDoc,
    getDocList,
    removeDoc
    // getBuyerEnlist,
    // getBuyerEnlistById,
    // getURL
}

async function addDoc(data) {
    try {
        const response = await RestMethod.POST('/add-manage-docs', data);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getDocList(offset, limit, search, admin) {
    var url = admin ? "/all-doc-list?" : "/doc-list?";
    if (offset !== undefined && limit !== undefined && offset !== null && limit !== null) {
        url = url + "offset=" + offset + "&limit=" + limit + '&';
    }

    if (search !== null) {
        url = url + search
    }


    try {
        const response = await RestMethod.GET(url);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function removeDoc(id) {
    var url = "/remove-doc/" + id;
    try {
        const response = await RestMethod.GET(url);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

// async function getURL(id) {
//     var url = "/short-url/"+id;
//     try {
//         const response = await RestMethod.GET(url);
//         const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
//         return api_response.data;
//     }
//     catch (error) {
//         toastr.error('Something Went Wrong')
//         console.log("error detected while fetching data from api");
//         return null;
//     }
// }



