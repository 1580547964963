

import { RestMethod } from '../../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../../_helpers/FilterResponse'
import toastr from 'toastr'

export const CommoditiesServices = {
    getSampleCommodity,
    getAllCommodity,
    importAllCommodity
}

async function getSampleCommodity() {
    try {
        const response = await RestMethod.GET("/sample-commodity");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'commodity_list.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getAllCommodity() {
    try {
        const response = await RestMethod.GET("/all-commodity");
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function importAllCommodity(data) {
    try {
        const response = await RestMethod.POST("/import-commodity", data);
        const api_response = FilterResponse(response);
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}