import React from "react";
import { useHistory } from "react-router";
import HeadLines from "../../CommonComponents/HeadLines";
import useIsBanker from "../../CustomHooks/useIsBanker";
import IconsSvg from "../../StickyFooter/IconsSvg";
import { BackToTopFunc } from "../../CommonFunctions/Function";

const MainModuleHeading = ({ isActive, workEvenIfActive = false }) => {
  const history = useHistory();
  // const LinkClick = (url) =>{
  //     history.push(url)
  // }
  const isBanker = useIsBanker();
  const HeaderArray = [
    {
      title: "Home",
      onClick: "/",
      sameUrl: window.location.pathname === "/",
    },
    {
      title: "Storage",
      onClick: "/my-storage/commodities",
      sameUrl: window.location.pathname === "/my-storage/commodities",
    },
    {
      title: "Trade",
      onClick: "/trade/my-demands",
      sameUrl: window.location.pathname === "/trade/my-demands",
    },
    {
      title: "Finance",
      onClick: "/finance",
      sameUrl: window.location.pathname === "/finance",
    },
  ];

  return (
    <>
      {HeaderArray.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              if (item.sameUrl) {
                BackToTopFunc();
              } else {
                history.push(
                  item.title === "Trade" && isBanker
                    ? "/blockchain/banker/verify-token"
                    : item.onClick
                );
              }
            }}
            className="d-flex align-items-center cursorPointer"
          >
            <IconsSvg
              iconName={item.title}
              isActive={item.title === isActive}
              desktop={true}
            />
            <HeadLines
              className={`padding-top4 padding-left8   ${
                item.title === isActive
                  ? " textColorWhite font-weight500"
                  : " colorLightGrey"
              }`}
              text={item.title}
            />
          </div>
        );
      })}
    </>
  );
};
export default MainModuleHeading;
