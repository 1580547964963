import React from "react";
import { Link, useHistory } from "react-router-dom";
import style from "./style.module.css";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import { backArrow } from "../../icon_assets";
const BreadcrumbUI = ({
  items = [],
  onBackArrowClick = () => null,
  className = "",
  className2 = "",
}) => {
  const history = useHistory;
  return (
    <nav aria-label="breadcrumb" className={className}>
      <ol
        className={`font-size16 stdFontStyle line-height19 mb-0 letterSpacing05 align-items-center ${style["breadcrumbCSS"]} ${className2}`}
      >
        <div
          onClick={
            window.history.length > 2
              ? onBackArrowClick
              : () => history.push("/")
          }
          className="margin-right8 cursorPointer"
        >
          <img alt="" src={backArrow} height={24} width={24} />
        </div>
        {items.map((item, index) => (
          <>
            <li
              key={index}
              className={`${style["breadcrumbLiCSS"]} ${
                index === items.length - 1 ? style["breadcrumbLiActive"] : ""
              }`}
              aria-current={index === items.length - 1 ? "page" : undefined}
            >
              {item.url ? (
                typeof item.url == "string" ? (
                  <Link to={item.url} className={style["ColorCSS"]}>
                    <HeadLinesSpan text={item.text}></HeadLinesSpan>
                  </Link>
                ) : (
                  <HeadLinesSpan
                    className={`cursor-pointer `}
                    onClick={item.url}
                    text={item.text}
                  ></HeadLinesSpan>
                )
              ) : (
                <HeadLinesSpan text={item.text}></HeadLinesSpan>
              )}
              {item.subText && (
                <HeadLinesSpan
                  text={item.subText}
                  className={
                    "padding-left4 font-size14 line-height16 font-weight400 colorDark8"
                  }
                ></HeadLinesSpan>
              )}
            </li>
          </>
        ))}
      </ol>
    </nav>
  );
};
export default BreadcrumbUI;
