import React from "react";
import { crossIcon } from "../../icon_assets";

const ImagePreview = (props) => {
  const {
    imagePrwRoqClassName,
    fileListForUpload,
    imagePrwCntrClassName,
    imagePrwClassName,
    imagePrwCloseClassName,
    handleDelete,
  } = props;
  return (
    <div className={imagePrwRoqClassName}>
      {Array.from(fileListForUpload)?.map((file, index) => (
        <div className={imagePrwCntrClassName}>
          <img
            className={imagePrwClassName}
            src={URL.createObjectURL(file)}
            width={40}
            alt=""
            height={40}
          />
          <img
            src={crossIcon}
            className={imagePrwCloseClassName}
            alt=""
            onClick={() => handleDelete(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default ImagePreview;
