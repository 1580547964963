import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import HeadLines from "../HeadLines";
import LoaderCircle from "./LoaderCircle/LoaderCircle";
import styles from "./styles.module.css";
const Loader = (props) => {
  const {
    text = "Loading...",
    textClassName,
    circleColor,
    circleThickness,
    spinnerClassName,
  } = props;
  const { isLoading = false } = props;
  useEffect(() => {
    if (isLoading) {
      let body = document.getElementsByTagName("body")[0];
      body.classList.add(`${styles["overflowHidden"]}`);
      let root = document.getElementById("root");
      root.classList.add(`${styles["blur"]}`);
      let modal = document.getElementsByClassName("modal-dialog")[0];
      modal?.classList?.add(`${styles["blur"]}`);
      // body.classList.add(`${styles["blur"]}`);
    }
    return () => {
      let body = document.getElementsByTagName("body")[0];
      body.classList.remove(`${styles["overflowHidden"]}`);
      let root = document.getElementById("root");
      root.classList.remove(`${styles["blur"]}`);
      let modal = document.getElementsByClassName("modal-dialog")[0];
      modal?.classList?.remove(`${styles["blur"]}`);
      // body.classList.remove(`${styles["blur"]}`);
    };
  }, [isLoading]);

  return createPortal(
    isLoading ? (
      <div className="positionAbsolute">
        <div className={styles["backdrop"]}></div>
        <div className={styles["loader-container"]}>
          <LoaderCircle
            spinnerClassName={spinnerClassName}
            circleColor={circleColor}
            circleThickness={circleThickness}
          />
          <HeadLines
            className={`margin-top12
                        font-size12
                        line-height14
                        letterSpacing025
                        ${styles["loader-text-color"]}
                        ${textClassName}
                        `}
            text={text}
          />
        </div>
      </div>
    ) : (
      <></>
    ),
    document.getElementById("loader")
  );
};

export default Loader;
