import React, { useEffect, useState } from "react";
import HeadLines from "../../CommonComponents/HeadLines";
import CustomRadioButton from "../../../../../_components/TradeContract/CustomRadioButton/CustomRadioButton";
import { titleCase } from "../../CommonFunctions/Function";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import { setUserLanguage } from "../../Redux/UserLang/actions";
import styles from "../styles.module.css";
import Button from "../../CommonComponents/Button";
import useUserLanguage from "../../CustomHooks/useUserLanguage";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { languageOptions as options } from "../../Profile/constants";
import { isMobile } from "../../helperFunc";
import Header from "../../CommonComponents/Header";
import { useHistory } from "react-router-dom";
import { backArrow } from "../../icon_assets";
import HeaderBackground from "../../CommonComponents/HeaderBackground";
import StickyFooter from "../../StickyFooter/StickyFooter";
import OptionButton from "../../CommonComponents/OptionButton";
import { CHANGE_LANGUAGE } from "../../GATracker3.0/constants";
import { getCommodityList } from "../../../../../_services/Arya2.0/CommonServices";
import { setCommodityArray } from "../../Redux/CommodityArray/actions";
import { eventTracker } from "../../GATracker3.0/eventTracker";
import s from "./style.module.css";
const LangPreferences = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedId, setSelected] = useState(1);
  const userLanguage = useUserLanguage();
  const [isLanguageSelected, setIsLanguageSelected] = useState(false);

  useEffect(() => {
    if (userLanguage) {
      setSelected(+userLanguage?.id);
    }
  }, []);
  useEffect(() => {
    if (selectedId) {
      if (selectedId === userLanguage?.id) {
        setIsLanguageSelected(false);
      }
    }
  }, [selectedId]);

  const saveLanguage = () => {
    if (!selectedId) return false;
    localStorage.setItem(
      "lang",
      options.find((el) => el.id === selectedId)?.lang_id
    );
    i18n.changeLanguage(options.find((el) => el.id === selectedId)?.lang_id);
    dispatch(
      setUserLanguage({ ...options.find((el) => el.id === selectedId) })
    );
    window.location.reload();
  };
  const clickPill = (id) => {
    setSelected(id);
  };
  const onClick = (id) => {
    if (!selectedId) {
      // setSelected(+id);
      return;
    } else {
      const selectedLanguageName = options.find(
        (el) => el.id == selectedId
      )?.lang_id;
      setSelected(options.find((el) => el.id == selectedId)?.id);
      eventTracker("event", CHANGE_LANGUAGE, {
        lang: titleCase(selectedLanguageName),
      });
      localStorage.setItem(
        "lang",
        options.find((el) => el.id == selectedId)?.lang_id
      );
      i18n.changeLanguage(options.find((el) => el.id == selectedId)?.lang_id);
      dispatch(
        setUserLanguage({ ...options.find((el) => el.id == selectedId) })
      );
      setIsLanguageSelected(true);
    }
  };
  useEffect(() => {
    if (isLanguageSelected)
      getCommodityList().then((response) => {
        if (response !== null) {
          dispatch(setCommodityArray(response?.data ?? []));
          // window.location.reload();
        }
      });
  }, [isLanguageSelected]);

  return (
    <>
      {!isMobile ? (
        <>
          {" "}
          <div className="stdFontStyle boxRadius4 padding16 lightBorder ">
            <HeadLines
              text="Select the language you prefer for browsing and trading"
              className="font-size16 font-weight500 letterSpacing05 line-height19"
            />
            <div className="margin-top4">
              {options.map((option) => (
                <div className="margin-top16 d-flex align-items-center">
                  <CustomRadioButton
                    radioBtnProps={{
                      name: option.text,
                      value: option.lang_id,
                      id: option.id,
                      checked: selectedId === option.id,
                      onChange: (e) => {
                        setSelected(+e.target.id);
                        setIsLanguageSelected(true);
                      },
                    }}
                    tdsRadio={true}
                  />
                  <HeadLinesSpan
                    className="colorDark1 margin-left8"
                    text={option.text}
                  />
                  <HeadLinesSpan
                    className="colorDark9 margin-left4"
                    text={`(${titleCase(option.lang_id)})`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={`mx-auto margin-top24 ${styles["wid400"]}`}>
            <Button
              className={`${styles["cta-btn"]} ${
                !isLanguageSelected ? `backLightGrey` : `backAccent3`
              }`}
              disabled={!isLanguageSelected}
              onClick={saveLanguage}
            >
              Save
            </Button>
          </div>{" "}
        </>
      ) : (
        <>
          {/* <Loader isLoading={isLoading} /> */}
          <HeaderBackground
            className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 
        ${isMobile ? "" : "margin-bottom24"}
        `}
          >
            <Header
              title="Language Preference"
              backArrow={backArrow}
              onSecondaryIconClick={null}
              onBackArrowClick={() => history.replace("/my-profile")}
              className="text-dark padding-bottom16 padding-top20 "
              titleClassName="font-size16 lin-height16"
            />
          </HeaderBackground>
          <div className="stdFontStyle  position-relative">
            <HeadLines
              text="Select the language you prefer for browsing and trading"
              className="padding-left16 padding-right16 padding-top16 colorDark1 font-size14 font-weight500 letterSpacing05 line-height16"
            />
            <div
              className={`d-flex margin-top40 flex-wrap margin-bottom16 padding-left16 `}
            >
              {options.map((option) => (
                <OptionButton
                  extraClassName={"margin-right8 margin-bottom16"}
                  isSelected={selectedId == option.id}
                  id={option.id}
                  selectedClassName={`${styles["lang-selected-option"]}`}
                  notSelectedClassName={`${styles["lang-not-selected-option"]}`}
                  text={
                    <HeadLines
                      className="font-weight400 font-size12 line-height20 cursor-pointer"
                      text={`${option.text} `}
                    >
                      {option.text}{" "}
                      <HeadLinesSpan
                        className={"colorDark9"}
                        text={`${option.title ? `(${option.title})` : ""}`}
                      />
                    </HeadLines>
                  }
                  onClick={clickPill}
                  textabc={option.text}
                />
              ))}
            </div>
          </div>
          <div className={`position-absolute w-100 padding16 ${s["btn-cta"]}`}>
            <Button
              className={`w-100 padding-top12 padding-bottom12 backAccent3 boxRadius4 text-white font-weight500 font-size14 line-height16`}
              title={"Save"}
              onClick={() => onClick()}
            />
          </div>
          <StickyFooter />
        </>
      )}
    </>
  );
};

export default LangPreferences;
