import React, { useState, useRef, useEffect } from "react";
import style from "./style.module.css";
import { profileMenuOptions } from "./constants";
import HeadLines from "../../CommonComponents/HeadLines";
import { useHistory } from "react-router";
import { LoginServices } from "../../../../../_services/Arya2.0/Login/LoginServices";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../Redux/UserInfo/actions";
import useUserLanguage from "../../CustomHooks/useUserLanguage";
import { useTranslation } from "react-i18next";
import useUserDetails from "../../CustomHooks/useUserDetails";
import Loader from "../../CommonComponents/Loader/Loader";
import { arrow } from "../../icon_assets";
import { useClickOutside } from "../../CustomHooks/useClickOutside";

function DesktopProfileMenu({ showDropdown, setShowDropdown = () => {} }) {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const userLanguage = useUserLanguage();
  const dispatch = useDispatch();

  const ref = useRef();
  const flag = useClickOutside(ref);

  useEffect(() => {
    if (flag) {
      setShowDropdown(false);
    }
  }, [flag]);

  const logout = async () => {
    let lang = localStorage.getItem("lang");
    setIsLoading(true);
    var response = await LoginServices.userLogout();

    if (response && response !== null) {
      dispatch(setUserInfo({ userLoggedIn: false }));
      localStorage.clear();
      localStorage.setItem("lang", lang);
      localStorage.setItem("logout", true);
      if (userLanguage) {
        i18n.changeLanguage(userLanguage["lang_id"]);
      }
      history.push("/");
      setIsLoading(false);
      // history.replace("/");
    } else {
      setIsLoading(false);
    }
  };
  const userDetails = useUserDetails();
  const isKyc = userDetails.kyc;

  let userName = userDetails?.first_name;
  if (userName) {
    userName = userName.trim().isNumber() ? "" : userName;
  }
  if (userName) {
    userName = userName
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  }

  return (
    <>
      {showDropdown && (
        <div className={`${style["desktopMenuDiv"]}`} ref={ref}>
          {profileMenuOptions.map((item) => {
            return (
              <div key={item.href}>
                <div
                  onClick={() => {
                    setShowDropdown(false);
                    if (item.title === "Logout") {
                      logout();
                    } else history.push(item.href);
                  }}
                  className={`${style["desktopMenuOptionDiv"]} ${
                    item.title === "Logout" ? style["margin-left2"] : ""
                  }`}
                >
                  {item.title === "My Profile" ? (
                    isKyc ? (
                      <div
                        className={`font-weight400 align-items-center cursorPointer line-height12 justify-content-center font-size10 d-flex  ${style["IconHeightWidth2"]}  ${style["border-radius50"]} colorAccent5 ${style["profileBack2"]}`}
                        onClick={() => history.push("/my-profile")}
                      >
                        <span className={style["margin-top1"]}>
                          {userName[0]}
                          {userName[1] ? userName[1] : ""}
                        </span>
                      </div>
                    ) : (
                      <img
                        src={item.icon}
                        alt=""
                        className={style["imgHeightWidth"]}
                      />
                    )
                  ) : (
                    <img
                      src={item.icon}
                      alt=""
                      className={style["imgHeightWidth"]}
                    />
                  )}
                  <HeadLines text={item.title} className={`margin-left4`} />
                </div>
                {item.title === "My Profile" && !isKyc && (
                  <div
                    className={style["kycTag"]}
                    onClick={() => history.push("/my-profile")}
                  >
                    <HeadLines
                      text="Complete KYC"
                      className={`font-size12 margin-left4 line-height14 font-weight400 colorAlert1`}
                    />
                    <img src={arrow} className="margin-left8" alt="" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default DesktopProfileMenu;
