import React from "react";
import styles from "./styles.module.css";
const Switch = (props) => {
  const { checked, onChange, id, name = "", className } = props;
  return (
    <span className={`${styles["switch"]} ${className} cursor-pointer`}>
      <input
        id={id}
        checked={checked}
        onChange={onChange}
        className={"chkbox"}
        type={"checkbox"}
        name={name}
      />
      <label htmlFor={id} className="ball cursor-pointer" />
    </span>
  );
};

export default Switch;
