import React from "react";
import { errorInfoIcon } from "../../icon_assets";
import HeadLines from "../HeadLines";
import s from "./styles.module.css";
const ValidationError = (props) => {
  const {
    text,
    className = `d-flex align-items-center gap4 ${s["margin-top4"]}`,
    imageClassName,
    textClassName = `${s["def-error-text"]}`,
    showIcon = true,
  } = props;
  return (
    <div className={className}>
      {showIcon && (
        <div className="d-flex align-items-center justify-content-center mb-auto">
          <img alt="" className={imageClassName} src={errorInfoIcon} />
        </div>
      )}
      <HeadLines className={textClassName} text={text} />
    </div>
  );
};

export default ValidationError;
