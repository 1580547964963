import { RestMethod } from "./../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "./../../../_helpers/FilterResponse";

import { customToastr } from "../../../_Pages/_webapp/arya2.0/CustomToastr";

export const LoginServices = {
  resetOtp,
  verifyOtpByMobile,
  sendOTPOnMobile,
  userLogout,
  aryaShaktiMobile,
  sendEmailVerifyLink,
  emailVerifyToken,
  checkEmailIsVerified
};
async function sendOTPOnMobile(data) {
  try {
    const response = await RestMethod.POST("/v2/get-otp-mobile", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      // toastr.error("Login failed. Please try later");
      customToastr({
        text: "Login failed. Please try later",
        type: "error",
      });
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function aryaShaktiMobile(data) {
  try {
    const response = await RestMethod.POST(
      "/v2/aryashakti/verify/mobile",
      data
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function verifyOtpByMobile(data) {
  try {
    const response = await RestMethod.POST("/v2/verify-by-mobile?v3=1", data);
    //const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return response;
  } catch (error) {
    if (error && error.status !== 420) {
      // toastr.error("Login failed. Please try later");
      customToastr({
        text: "Login failed. Please try later",
        type: "error",
      });
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function resetOtp(data) {
  try {
    const response = await RestMethod.POST("/v2/resend-otp", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (error && error.status !== 420) {
      customToastr({
        text: "Something went wrong. Please try again later",
        type: "error",
      });
    }
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function userLogout() {
  try {
    const response = await RestMethod.GET("v2/logout");
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    customToastr({ text: "Logout Successfully", type: "success" });
    return api_response;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function sendEmailVerifyLink(data) {
  try {
    const response = await RestMethod.POST("/v2/send-verification-link", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    // customToastr({ text: "Logout Successfully", type: "success" });
    return api_response;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function emailVerifyToken(data) {
  try {
    const response = await RestMethod.POST("/v2/verify-email", data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    // customToastr({ text: "Logout Successfully", type: "success" });
    return api_response;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function checkEmailIsVerified() {
  try {
    const response = await RestMethod.GET("v2/check-email-verification");
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;

  } catch (e) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
}