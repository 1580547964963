import React, { useEffect, useState } from "react";
import CustomRadioBtn from "../../CommonComponents/CustomRadioBtn/CustomRadioBtn";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import HeadLines from "../../CommonComponents/HeadLines";
import UploadDoc from "../../Registration/UploadDoc";
import { backArrow, errorInfoIcon } from "../../icon_assets";
import UploadDocumentComponent from "../../Finance/Components/UploadFile/UploadDocumentComponent";
import { isMobile } from "../../helperFunc";
import { checkFileType } from "../BankDetails/constant";
import s from "./style.module.css";
import {
  fileSizeLimit,
  fileSizeMax,
} from "../../../../../_constants/Config/AryaConstants";
import ErrorMessage from "../../CommonComponents/ErrorMessage/ErrorMessage";
import Button from "../../CommonComponents/Button";
import HeaderBackground from "../../CommonComponents/HeaderBackground";
import Header from "../../CommonComponents/Header";
import { useHistory } from "react-router";
import StickyFooter from "../../StickyFooter/StickyFooter";

const TradeDetail = () => {
  const docType = {
    tcs: "TCS",
    tds: "TDS",
  };
  const [doctypeCheck, setdoctypeCheck] = useState("");
  const [uploadDoc, setUploadDoc] = useState("");
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if (uploadDoc && doctypeCheck !== "") {
      console.log("enable btn");
      setFlag(0);
    }
  }, [uploadDoc, doctypeCheck]);
  const handleUploadform = (event) => {
    const a = event.target.files;
    const fileValidate = fileValidation(a?.[0]);
    if (fileValidate) {
      setUploadDoc(a?.[0]);
      setError("");
    }
  };
  const fileValidation = (file) => {
    console.log("file?.sizefile?.size", file);
    if (file && !checkFileType.includes(file?.type)) {
      setError("Document must be a file of type: pdf, jpeg, png.");
      return false;
    } else if (file && file?.size > fileSizeLimit) {
      setError(`Document may not be greater than ${fileSizeMax}MB.`);
      //2mb
      return false;
    } else {
      return true;
    }
  };
  const saveForm = () => {
    console.log("save form");
  };
  console.log("Radio", doctypeCheck);

  return (
    <div className="scroll-vertical">
      {isMobile && (
        <HeaderBackground
          className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 ${s["header-style"]}`}
        >
          <Header
            title="Trade Details"
            backArrow={backArrow}
            iconClassName={`${s["back-arrow"]} flex-center`}
            onSecondaryIconClick={() => null}
            onBackArrowClick={() => history.goBack()}
            titleClassName="font-weight600 font-size16 colorDark5 line-height19 letterSpacing015"
            className="padding-bottom16 padding-top20"
          />
        </HeaderBackground>
      )}
      <div
        className={
          isMobile ? "padding-left16 padding-right16 padding-top16" : ""
        }
      >
        <HeadLines
          className={`colorDark9 font-weight500 ${
            isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
          } letterSpacing005`}
          text={
            <>
              Select Document Type{" "}
              <HeadLinesSpan className={"asterisk"} text="*" />
            </>
          }
        />
        <div
          className={`${
            isMobile ? "margin-top10" : "margin-top8"
          }  d-flex gap40`}
        >
          <div className="d-flex gap4">
            <CustomRadioBtn
              inputProps={{
                name: "docTypeq",
                checked: doctypeCheck === docType.tcs,
                value: docType.tcs,
                onChange: (e) => {
                  setdoctypeCheck(docType.tcs);
                },
              }}
              id="tcs_1"
            />
            <HeadLines
              className={`colorDark5 ${
                isMobile
                  ? "font-weight400 font-size12 line-height14 margin-top2 "
                  : "font-weight500 font-size16 line-height19"
              } letterSpacing005`}
              text="TCS"
            />
          </div>
          <div className="d-flex gap4">
            <CustomRadioBtn
              inputProps={{
                name: "docTypeq",
                checked: doctypeCheck === docType.tds,
                value: docType.tds,
                onChange: () => {
                  setdoctypeCheck(docType.tds);
                },
              }}
              id="tds_2"
            />
            <HeadLines
              className={`colorDark5 ${
                isMobile
                  ? "font-weight400 font-size12 line-height14 margin-top2 "
                  : "font-weight500 font-size16 line-height19"
              } letterSpacing005`}
              text="TDS"
            />
          </div>
        </div>
      </div>
      <div
        className={`margin-top16 ${
          isMobile ? "padding-right16 padding-left16" : ""
        }`}
      >
        <UploadDocumentComponent
          className={`${isMobile ? "margin-top16" : ""} width-100`}
          customTextClassName={`${s["text-truncate-file-upload"]} text-truncate w-100 ${s["upload-placeholder-color"]} `}
          onChange={(e) => {
            handleUploadform(e);
          }}
          value={uploadDoc?.name}
          placeholder={uploadDoc?.name || "Select File"}
          inputClassName={`width-100 boxRadius4 ${
            s["input-border"]
          } font-weight400 ${
            isMobile
              ? "font-size14 line-height16 letterSpacing001 margin-top4 "
              : "font-size16 line-height19 letterSpacing05 margin-top4"
          }`}
          errorInputClassName={"input-style-error"}
          errorClassName={"input-error-style"}
          title={"Upload Document"}
          showError={""}
          errorIcon={errorInfoIcon}
          titleClassName={`${
            isMobile
              ? `font-size14 line-height16 font-weight500`
              : "font-size16 line-height19 font-weight600 colorDark9"
          } letterSpacing05 colorDark9`}
          compulsoryStarClassName={isMobile ? s["mob-astrik"] : "margin-top8"}
          isCompulsory={true}
          financePage={false}
          kycForm={true}
        />
        {isMobile && (
          <HeadLines
            className={
              "font-size10 line-height12 font-weight400 colorDark9 margin-top4"
            }
          >
            <HeadLinesSpan text={"*"} className={"asterisk"} />
            Please upload a jpeg/png/pdf. Maximum file size limit is 10 MB.
          </HeadLines>
        )}
        {error && <ErrorMessage className={"margin-top4"} text={error} />}
      </div>
      <div
        className={`${
          isMobile
            ? "padding-right16 padding-left16 position-absolute w-100 margin-top16"
            : "margin-top40 "
        } d-flex justify-content-center`}
      >
        <Button
          disabled={flag}
          className={`margin-bottom12 ${
            flag ? "button-disabled" : "backAccent3"
          } boxRadius6 text-white font-weight500  ${
            isMobile
              ? "w-100 padding-top12 padding-bottom12 flex-center font-size14 line-height16"
              : "font-size16 line-height19 w-50"
          } ${s["btn-styles"]}`}
          title={"Save"}
          onClick={() => saveForm()}
        />
      </div>
      {isMobile && <StickyFooter isActive="Profile" workEvenIfActive />}
    </div>
  );
};
export default TradeDetail;
