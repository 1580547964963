import React, { useState } from "react";
import Button from "../CommonComponents/Button";
import HeadLines from "../CommonComponents/HeadLines";
import { isMobile } from "../helperFunc";
import SkipPopUp from "./SkipPopUp";
import style from "./style.module.css";
const SkipSubmitButton = ({ buttonText = "Submit", ...props }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {show && (
        <SkipPopUp
          show={show}
          setShow={setShow}
          onClick={props.skipClick}
          skipText={props.skipText}
          setBlurBackGround={props.setBlurBackGround}
        />
      )}
      <div
        className={`d-flex  padding16 backgroundWhite width-100 ${
          isMobile
            ? style["stickyFooter"]
            : "justify-content-center margin-top16"
        }`}
      >
        {!props.noSkip && (
          <HeadLines
            onClick={() => setShow(true)}
            text="Skip"
            className={`cursorPointer margin-left16  ${style["skipColor"]} ${
              isMobile
                ? "font-weight400 font-size12 line-height14 mtb-auto  width-100  letterSpacing0125"
                : `light2Border font-weight500 textCenter boxRadius6 font-size16 line-height19 ${style["padding105"]} letterSpacing05 ${style["width-273"]}`
            }`}
          />
        )}
        <Button
          title={buttonText}
          className={`boxRadius6 ${
            props.buttonEnable
              ? "backAccent3"
              : isMobile
              ? "backColorAccent5"
              : "btn-disable-grey"
          }    textColorWhite   ${
            isMobile
              ? " font-size14 padding12 line-height16 letterSpacing0125 width-100"
              : ` font-size16 line-height19 letterSpacing05 ${style["width-273"]} margin-left20 ${style["padding105"]}`
          }`}
          onClick={() => {
            if (props.buttonEnable) props.submitClick();
          }}
        />
      </div>
    </>
  );
};
export default SkipSubmitButton;
