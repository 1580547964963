import { RestMethod } from '../../_helpers/ApiConfig/RestMethod'
import FilterResponse from '../../_helpers/FilterResponse'
import toastr from 'toastr'

export const settingsServices = {
    getAllSettings,
    addSettings,
    getSettingsById,
    updateSettings,
    deleteSetting,
    getSettingByPage
}

async function getAllSettings() {
    try {
        const response = await RestMethod.GET("/all-setting");
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function addSettings(data) {
    try {
        const response = await RestMethod.POST("/add-setting",data);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getSettingsById(id) {
    try {
        const response = await RestMethod.GET("/setting-detail/"+id);
        const api_response = FilterResponse(response); 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function updateSettings(id, data) {
    try {
        const response = await RestMethod.PUT("/update-setting/"+id,data);
        const api_response = FilterResponse(response); 
        return api_response;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}




async function deleteSetting(id) {
    var reuestlink = "/delete-setting/" + id;

    try {
        const response = await RestMethod.GET(reuestlink);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}

async function getSettingByPage(page) {
    var reuestlink = "/page-setting/" + page;

    try {
        const response = await RestMethod.GET(reuestlink);
        const api_response = FilterResponse(response); //If respone 200 to 299 Then return 
        return api_response.data;
    }
    catch (error) {
        toastr.error('Something Went Wrong')
        console.log("error detected while fetching data from api");
        return null;
    }
}



