import { USER_TYPE } from "./constants";
import useUserDetails from "./useUserDetails";

const useIsBanker = () => {
  const userDetails = useUserDetails() || {};

  let userRoles = userDetails.roles;
  let isKyc = userDetails.kyc;
  var isTrue = false;

  if (isKyc == 1 && userRoles.find((obj) => +obj.id === USER_TYPE.BANKER)) {
    isTrue = true;
  }
  return isTrue;
};

export default useIsBanker;
