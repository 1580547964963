import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../_helpers/FilterResponse";
import toastr from "toastr";

export const marketPlaceServices = {
  getAllProducts,
  getQualityCount,
};

async function getAllProducts(homeWidget = false) {
  try {
    const response = await RestMethod.GET(
      `search/products?offset=0&limit=3&filter=[{"price":[1]}]`
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (!homeWidget) toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function getQualityCount(
  commodityName,
  filterData = [],
  homeWidget = false
) {
  let filterString = "filter=[]";
  if (filterData.length > 0) {
    filterString = "filter=" + encodeURIComponent(JSON.stringify(filterData));
  }
  console.log(
    "filterString:-------------------------",
    decodeURIComponent(filterString)
  );
  try {
    const response = await RestMethod.GET(
      `commodity/${encodeURIComponent(commodityName)}/quality_count?` +
        filterString
    );
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response;
  } catch (error) {
    if (!homeWidget) toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
}
