import React from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import s from "./styles.module.css";
import "./styles.css";
import "../../commonStyle.css";
import { eventTracker } from "../../GATracker3.0/eventTracker";
import { ENQUIRY_FORM_SUBMITTED } from "../../GATracker3.0/constants";
import BlurModalDesktopHeading from "./BlurModalDesktopHeading";
const isMobile = window.matchMedia(
  "only screen and (max-width: 1023px)"
).matches;

const BlurModal = (props) => {
  const {
    show,
    onHide,
    keyboard = true,
    backdrop,
    showCross = false,
    children,
    className = "blur-modal",
    eventCleanupFunc = false,
    pillClassName = "pill", //pill or pill dark
    isnotFixed = false,
    apiCallSuccessFlagRef = {},
    certified = "",
    headingTextClassName,
    headingClassName,
    showDesktopHeader = false,
    desktopHeading = "",
    showCloseIconOnDesktop = true,
    desktopCrossIcon = undefined,
    historyCSS = "history",
  } = props;

  useEffect(() => {
    var userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);
    if (ios && safari) {
      if (show && !isnotFixed) document.body.style.position = "fixed";
      else document.body.style.position = "unset";
    }
    return () => {
      if (ios && safari) {
        document.body.style.position = "unset";
      }
    };
  }, [show]);

  useEffect(() => {
    if (show) {
      document.getElementById("root")?.classList.add(s["blur-root"]);
    }
    return () => {
      document.getElementById("root")?.classList.remove(s["blur-root"]);
    };
  }, [show]);

  useEffect(() => {
    return () => {
      if (eventCleanupFunc) {
        if (!apiCallSuccessFlagRef?.current) {
          eventTracker("event", ENQUIRY_FORM_SUBMITTED, {
            product_type: certified,
            submit_response: "enquiry_unsuccessful",
          });
        }
      }
    };
  }, []);

  return (
    <Modal
      keyboard={keyboard}
      backdrop={backdrop}
      className={`stdFontStyle ${className} ${isMobile ? "" : historyCSS}`}
      backdropClassName="custom-backdrop"
      show={show}
      onHide={onHide}
    >
      {isMobile ? <div className={pillClassName} /> : ""}
      {showCross && <span className={"close z-index3"} onClick={onHide}></span>}
      <Modal.Body>
        {showDesktopHeader && (
          <BlurModalDesktopHeading
            crossIcon={desktopCrossIcon}
            onHide={onHide}
            heading={desktopHeading}
            showCloseIcon={showCloseIconOnDesktop}
            headingTextClassName={headingTextClassName}
            className={headingClassName}
          />
        )}
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default BlurModal;
