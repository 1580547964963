import React from "react";
import HeadLines from "../../CommonComponents/HeadLines";
import s from "./styles.module.css";
import "../../commonStyle.css";
const Step = (props) => {
  const { stepNumber, stepText, completed, isFirst, isLast, isCurrent,titleClassName="" } = props;
  return (
    <div className={`positionRelative ${s["flex1"]}`}>
      {!isFirst && (
        <div
          className={`${s["line"]} 
                        ${s["before"]} 
                        ${(completed || isCurrent) && s["completed"]}
                        `}
        />
      )}
      <div
        className={`${s["step-number"]} 
                        ${completed && s["num-completed"]}
                        ${isCurrent && s["num-current"]}
                        `}
      >
        {stepNumber}
      </div>
      <HeadLines
        className={`${s["step-text"]} 
      ${completed && s["completed-text"]}

      ${titleClassName}
      `}
        text={stepText}
      />
      {!isLast && (
        <div
          className={`${s["line"]}
                        ${s["after"]} 
                        ${completed && s["completed"]}
                        `}
        />
      )}
    </div>
  );
};

export default Step;
