import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../_helpers/FilterResponse";
import toastr from "toastr";
import { PAYMENT_SUCCESSFUL } from "../../_constants/Events/events";
import { EventTracker } from "../../_helpers/Common/EventTrackingFunction";

export const paymentLink = async (data) => {
  var reuestlink = "/payment-link";
  try {
    const response = await RestMethod.POST(reuestlink, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Failed to share link. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const paymentLinkRecord = async (data) => {
  var reuestlink = "/payment-link-signature";
  try {
    const response = await RestMethod.POST(reuestlink, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const reSendPaymentLink = async (data) => {
  var reuestlink = "/resend-payment-link";
  try {
    const response = await RestMethod.POST(reuestlink, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Failed to share link. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const checkPaymentStatus = async (data) => {
  var reuestlink = "/check-payment-status";
  try {
    const response = await RestMethod.POST(reuestlink, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.warning("Failed to check payment status. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const stockCheckPaymentStatus = async (data) => {
  var reuestlink = "/check-stock-payment-status";
  try {
    const response = await RestMethod.POST(reuestlink, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.warning("Failed to check payment status. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const paymentSeen = async (id) => {
  var reuestlink = "/payment-seen-status/" + id;
  try {
    const response = await RestMethod.GET(reuestlink);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    // toastr.error('Something Went Wrong')
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const gettdsDetails = async (id) => {
  var reuestlink = "/get-tds-detail";
  try {
    const response = await RestMethod.GET(reuestlink);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.warning("Failed to load TDS details. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const banksList = async (id) => {
  var reuestlink = "/bank-list";
  try {
    const response = await RestMethod.GET(reuestlink);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Failed to load bank details. Please try later");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const createLandSupply = async (data) => {
  var reuestlink = "/create-lead-supply";
  try {
    const response = await RestMethod.POST(reuestlink, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const LeadsSuppliersList = async (id) => {
  var reuestlink = "/lead-supplies/" + id;
  try {
    const response = await RestMethod.GET(reuestlink);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const verifyUPI = async (data) => {
  var reuestlink = "/verify-upi";
  try {
    const response = await RestMethod.POST(reuestlink, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const verifyUPIPayment = async (data) => {
  var reuestlink = "/verify-upi-payment";
  try {
    const response = await RestMethod.POST(reuestlink, data);
    const api_response = FilterResponse(response, true); //If respone 200 to 299 Then return
    EventTracker("event", PAYMENT_SUCCESSFUL, {
      event_category: "payment_successful",
      event_action: "UPI",
      event_label: "-",
    });
    return api_response.data;
  } catch (error) {
    EventTracker("event", PAYMENT_SUCCESSFUL, {
      event_category: "payment_unsuccessful",
      event_action: "UPI",
      event_label: "-",
    });
    toastr.error("Something Went Wrong");
    console.log("error detected while fetching data from api");
    return null;
  }
};
